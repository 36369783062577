<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <div class="table-tab font-weight-bold px-5 py-2 text-center text-strong">Paquetes</div>
        <DataTableMain :datatable="table" :on-success="successTableAction" :itemsAction="successTableAction" />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccountSpinningClass',
  components: {
    DataTableMain,
    MainFormDialog
  },
  mixins: [DatesUtils],
  data: () => ({
    table: {
      color: '#ECDEF7',
      className: 'background-morado-light',
      header: {
        options: [
          { title: 'Nuevo', icon: 'mdi-plus', color: '#602F73', action: 'newData', code: 'libre', hidden: '' }
        ],
        titles: [
          { text: 'Nombres', value: 'name', class: 'color-morado-dark text-strong-1' },
          { text: 'Precio', value: 'price', class: 'color-morado-dark text-strong-1' },
          { text: 'Dias Vigentes', value: 'daysOfValidity', class: 'color-morado-dark text-strong-1' },
          { text: 'Cantidad por Compra', value: 'maxPurchases', class: 'color-morado-dark text-strong-1' },
          { text: '', value: 'Actions', class: 'color-morado-dark text-strong-1', align: 'right' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Editar', icon: 'mdi-pencil', color: '#A87BC9', action: 'updateData', code: 'libre', hidden: '' },
          { title: 'Eliminar', icon: 'mdi-delete', color: '#A87BC9', action: 'deleteData', code: 'libre', hidden: '' }
        ]
      },
      footer: {
        paging: true
      }
    },
    params: {
      start: '',
      final: ''
    },
    user: {},
    form: [],
    object: {},
    url: '',
    message: ''
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_objects', 'get_reload',
      'get_PackagesCreateForm', 'get_DeleteForm'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.table.body.data = rows
      }
    },
    get_reload ({ name, model }) {
      if (name === 'create') {
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: 'Paquete creado correctamente' } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
        }, 1500)
      } else if (name === 'update') {
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: 'Paquete actualizado correctamente' } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
        }, 1500)
      } else if (name === 'delete') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: 'El paquete se ha eliminado correctamente' } })
          setTimeout(() => { this.HIDDE_DIALOG_ALERT() }, 1500)
        }
      }
      this.GETListObjectsService({ url: '/Packages' })
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.GETListObjectsService({ url: '/Packages' })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    /* *==========*==========*==========*==========*==========* */
    successTableAction (item) {
      this[item.action](item)
    },
    newData (item) {
      this.form = this.get_PackagesCreateForm
      this.object = { }
      this.url = '/Packages'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Nuevo Paquete', width: 650, params: { returnObjet: true } })
    },
    updateData ({ item }) {
      this.object = item
      this.form = this.get_PackagesCreateForm
      this.url = `/Packages/${item.id}`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'update', title: 'Editar Paquete', width: 650, params: { returnObjet: true } })
    },
    deleteData ({ item }) {
      console.log(item)
      this.form = this.get_DeleteForm
      this.object = { clientId: item.clientId }
      this.url = `/Packages/${item.id}`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'delete', title: 'Eliminar Paquete', width: 650, params: { returnObjet: true } })
    }
  }
}
</script>
<style scoped>
th{
  border-bottom: 10px #602F73 solid;
}
.table-tab {
  width: 35%;
  background-color: #602F73;
  border: 1px #602F73 solid;
  border-radius: 25px 25px 0px 0px;
  color: white;
}
.v-tab--active{
  border: 1px #602F73 solid;
  background-color: #602F73;
  color: white;
}
</style>
