<template>
  <v-card v-if="model" elevation="0" class="rounded-xl" tile>
    <v-list-item three-line id="squred" class="container-border-color-rosado" link @click="sendAction">
      <v-list-item-content>
        <div class="text-center text-md-h4 font-weight-bold">
          <span class="color-morado text-uppercase text-strong-1">{{ model.name }}</span>
        </div>
        <v-list-item-title class="text-md-h4 text-center font-weight-medium">
          <span class="color-azul text-uppercase text-strong-1">$ {{ convertNumber(model.price) }}</span>
        </v-list-item-title>
        <v-list-item-subtitle class="text-center text-md-h5">
          <span class="color-azul text-strong-1"> {{ model.daysOfValidity }} días de vigencia</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
import { NumberUtils } from '@/mixins/NumberMixin'
export default {
  mixins: [NumberUtils],
  name: 'PackageComponent',
  props: ['model', 'onSuccess'],
  data: () => ({}),
  methods: {
    convertNumber (costo) {
      return this.intlRound(costo)
    },
    sendAction () {
      if (this.onSuccess) this.onSuccess(this.model)
    }
  }
}
</script>

<style scoped>
#squred {
    border-style: dotted !important;
    border-width: 3px;
}
</style>
