<template>
  <v-file-input truncate-length="40"
    show-size
    :label="itemAction.name"
    :accept="itemAction.accept"
    @change="selectFile"
    outlined
    dense
  >
  </v-file-input>
</template>
<script>
export default {
  name: 'FileInputDinamicField',
  props: ['itemAction', 'onSuccess', 'setterModel'],
  data: () => ({
    currentFile: null,
    message: ''
  }),
  methods: {
    selectFile (file) {
      this.onSuccess({ id: this.itemAction.nameid, data: file })
    }
  },
  watch: {
    setterModel (val) {
      if (val) {
        this.object = val
        this.objects.push(val)
      }
    }
  }
}
</script>

<style>
</style>
