<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mx-auto background-azul-light" elevation="0" >
          <v-card-text>
            <v-btn @click="createCouch" color="#001B71" dark>
              <v-icon left>mdi-plus</v-icon> Nuevo
            </v-btn>
            <v-btn class="mx-2" @click="newRefresh" color="#001B71" dark>
              <v-icon left>mdi-refresh</v-icon> Refrescar
            </v-btn>
          </v-card-text>
          <div class="table-tab subtitle-1 font-weight-bold px-5 text-center">Fisios</div>
          <v-progress-linear :active="loading" :indeterminate="loading" top color="#001B71"></v-progress-linear>
          <v-virtual-scroll :items="Packages" :item-height="150" height="450" class="container-border-color-azul">
            <template v-slot:default="{ item }">
              <v-divider />
              <v-list-item class="mx-5">
                <v-list-item-action>
                  <v-row>
                    <CirculePhotoField :model="item" :photo="item.photo" color="#C9E7FF"/>
                  </v-row>
                </v-list-item-action>

                <v-list-item-content class="ml-5">
                  <v-list-item-title class="font-weight-bold">
                    <div class="color-azul text-md-h5 text-sm-h6 text-h6 font-weight-bold">
                      {{ item.name }}
                    </div>
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <v-chip outlined id="circle-white-line">
                      <label class="subtitle-2 font-weight-bold text-uppercase">
                        FISIOTERAPEUTA
                      </label>
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="mr-5">
                  <v-row>
                    <v-col cols="12">
                      <div class="mx-1 color-azul text-md-h6 text-sm-h5 text-h6 font-weight-bold ma-auto text-center">
                        <div class="font-weight-bold color-azul text-center">Consultas</div>
                        <div class="font-weight-bold color-azul text-center">{{item.totalAppointments}}</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-action>

                <v-list-item-action class="mr-5">
                  <v-row>
                    <v-col cols="12">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" color="#001B71" x-small fab dark>
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="AddLoadPhotoBanner(item)" link>
                            <v-list-item-title>
                              <v-icon left>mdi-camera-image</v-icon> Cargar Foto banner
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="AddLoadPhotoCirculo(item)" link>
                            <v-list-item-title>
                              <v-icon left>mdi-camera-image</v-icon> Cargar Foto Circulo
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteCouch(item)" link>
                            <v-list-item-title>
                              <v-icon left>mdi-delete</v-icon> Eliminar
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="updateCouch(item)" link>
                            <v-list-item-title>
                              <v-icon left>mdi-delete</v-icon> Editar
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import CirculePhotoField from '@/components/fields/circules/CirculePhotoField'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    MainFormDialog,
    CirculePhotoField
  },
  mixins: [DatesUtils],
  data: () => ({
    user: {},
    Packages: [],
    form: '',
    url: '',
    object: {},
    message: '',
    loading: false
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_object', 'get_objects', 'get_reload',
      'get_FilePhotoLoad', 'get_UserForm', 'get_DeleteForm'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.Packages = rows
        this.loading = false
      }
    },
    get_object (row) {
      if (row) {
        row.RoleIds = row.roles.map(i => {
          return i.id
        })
        this.form = this.get_UserForm
        this.object = row
        this.url = `/Users/${row.id}`
        this.message = 'Registro Agregado Correctamente'
        this.SHOW_DIALOG_MAIN({ type: 'update', title: 'Editar Fisio', width: 700, params: { returnObjet: true } })
      }
    },
    get_reload ({ name, model }) {
      if (name === 'formData') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Imagen agregado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
        }
      } else if (name === 'create') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Coach creado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
        }
      } else if (name === 'delete') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Coach eliminado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
        }
      }
      this.GETListObjectsService({ url: '/Users/therapists' })
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.GETListObjectsService({ url: '/Users/therapists' })
  },
  methods: {
    ...mapActions(['GETListObjectsService', 'GETObjectService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    showLastClases (item) {
      return `${item.remainingClasses} / ${item.totalClasses}`
    },
    loadPhoto (item) {
      this.form = this.get_FilePhotoLoad
      this.object = {}
      this.url = `/Users/${item.id}/uploadImage`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'formData', title: 'Cargar Foto', width: 500, params: { content: 'form-data', returnObjet: true } })
    },
    AddLoadPhotoBanner (item) {
      this.form = this.get_FilePhotoLoad
      this.object = {}
      this.url = `/Users/${item.id}/uploadBanner`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'formData', title: 'Cargar Foto', width: 500, params: { content: 'form-data', returnObjet: true } })
    },
    AddLoadPhotoCirculo (item) {
      this.form = this.get_FilePhotoLoad
      this.object = {}
      this.url = `/Users/${item.id}/uploadImage`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'formData', title: 'Cargar Foto', width: 500, params: { content: 'form-data', returnObjet: true } })
    },
    createCouch () {
      this.form = this.get_UserForm
      this.object = {
        firstName: '',
        lastName: '',
        birthDate: '',
        phoneNumber: '',
        email: '',
        signId: null,
        RoleIds: [3],
        password: 'Fisio'
      }
      this.url = '/Users'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Nuevo Fisio', width: 700, params: { returnObjet: true } })
    },
    newRefresh (item) {
      this.loading = true
      this.GETListObjectsService({ url: '/Users/therapists' })
    },
    deleteCouch (item) {
      this.form = this.get_DeleteForm
      this.object = {}
      this.url = `/Users/${item.id}`
      this.message = 'Registro eliminado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'delete', title: 'Eliminar', width: 500, params: { returnObjet: true } })
    },
    updateCouch (item) {
      this.GETObjectService({ url: `/Users/${item.id}` })
    }
  }
}
</script>

<style scoped>
.table-calendar {
  border: 1px #355DC8 solid;
}
.table-tab {
  width: 40%;
  background-color: #001B71;
  border: 1px #001B71 solid;
  border-radius: 50px 50px 0px 0px;
  color: white;
}
.v-tab--active{
  border: 1px #355DC8 solid;
  background-color: #355DC8;
  color: white;
}
</style>
