<template>
  <v-container>
    <v-form ref="form">
      <v-row dense>
        <v-col cols="12">
          <p class="text-md-h3 text-center text-strong color-morado-dark">
            <span class="text-strong"> Bienvenida a tu Cuenta </span>
          </p>
        </v-col>

        <v-col cols="6">
          <CirculePhotoLoad :model="account" :on-success="loadPhoto" color="#ECDEF7" />
        </v-col>
        <v-col cols="6">
          <label class="color-morado-dark px-2">Nombre/s</label>
          <v-text-field v-model="account.firstName" :rules="fieldRequired" dense solo />
          <label class="color-morado-dark px-2">Apellido/s</label>
          <v-text-field v-model="account.lastName" :rules="fieldRequired" dense solo />
        </v-col>

        <v-col cols="6">
          <label class="color-morado-dark px-2">Celular</label>
          <v-text-field v-model="account.phoneNumber" :rules="fieldRequired" dense solo />
        </v-col>
        <v-col cols="6">
          <label class="color-morado-dark px-2">Fecha Nacimiento</label>
          <DatePickerSolo :item-action="fieldDateInit" :setterModel="account[fieldDateInit.nameid]" :on-success="actionFieldData" />
        </v-col>

        <v-col cols="6">
          <label class="color-morado-dark px-2">Email</label>
          <v-text-field v-model="account.email" :rules="emailRules" dense solo />
        </v-col>
        <v-col cols="6" v-if="account.signId">
          <label class="color-morado-dark px-2">Signos Zodiacal</label>
          <SelectedDinamicSolo :item-action="fieldSelecteSign" :setterModel="account[fieldSelecteSign.nameid]" :on-success="actionFieldData" />
        </v-col>
        <v-col cols="3">
          <label class="color-morado-dark px-2">Talla de zapato</label>
          <v-text-field v-model="account.alergies" :rules="fieldRequired" type="number" min="0" dense solo />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6">
          <v-btn color="#602F73" @click="updatePasswordUser" text dark>
            <v-icon left dark>mdi-plus</v-icon> Cambiar Contraseña
          </v-btn>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-layout justify-end>
            <v-btn color="#602F73" @click="updateUser" dark>
              <v-icon left dark>mdi-plus</v-icon> Guardar
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </v-form>

    <AvisoDialog v-if="get_dialogAlert.dialog" />
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DatePickerSolo from '@/components/fields/account/DatePicker_solo'
import SelectedDinamicSolo from '@/components/fields/account/SelectedDinamicField_solo'
import CirculePhotoLoad from '@/components/fields/account/CirculePhotoLoadField'
import AvisoDialog from '@/components/dialogs/AvisoDialog'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import { Validate } from '@/mixins/validateFormMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    DatePickerSolo,
    SelectedDinamicSolo,
    CirculePhotoLoad,
    AvisoDialog,
    MainFormDialog
  },
  mixins: [DatesUtils, Validate],
  data: () => ({
    fieldDateInit: { field: 'textDatePicker', name: '', nameid: 'birthDate', rules: true, cols: 3 },
    fieldSelecteSign: { field: 'selectDataServer', name: '', nameid: 'signId', url: '/Signs', rules: true, cols: 6 },
    params: {},
    user: {},
    account: {
      firstName: '',
      lastName: '',
      birthDate: '',
      phoneNumber: '',
      email: '',
      signId: null,
      RoleIds: []
    },
    form: [],
    object: {},
    url: '',
    message: ''
  }),
  computed: {
    ...mapGetters(['get_object', 'get_object_put', 'get_dialogAlert', 'get_dialogMain', 'get_reload',
      'get_FilePhotoLoad', 'get_UpdatePasswordForm'])
  },
  watch: {
    get_object (row) {
      if (row) {
        this.account = row
        this.account.RoleIds = row.roles.map(item => {
          return item.id
        })
        this.account.signId = row.sign.id
        //console.log('account', this.account)
      }
    },
    get_object_put (row) {
      if (row) {
        this.account = row
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Informacion de usuario actualizado' } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
          this.GETObjectService({ url: `/Users/${this.user.id}` })
        }, 1500)
      }
    },
    get_reload ({ name, model }) {
      if (name === 'formData') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Imagen agregado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
            this.GETObjectService({ url: `/Users/${this.user.id}` })
          }, 1500)
        }
      } else if (name === 'formpassword') {
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Contraseña actualizada correctamente' } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
          this.GETObjectService({ url: `/Users/${this.user.id}` })
        }, 2000)
      }
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.GETObjectService({ url: `/Users/${this.user.id}` })
  },
  methods: {
    ...mapActions(['GETObjectService', 'PUTObjectService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'SHOW_DIALOG_MAIN', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    actionFieldData (obj) {
      this.account[obj.id] = obj.data === undefined ? null : obj.data
    },
    loadPhoto () {
      this.form = this.get_FilePhotoLoad
      this.object = {}
      this.url = `/Users/${this.user.id}/uploadImage`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'formData', title: 'Cargar Foto', width: 500, params: { content: 'form-data', returnObjet: true } })
    },
    updateUser () {
      if (this.$refs.form.validate()) {
        if (this.account.alergies <= 0) {
          this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 600, params: { message: 'Talla de zapato no debe ser menor o igual cero' } })
          return
        }
        this.PUTObjectService({ url: `/Users/${this.user.id}`, data: this.account })
      } else {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Hay campos obligatorios' } })
      }
    },
    updatePasswordUser () {
      this.form = this.get_UpdatePasswordForm
      this.object = { userId: this.user.id, oldPassword: '', newPassword: '' }
      this.url = `/Users/password?id=${this.user.id}`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'formpassword', title: 'Cargar Foto', width: 500, params: { returnObjet: true } })
    },
    agendarCita () {
    }
  }
}
</script>
<style scoped>
.v-text-field .v-input__control .v-input__slot
{
  display: flex !important;
  align-items: center !important;
  box-shadow: none !important;
  background-color: aqua !important;
  border-radius: 0px;
}
</style>
