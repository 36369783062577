<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <div class="container-height-100">
          <h1 class="font-weight-bold color-azul ma-auto">Citas Disponibles</h1>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-2 background-line-white" />
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success='successTableAction' :itemsAction='successTableAction' />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import { Validate } from '@/mixins/validateFormMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    DataTableMain,
    MainFormDialog
  },
  mixins: [DatesUtils, Validate],
  data: () => ({
    table: {
      color: '#C9E7FF',
      className: 'background-azul-light',
      header: {
        options: [
          { title: 'Nuevo', icon: 'mdi-plus', color: '#001B71', action: 'newData', code: 'libre', hidden: '' },
        ],
        titles: [
          { text: 'Fecha', value: 'DateDinamic', class: 'black--text', showItem: 'date' },
          { text: '', value: 'ActionsLocal', class: 'black--text', align: 'right' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Editar', icon: 'mdi-pencil', color: '#001B71', action: 'updateData', code: 'libre', hidden: '' },
          { title: 'Eliminar', icon: 'mdi-delete', color: '#001B71', action: 'deleteData', code: 'libre', hidden: '' }
        ]
      },
      footer: {
        paging: true
      }
    },
    user: {},
    form: {},
    object: {},
    url: '',
    message: ''
  }),
  computed: {
    ...mapGetters(['get_objects', 'get_dialogMain', 'get_reload',
      'get_ScheduleCitaForm', 'get_DeleteForm'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.table.body.data = rows
      }
    },
    get_reload ({ name, model }) {
      if (name === 'nuevoClase' || name === 'updateDate') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'La cita se registró correctamente' } })
          setTimeout(() => { this.HIDDE_DIALOG_ALERT() }, 1500)
        }
      }
      if (name === 'delete') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'La cita se ha eliminado correctamente' } })
          setTimeout(() => { this.HIDDE_DIALOG_ALERT() }, 1500)
        }
      }

      this.initData()
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.initData()
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    /* *==========*==========* */
    /* *==========*==========* */
    initData () {
      const today = new Date()
      // const dayfinal = this.AdditiontoDay(today, 7)

      const initDate = this.formatoFecha(today, 'yyyy-mm-dd')
      // const endDate = this.formatoFecha(dayfinal, 'yyyy-mm-dd')
      this.GETListObjectsService({ url: `/AppointmentAvailableDates?initDate=${initDate}` })
    },
    successTableAction (item) {
      this[item.action](item)
    },
    newData (item) {
      this.form = this.get_ScheduleCitaForm
      this.object = {}
      this.url = '/AppointmentAvailableDates'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'nuevoClase', title: 'Nueva Cita', width: 600, params: { returnObjet: true } })
    },
    updateData ({ item }) {
      const dateitem = this.formatoFecha(this.dateStringToDate(item.date), 'yyyy-mm-dd')
      const timeitem = this.timeStringToDate(item.date)
      this.object = { id: item.id, date: dateitem, time: timeitem }

      this.form = this.get_ScheduleCitaForm
      this.url = `/AppointmentAvailableDates/${item.id}`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'updateDate', title: 'Editar Cita', width: 600, params: { returnObjet: true } })
    },
    deleteData ({ item }) {
      console.log(item)
      this.form = this.get_DeleteForm
      this.object = { clientId: item.clientId }
      this.url = `/AppointmentAvailableDates/${item.id}`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'delete', title: 'Finalizar Terapia', width: 600, params: { returnObjet: true } })
    }
  }
}
</script>

<style scoped>
</style>
