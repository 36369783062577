import Vue from 'vue'

export const HeaderGral = () => {
  // const token = Vue.$cookies.get('token_pv')
  // Authorization: `Bearer ${token}`
  const headers = {
    headers: {
      'Access-Control-Allow-Origin': '*'
      // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
  }
  return headers
}
export const HeaderMultipart = (boundary) => {
  const headers = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Content-Type': 'multipart/form-data' + boundary
    }
  }
  return headers
}
export const HeaderConekta = (token) => {
  // const token = Vue.$cookies.get('token_pv')
  // Authorization: `Bearer ${token}`
  // window.btoa(action.token)
  const headers = {
    headers: {
      Accept: 'application/vnd.conekta-v2.1.0+json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${window.btoa(token)}`
    }
  }
  return headers
}

export const UserSession = () => {
  const user = Vue.$cookies.get('user_sny')
  return user
}
export const IsUserSession = () => {
  const exist = Vue.$cookies.isKey('user_sny')
  return exist
}
