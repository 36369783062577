<template>
  <v-footer :color="color">
    <v-card :color="color" width="100%" flat tile>
      <v-card-text class="text-center">
        <p class=" white--text text-md-h2 text-sm-h3 text-h4  font-weight-bold font-italic">
          <span :class="`${className} text-strong-1`">
            movement
            <span :class="colorIcon"> + </span>
            recovery
            <span :class="colorIcon"> = </span>
            wellness
          </span>
        </p>
      </v-card-text>
    </v-card>
    <!--
    <v-layout justify-center>
      <v-flex xs12 sm8 md8>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 sm8 md8>
        <p class="color-state-white text-center text-h2 font-weight-bold py-10">
          <span>movement <span class="color-letra-blue-light font-weight-light">+</span> recovery
            <span class="color-letra-blue-light font-weight-light">=</span> wellness
          </span>
        </p>
      </v-flex>
    </v-layout>
    -->
  </v-footer>
</template>

<script>
export default {
  props: ['color', 'className', 'colorIcon'],
  data: () => ({
  }),
  mounted () {}
}
</script>

<style scoped>
.color-state-blue {
  color:#041a72;
  font-style: italic;
}
.color-state-white {
  color:#FFFFFF;
  font-style: italic;
}

</style>
