<template>
  <v-dialog persistent justify-center v-model="get_dialogForm.dialog" :width="get_dialogForm.width">
    <div style="background-color: white;" class="pa-1">
      <v-layout justify-end>
        <v-btn :color="get_dialogForm.params.color" icon @click="close">
          <v-icon large>mdi-close-circle</v-icon>
        </v-btn>
      </v-layout>
          <v-row no-gutters dense>
            <v-col cols="12">
              <component :is="tipo" :model="model" :on-success="actions" />
            </v-col>
          </v-row>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ConsultaCitaDetailForm from '@/components/forms/fisio/ConsultaCitaDetailForm.vue'
import InfoPersonDetailForm from '@/components/forms/fisio/InfoPersonDetailForm.vue'
import ConsultaRiderDetailForm from '@/components/forms/cycling/ConsultaRiderDetailForm.vue'
export default {
  props:['model', 'tipo', 'OnSuccess'],
  data: () => ({}),
  computed: {
    ...mapGetters(['get_dialogForm'])
  },
  components: {
    ConsultaCitaDetailForm,
    InfoPersonDetailForm,
    ConsultaRiderDetailForm
  },
  methods: {
    ...mapMutations(['HIDDE_DIALOG_FORM']),
    actions (actons) {
      this.OnSuccess(actons)
      this.HIDDE_DIALOG_FORM()
    },
    close () {
      this.HIDDE_DIALOG_FORM()
    }
  }
}
</script>
<style>
</style>
