<template>
  <v-container fluid class="pa-0 ma-0">
    <div class="text-md-h3 py-5 color-azul background-azul-light container-height">
      <span class="ma-auto text-strong-1">
        Welcome to <span class="text-strong text-uppercase">synergy</span>
      </span>
    </div>
    <v-toolbar color="#001B71" elevation="0" dark>
      <v-toolbar-title class="ma-auto">
        <span class="text-md-h4 text-sm-h5 white--text">@synergyinmovement</span>
      </v-toolbar-title>
    </v-toolbar>

    <v-container fluid fill-height class="mt-10">
      <v-row dense>
        <v-layout justify-space-around>
          <v-flex xs12 sm10 md6>
            <v-card tile>
              <v-card-title>
                <div class="text-uppercase text-center ma-auto color-azul text-strong">Crear Cuenta</div>
              </v-card-title>
              <v-form ref="form">
                <v-card-text>
                  <v-col cols="12">
                    <label class="color-azul title">
                      <span class="text-strong-1">Email</span>
                    </label>
                    <v-text-field v-model="create.email" label="Email" :rules="emailRules" placeholder="...@gmail.com" solo></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label class="color-azul title">
                      <span class="text-strong-1">Contraseña</span>
                    </label>
                    <v-text-field v-model="create.password" type="password" label="Contraseña" :rules="fieldRequired" placeholder="**********" solo></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label class="color-azul title">
                      <span class="text-strong-1">Repetir Contraseña</span>
                    </label>
                    <v-text-field v-model="create.password2" type="password" label="Confirmar Contraseña" :rules="fieldRequired" placeholder="**********" solo></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="#CD7EE5" class="title" @click="CreateAccount" dark large block>
                      <span class="text-strong-1">Registrate</span>
                    </v-btn>
                  </v-col>
                </v-card-text>
                <v-progress-linear :active="loadingCreate" :indeterminate="true" color="#001B71" height="5" absolute bottom />
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout justify-space-around>
          <v-flex xs12 sm10 md6>
            <v-card tile>
              <v-card-title>
                <div class="text-uppercase text-center ma-auto color-azul text-strong">Iniciar Sesión</div>
              </v-card-title>
              <v-form ref="session">
                <v-card-text>
                  <v-col cols="12">
                    <label class="color-azul title">
                      <span class="text-strong-1">Email</span>
                    </label>
                    <v-text-field v-model="login.email" label="Email" :rules="fieldRequired" placeholder="...@gmail.com" solo></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label class="color-azul title">
                      <span class="text-strong-1">Contraseña</span>
                    </label>
                    <v-text-field v-model="login.password" type="password" :rules="fieldRequired" @keyup.enter="session" required solo />
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="#CD7EE5" class="title" @click="session" dark large block>
                      <span class="text-strong-1">Iniciar Sesión</span>
                    </v-btn>
                    <v-btn color="#CD7EE5" class="my-4 text-right subtitle-1" @click="reserAccount" text dark block>
                      <span class="text-strong-1">Restablecer Contraseña</span>
                    </v-btn>
                  </v-col>
                </v-card-text>
                <v-progress-linear :active="loading" :indeterminate="true" color="#001B71" height="5" absolute bottom />
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-row>
    </v-container>
    <AvisoDialog v-if="get_dialogAlert.dialog" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AvisoDialog from '@/components/dialogs/AvisoDialog'
import { Validate } from '@/mixins/validateFormMixin'
import router from '@/router'
export default {
  name: 'PhysioCalendar',
  components: {
    AvisoDialog
  },
  mixins: [Validate],
  data: () => ({
    showeye: false,
    create: { roleIds: [4], signId: 1 },
    login: {},
    loading: false,
    loadingCreate: false
  }),
  computed: {
    ...mapGetters(['get_dialogAlert', 'get_object_post', 'get_object_two_post'])
  },
  watch: {
    get_object_post (row) {
      if (row) {
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Cuenta creada correctamente' } })
        this.loadingCreate = false
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
          this.create = { roleIds: [4], signId: 1 }
        }, 2000)
        // console.log('row', row)
      }
    },
    get_object_two_post (row) {
      // console.log('row', row)
      this.loading = false
      if (row.message) {
        this.SHOW_DIALOG_ALERT({ title: 'Error !', width: 500, params: { message: 'Credenciales no válidas.' } })
        return
      }
      const user = {
        id: row.id,
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        phone: row.phoneNumber
      }
      this.$cookies.set('user_sny', user)
      localStorage.setItem('permisos', JSON.stringify(row.roles[0]))
      window.location = '/'
    }
  },
  mounted () {},
  methods: {
    ...mapActions(['POSTObjectService', 'POSTObjectTwoService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    CreateAccount () {
      if (this.$refs.form.validate()) {
        if (this.create.password === this.create.password2) {
          this.POSTObjectService({ url: '/Users', data: this.create })
          this.loadingCreate = true
          return
        }
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Las contraseñas debe ser idénticas' } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
        }, 2000)
      }
    },
    session () {
      if (this.$refs.session.validate()) {
        this.loading = true
        this.POSTObjectTwoService({ url: '/Users/login', data: this.login })
      }
    },
    reserAccount () {
      router.push({ name: 'ResetPasswordEmailView' })
    }
  }
}
</script>

<style>

</style>
