<template>
  <v-card width="auto" elevation="0">
    <v-card-text class="px-5">
      <v-row dense>
        <v-col cols="12">
          <p outlined class="chip-view-blue-line background-line-azul-light">
            <label class="title font-weight-bold text-uppercase color-azul-medium">Consulta de Fisioterapia</label>
          </p>
        </v-col>
        <v-col cols="6">
          <label class="color-azul-medium subtitle-1 font-weight-bold">Fisioterapeuta</label>
        </v-col>
        <v-col cols="6" class="text-right">
          <label class="color-azul subtitle-1 font-weight-bold">{{model.therapist.firstName}} {{model.therapist.lastName}}</label>
        </v-col>

        <v-col cols="6">
          <label class="color-azul-medium subtitle-1 font-weight-bold">Fecha y Hora</label>
        </v-col>
        <v-col cols="6" class="text-right">
          <label class="color-azul subtitle-1 font-weight-bold">{{model.date}} {{model.hour}} hrs</label>
        </v-col>

        <v-col cols="6">
          <label class="color-azul-medium subtitle-1 font-weight-bold">No. de Cita</label>
        </v-col>
        <v-col cols="6" class="text-right">
          <label class="color-azul subtitle-1 font-weight-bold">{{model.appointNo}}</label>
        </v-col>

        <v-col cols="6">
          <label class="color-azul-medium subtitle-1 font-weight-bold">Motivo de Consulta</label>
        </v-col>
        <v-col cols="6" class="text-right">
          <label class="color-azul subtitle-1 font-weight-bold">{{model.appointReason}}</label>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row dense class="px-5">
        <v-col cols="6">
          <label class="color-azul caption">Paciente: {{model.client.firstName}} {{model.client.lastName}}</label>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn @click="onCancel" color="#355DC8" dark>Cancelar Cita</v-btn>
          <p class="color-azul caption font-weight-bold">Debera ser cancelada 48 hrs antes</p>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ConsultaCitaDetailForm',
  props: ['model', 'onSuccess'],
  data: () => ({
    message: '',
    show: false,
    selectActividadField: {
      name: 'Empresa',
      nameid: 'empresa_id',
      url: '/empresas',
      value: 'id'
    }
  }),
  computed: {
    ...mapGetters([])
  },
  methods: {
    onCancel () {
      this.onSuccess({ action: 'CancelarCita', item: this.model })
    }
  }
}
</script>

<style>
</style>
