<template>
  <v-container fluid>
    <!-- <v-toolbar color="#d7e7ff" elevation="0" dark>
      <v-toolbar-title class="ma-auto">
        <label class="text-md-h4 text-sm-h5 text-xs-h6 text-uppercase">
          <span class="text-strong-1 white--text">agenda semanal</span>
        </label>
      </v-toolbar-title>
    </v-toolbar> -->

    <v-row dense>
      <v-col
        cols="12"
        sm="4"
        md="3"
      >
        <v-card
          color="#001B71"
          tile
          dark
          height="100%"
        >
          <v-card-title class="text-center">
            <h2 class="text-strong text-uppercase ma-auto color-morado mt-2">
              Contacto
            </h2>
          </v-card-title>
          <v-card-text>
            <v-container fill-height>
              <v-row
                align="center"
                justify="center"
                dense
              >
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <p class="p-1 white--text font-weight-bold py-5">
                    <v-icon color="#a782b9">
                      mdi-phone
                    </v-icon>
                    <span class="text-light">(999) 908 3852</span>
                  </p>
                  <p class="p-1 white--text font-weight-bold py-5">
                    <v-icon color="#a782b9">
                      mdi-web
                    </v-icon>
                    <span class="text-light color-morado"> www.synergyinmovement.com</span>
                  </p>
                  <p class="p-1 white--text font-weight-bold py-5">
                    <v-icon color="#a782b9">
                      mdi-map-marker
                    </v-icon>
                    <span class="text-light"> Plaza Alpha, Temozón. Mérida, Yucatán.</span>
                  </p>
                  <p class="p-1 white--text font-weight-bold py-5">
                    <v-icon color="#a782b9">
                      mdi-view-dashboard
                    </v-icon>
                    <span class="text-light color-morado"> @synergyinmovement</span>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="9"
      >
        <v-card
          color="#A782B9"
          tile
          dark
          min-height="550"
          elevation="0"
        >
          <v-card-title class="text-center">
            <h2 class="text-strong text-uppercase ma-auto color-azul mt-2">
              faq`s
            </h2>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                class="text-center"
              >
                <div class="white--text py-3">
                  <p class="text-strong">
                    ¿ Qué tipo de lesiones pueden atender en fisioterapia ?
                  </p>
                  <label class="text-light p-x4 color-azul font-weight-bold">
                    Nos encargamos de mejorar las capacidades físicas de los pacientes (movilidad, flexibilidad, fuerza y resistencia) así como la funcionalidad general. Atendemos lesiones del sistema musculoesqueletico como dolor en las articulaciones, esguinces y/o torceduras, post-fracturas, artritis, artrosis, sobrecargas musculares y lesiones deportivas; padecimientos neurológicos como hernia, ciática, embolia, entre otros; problemas del sistema circulatorio, respiratorio, cardiovascular, por mencionar algunos.
                  </label>
                </div>

                <div class="white--text py-3">
                  <p class="text-strong">
                    ¿ Qué debo esperar en la primera sesión ?
                  </p>
                  <label class="text-light p-x4 color-azul font-weight-bold">
                    Se realizará una entrevista para conocer las condiciones actuales del paciente, así como una evaluación física con objetivo de encontrar el origen del padecimiento actual y con ello, establecer estrategias de intervención.
                  </label>
                </div>

                <div class="white--text py-3">
                  <p class="text-strong">
                    ¿ Cuáles son las áreas en donde interviene un fisioterapeuta ?
                  </p>
                  <label class="text-light p-x4 color-azul font-weight-bold">
                    Las áreas de la fisioterapia son: neurológica, padiatrica, geriátrica, cardiorrespiratoria, ortopédica y deportiva, entre otras.
                  </label>
                </div>
                <div class="white--text py-3">
                  <p class="text-strong">
                    ¿ Cómo debo de ir vestido o vestida a mis sesiones ?
                  </p>
                  <label class="text-light p-x4 color-azul font-weight-bold">
                    Recomendamos que uses ropa cómoda que nos permita alcanzar la zona que se va a tratar. Por ejemplo, si nos visitas para que chequemos tu hombro, una prenda sin mangas o de tirantes sería lo ideal.
                  </label>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
    >
      <v-col cols="12">
        <FooterMain
          color="grey lighten-5"
          class-name="color-azul"
          color-icon="color-morado"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CirculeField from '@/components/fields/CirculeField.vue'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import router from '@/router'
export default {
  name: 'PhysioCalendar',
  components: {
    CirculeField
  },
  mixins: [DatesUtils],
  data: () => ({
    showCalendar: false,
    week: [],
    hoursAM: [],
    hoursPM: []
  }),
  computed: {
    ...mapGetters(['get_objects'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        // console.log('rows', rows)
        this.selectionDay(rows)
      }
    }
  },
  mounted () {
    // this.week = this.rangeDatetoWeek()
    // this.GETListObjectsService({ url: `/AppointmentAvailableDates?initDate=${this.week.start}&endDate=${this.week.final}` })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    selectionDay (rows) {
      for (let index = 0; index < this.week.days.length; index++) {
        let clase = {}
        clase = this.week.days[index]
        if (clase.name === 'Dom') continue

        console.log('clase', clase)
        clase.hours_am = []
        clase.hours_pm = []

        const i = this.dateStringToDate(`${clase.date} 00:00:00`)
        const f = this.dateStringToDate(`${clase.date} 11:59:59`)

        const ii = this.dateStringToDate(`${clase.date} 12:00:00`)
        const ff = this.dateStringToDate(`${clase.date} 23:59:59`)
        for (let index = 0; index < rows.length; index++) {
          const element = rows[index]
          const compare = this.dateStringToDate(element.date)
          const hour = this.timeStringToDate(element.date)

          const morning = this.compareBetweenDate(i, compare, f)
          const afternoon = this.compareBetweenDate(ii, compare, ff)

          const today = new Date()
          const fechaEvent = this.dateStringToDate(element.date)
          const result = this.compareDate(fechaEvent, today)
          element.datestring = this.formatoFecha(fechaEvent, 'dd-mm-yyyy')
          if (result) {
            element.show = true
          }
          if (morning) {
            element.hour = `${hour}`
            clase.hours_am.push(element)
          } else if (afternoon) {
            element.hour = `${hour}`
            clase.hours_pm.push(element)
          }
        }
      }
      this.showCalendar = true
      this.hoursAM = Object.assign([], this.week)
    },
    actionItem (item) {
      const today = new Date()
      const fechaEvent = this.dateStringToDate(item.date)
      const result = this.compareDate(fechaEvent, today)
      if (result) {
        router.push({ name: 'PhysioCitaConfirmView', params: { id: item.id } })
      } else {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'La cita a expirado', confirm: true } })
        // setTimeout(() => { this.HIDDE_DIALOG_ALERT() }, 1500)
      }
      // console.log(result)
    }
  }
}
</script>

<style>

</style>
