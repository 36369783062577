import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '../views/HomeView.vue'
import NosotrasView from '../views/Nosotras.view.vue'
import ProfileView from '../views/Profile.view.vue'
import ValidateEmailView from '../views/ValidateEmail.view.vue'
import ResetPasswordEmailView from '../views/ResetPasswordEmail.view.vue'
import ResetPasswordView from '../views/ResetPassword.view.vue'

import ProvacidadView from '../views/politicas/Provacidad.view.vue'
import TerminoyCondicionesView from '../views/politicas/TerminoyCondiciones.view.vue'

import CyclingView from '../views/CyclingView.vue'
import CyclingPaquetesView from '../views/cycling/CyclingPaquetes.view.vue'
import CyclingConfirmPaidView from '../views/cycling/CyclingConfirmPaid.view.vue'

import CyclingCoachesView from '../views/cycling/CyclingCoaches.view.vue'

import CyclingCalendarView from '../views/cycling/CyclingCalendar.view.vue'
import CyclingMapsClassView from '../views/cycling/CyclingMapsClass.view.vue'
import CyclingCitaConfirmView from '../views/cycling/CyclingCitaConfirm.view.vue'

import PhysioView from '../views/PhysioView.vue'
import PhysioCalendarView from '../views/physio/PhysioCalendarView.vue'
import PhysioPersonsView from '../views/physio/PhysioPersons.view.vue'

import MyAccountView from '@/views/client/MyAccount.view.vue'
import MyAccountUserView from '@/views/client/MyAccountUser.view.vue'
import MyAccountListWaitView from '@/views/client/MyAccountListWait.view.vue'
import MyAccounPaidsView from '@/views/client/MyAccountPaids.view.vue'
import MyAccounEnrollmentsView from '@/views/client/MyAccountEnrollments.view.vue'
import MyAccountPackagesView from '@/views/client/MyAccountPackages.view'

import MyAccountCoaches from '@/views/client/MyAccountCoaches.view.vue'
import MyAccountRiders from '@/views/client/MyAccountRiders.view.vue'
import MyAccountSpinning from '@/views/client/MyAccountSpinning.view.vue'

import PhysioExpediente from '@/views/client_fisio/PhysioExpediente.view.vue'
import PhysioCitas from '@/views/client_fisio/PhysioCitas.view.vue'
import PhysioCitasPacientesView from '@/views/client_fisio/PhysioCitasPacientes.view.vue'
import PhysioHistorial from '@/views/client_fisio/PhysioHistorial.view.vue'
import PhysioPaids from '@/views/client_fisio/PhysioPaids.view.vue'
import PhysioPatients from '@/views/client_fisio/PhysioPatients.view.vue'
import PhysioFisios from '@/views/client_fisio/PhysioFisios.view.vue'

import PhysioAgendaView from '@/views/client_fisio/PhysioAgenda.view.vue'
import PhysioCitaConfirmView from '@/views/physio/PhysioCitaConfirm.view.vue'

import { UserSession } from '@/store/modules/config'

Vue.use(VueRouter)
const guardMyroute = (to, from, next) => {
  const user = UserSession()
  // var isAuthenticated= false;
  if (user) {
    next()
  } else {
    next({ name: 'home' })
  }
}
const routesAll = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Nosotras',
    name: 'NosotrasView',
    component: NosotrasView
  },
  {
    path: '/Perfil',
    name: 'PerfilView',
    component: ProfileView
  },
  {
    path: '/emailToken/:token',
    name: 'ValidateEmailView',
    component: ValidateEmailView
  },
  {
    path: '/Restablecer/Acceso',
    name: 'ResetPasswordEmailView',
    component: ResetPasswordEmailView
  },
  {
    path: '/resetPassword/:token',
    name: 'ResetPasswordView',
    component: ResetPasswordView
  },

  {
    path: '/Politica/AvisoPrivacidad',
    name: 'AvisoPrivacidadView',
    component: ProvacidadView
  },
  {
    path: '/Politica/TerminoyCondiciones',
    name: 'TerminoyCondicionesView',
    component: TerminoyCondicionesView
  },

  /* Paginas Principales */
  {
    path: '/Cycling',
    name: 'CyclingView',
    component: CyclingView
  },
  {
    path: '/Cycling/Comprar',
    name: 'CyclingComprarView',
    component: CyclingPaquetesView
  },
  {
    path: '/Cycling/Comprar/ConfirmarPago/:id',
    name: 'CyclingConfirmPaidView',
    component: CyclingConfirmPaidView
  },
  {
    path: '/Cycling/Coaches',
    name: 'CyclingCoachesView',
    component: CyclingCoachesView
  },
  {
    path: '/Cycling/Reservar',
    name: 'CyclingCalendarView',
    component: CyclingCalendarView
  },
  {
    path: '/Cycling/Reservar/MapaClase/:id',
    name: 'CyclingMapsClassView',
    component: CyclingMapsClassView
  },
  {
    path: '/Cycling/Reservar/Confirmar/:id/:position',
    name: 'CyclingCitaConfirmClassView',
    component: CyclingCitaConfirmView
  },

  {
    path: '/Physio',
    name: 'PhysioView',
    meta: {
      fisio: true
    },
    component: PhysioView
  },
  {
    path: '/Physio/Agendar',
    name: 'PhysioCalendarView',
    meta: {
      fisio: true
    },
    component: PhysioCalendarView
  },
  {
    path: '/Physio/PhysioPersons',
    name: 'PhysioPersonsView',
    meta: {
      fisio: true
    },
    component: PhysioPersonsView
  },

  // My Account Cycling

  {
    path: '/MiCuenta/Calendario',
    name: 'MyAccountCalendarView',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccountView
  },
  {
    path: '/MiCuenta/Usuario',
    name: 'MyAccountUserView',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccountUserView
  },
  {
    path: '/MiCuenta/ListaEspera',
    name: 'MyAccountListWaitView',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccountListWaitView
  },
  {
    path: '/MiCuenta/Pagos',
    name: 'MyAccountPaidsView',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccounPaidsView
  },
  {
    path: '/MiCuenta/PaquetesVigentes',
    name: 'MyAccounEnrollmentsView',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccounEnrollmentsView
  },
  {
    path: '/MiCuenta/Paquetes',
    name: 'MyAccountPackagesView',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccountPackagesView
  },
  {
    path: '/MiCuenta/Coaches',
    name: 'MyAccounCoachesView',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccountCoaches
  },
  {
    path: '/MiCuenta/Riders',
    name: 'MyAccounRidersView',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccountRiders
  },
  {
    path: '/MiCuenta/SpinningClases',
    name: 'MyAccounSpinningClass',
    meta: {
      account: true
    },
    beforeEnter: guardMyroute,
    component: MyAccountSpinning
  },

  // My Account Fisio
  {
    path: '/MiCuenta/Fisio/Expedientes',
    name: 'PhysioExpedienteView',
    meta: {
      account: true,
      fisio: true
    },
    beforeEnter: guardMyroute,
    component: PhysioExpediente
  },
  {
    path: '/MiCuenta/Fisio/Citas',
    name: 'PhysioCitasView',
    meta: {
      account: true,
      fisio: true
    },
    beforeEnter: guardMyroute,
    component: PhysioCitas
  },
  {
    path: '/MiCuenta/Fisio/CitasPacientes',
    name: 'PhysioCitasPacientesView',
    meta: {
      account: true,
      fisio: true
    },
    beforeEnter: guardMyroute,
    component: PhysioCitasPacientesView
  },
  {
    path: '/MiCuenta/Fisio/Historial',
    name: 'PhysioHistorialView',
    meta: {
      account: true,
      fisio: true
    },
    beforeEnter: guardMyroute,
    component: PhysioHistorial
  },
  {
    path: '/MiCuenta/Fisio/Pagos',
    name: 'PhysioPaidsView',
    meta: {
      account: true,
      fisio: true
    },
    beforeEnter: guardMyroute,
    component: PhysioPaids
  },
  {
    path: '/MiCuenta/Fisio/Pacientes',
    name: 'PhysioPatientsView',
    meta: {
      account: true,
      fisio: true
    },
    beforeEnter: guardMyroute,
    component: PhysioPatients
  },
  {
    path: '/MiCuenta/Fisio/Fisios',
    name: 'PhysioFisiosView',
    meta: {
      account: true,
      fisio: true
    },
    beforeEnter: guardMyroute,
    component: PhysioFisios
  },
  {
    path: '/MiCuenta/Fisio/Agenda',
    name: 'PhysioAgendaView',
    meta: {
      account: true,
      fisio: true
    },
    beforeEnter: guardMyroute,
    component: PhysioAgendaView
  },
  {
    path: '/Fisio/Agendar/Confirmar/:id',
    name: 'PhysioCitaConfirmView',
    component: PhysioCitaConfirmView
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routesAll,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
