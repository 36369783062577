export const DatesUtils = {
  data () {
    return {
      daysWeek: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      weekAbbr: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthsObj: [
        { id: '01', name: 'Enero' },
        { id: '02', name: 'Febrero' },
        { id: '03', name: 'Marzo' },
        { id: '04', name: 'Abril' },
        { id: '05', name: 'Mayo' },
        { id: '06', name: 'Junio' },
        { id: '07', name: 'Julio' },
        { id: '08', name: 'Agosto' },
        { id: '09', name: 'Septiembre' },
        { id: '10', name: 'Octubre' },
        { id: '11', name: 'Noviembre' },
        { id: '12', name: 'Diciembre' }
      ],
      monthsAbbreviation: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    }
  },
  methods: {
    getDateTime () {
      const hoy = new Date()
      return hoy.getTime()
    },
    theWeekDay (date) {
      const today = new Date(date)
      const theDayWeek = today.getDay()
      const nameday = this.weekAbbr[theDayWeek]
      return nameday
    },
    rangeDatetoWeek () {
      const list = []
      const todate = new Date()
      // const finaldate = this.AdditiontoDay(todate, 8)
      for (let index = 0; index < 7; index++) {
        const day = this.AdditiontoDay(todate, index)
        const FF = this.formatoFecha(day, 'yyyy-mm-dd')
        const nameday = this.weekAbbr[day.getDay()]
        if (nameday === 'Dom') continue
        list.push({ name: nameday, date: FF, day: day.getDate() })
      }

      // if (range.length > 0) {

      // } 
      // else {
      //   const FI = this.formatoFecha(start, 'yyyy-mm-dd')
      //   const dayWeek = this.weekAbbr[start.getUTCDay()]
      //   list.push({ name: dayWeek, day: start.getDate(), date: FI })
      //   for (let index = 1; index <= 6; index++) {
      //     const netx = this.AdditiontoDay(start, index)
      //     const FF = this.formatoFecha(netx, 'yyyy-mm-dd')
      //     const nameday = this.weekAbbr[netx.getUTCDay()]
      //     list.push({ name: nameday, date: FF, day: netx.getDate() })
      //   }
      // }
      const i = list[0].date
      const f = list[list.length - 1].date
      const data = { start: i, final: f, days: list, hours_am: [], hours_pm: [] }
      return data
    },
    formatoFecha (fecha, formato) {
      const map = {
        dd: (fecha.getDate() < 10) ? '0' + fecha.getDate().toString() : fecha.getDate().toString(),
        mm: ((fecha.getMonth() + 1) < 10) ? '0' + (fecha.getMonth() + 1).toString() : (fecha.getMonth() + 1).toString(),
        yyyy: fecha.getFullYear()
      }
      return formato.replace(/dd|mm|yyyy/gi, matched => map[matched])
    },
    AdditiontoDay (fecha, dias) {
      fecha = new Date((fecha.getTime() + (dias * 24 * 60 * 60 * 1000)))
      return fecha
    },
    dateStringToDate (date) {
      const converDate = new Date(date)
      return converDate
    },
    timeStringToDate (date, locale = false) {
      const converDate = new Date(date)
      const options = { hour: '2-digit', minute: '2-digit' }
      let now = null
      if (locale) {
        now = converDate.toLocaleTimeString('en-US')
      } else {
        now = converDate.toLocaleTimeString([], options)
      }
      return now
    },
    compareBetweenDate (init, date, final) {
      if ((date.getTime() >= init.getTime()) && (date.getTime() <= final.getTime())) {
        return true
      }
      return false
    },
    nameDayweek (date) {
      const converDate = new Date(date)
      const dayWeek = this.daysWeek[converDate.getUTCDay()]
      return dayWeek
    },
    compareDate (f1, f2) {
      return (f1 > f2)
    },

    dateStringToyearMonth () {
      const fecha = new Date()
      const month = ((fecha.getMonth() + 1) < 10) ? '0' + (fecha.getMonth() + 1).toString() : (fecha.getMonth() + 1).toString()
      const year = fecha.getFullYear()

      return `${year}-${month}`
    },
    formatoFechaTitle (fecha) {
      if (!fecha) return
      const dayWeek = this.daysWeek[fecha.getUTCDay()]
      const day = fecha.getDate()
      const month = this.months[fecha.getMonth()]
      const year = fecha.getFullYear()
      return `${dayWeek} ${day} de ${month} ${year}`
    },
    formatoFechaAbreviacion (fecha) {
      if (fecha) {
        let f = ''
        if (fecha.length > 10) f = fecha.substring(0, 10)
        else f = fecha
        const divider = f.split('-')
        const day = divider[2]
        const month = this.monthsAbbreviation[(divider[1] - 1)]
        const year = divider[0]
        return `${day}-${month}-${year}`
      }
      return 'Sin Fecha'
    },
    compareDateEqual ({ f1, f2 }) {
      const date1 = this.dateStringToDate(f1.substring(0, 10))
      const date2 = this.dateStringToDate(f2.substring(0, 10))
      return (date1 > date2)
    },
    AgeBirthDate (date) {
      const hoy = new Date()
      const cumpleanos = new Date(date)
      let edad = hoy.getFullYear() - cumpleanos.getFullYear()
      const m = hoy.getMonth() - cumpleanos.getMonth()
      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--
      }
      return `${edad} años`
    },
    BirthDateName (date) {
      const birthdate = new Date(date)
      const day = birthdate.getDate()
      const month = this.months[birthdate.getMonth()]
      return `${day} de ${month}`
    },
    obtenerFechaInicioDeMes () {
      const fechaInicio = new Date()
      return new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), 1)
    },
    obtenerFechaFinDeMes () {
      const fechaFin = new Date()
      return new Date(fechaFin.getFullYear(), fechaFin.getMonth() + 1, 0)
    }
  }
}
