<template>
  <v-container>
    <v-form ref="form">
      <v-row dense>
        <v-col cols="12">
          <p class="text-md-h3 text-center text-strong color-azul">
            <span class="text-strong"> Expediente Médico </span>
          </p>
        </v-col>
        <v-col cols="6">
          <CirculePhotoLoad :model="medicalRecord" :on-success="loadPhoto" color="#C9E7FF" classname="circle-blue" :fisio="true"/>
        </v-col>
        <v-col cols="6">
          <label class="color-azul   px-2">Nombre/s</label>
          <v-text-field v-model="medicalRecord.firstName" :rules="fieldRequired" dense solo />
          <label class="color-azul   px-2">Apellido/s</label>
          <v-text-field v-model="medicalRecord.lastName" :rules="fieldRequired" dense solo />
        </v-col>

        <v-col cols="4">
          <label class="color-azul   px-2">Fecha Nacimiento</label>
          <DatePickerSolo :item-action="fieldDateInit" :setterModel="medicalRecord[fieldDateInit.nameid]" :on-success="actionFieldData" />
        </v-col>
        <v-col cols="4">
          <label class="color-azul   px-2">Celular</label>
          <v-text-field v-model="medicalRecord.phoneNumber" :rules="fieldRequired" dense solo />
        </v-col>
        <v-col cols="4">
          <label class="color-azul   px-2">Emergencia</label>
          <v-text-field v-model="medicalRecord.emergencyNum" :rules="fieldRequired" dense solo />
        </v-col>

        <v-col cols="8">
          <label class="color-azul   px-2">Email</label>
          <v-text-field v-model="medicalRecord.email" :rules="emailRules" dense solo />
        </v-col>
        <v-col cols="4"></v-col>

        <v-col cols="4">
          <label class="color-azul   px-2">Referencia</label>
          <v-text-field v-model="medicalRecord.emergencyContact" :rules="fieldRequired" dense solo />
        </v-col>
        <v-col cols="8">
          <label class="color-azul   px-2">Alergia y Enfermedades</label>
          <v-text-field v-model="medicalRecord.alergies" :rules="fieldRequired" dense solo />
        </v-col>
        <!-- <v-col cols="4">
          <label class="color-morado px-2">Enfermedades</label>
          <v-text-field v-model="medicalRecord.enfermedad" :rules="fieldRequired" dense solo />
        </v-col> -->
        <v-col cols="12">
          <v-layout justify-end>
            <v-btn color="#001B71" @click="updateUser" dark>
              <v-icon left dark>mdi-plus</v-icon> Guardar
            </v-btn>
          </v-layout>
        </v-col>

        <!-- <v-col cols="12">
          <label class="color-azul text-strong px-2">Motivo de consulta</label>
          <v-textarea class="textArea-border-radius" background-color="#CBE2F6" color="#CBE2F6" solo></v-textarea>
        </v-col> -->
      </v-row>
    </v-form>
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CirculePhotoLoad from '@/components/fields/account/CirculePhotoLoadField'
import DatePickerSolo from '@/components/fields/account/DatePicker_solo'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import { Validate } from '@/mixins/validateFormMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    MainFormDialog,
    CirculePhotoLoad,
    DatePickerSolo
  },
  mixins: [DatesUtils, Validate],
  data: () => ({
    medicalRecord: {
      id: 0
    },
    fieldDateInit: { field: 'textDatePicker', name: '', nameid: 'birthDate', rules: true, cols: 3 },
    fieldDateFinal: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'final', cols: 3 },
    user: {},
    form: [],
    object: {},
    url: '',
    message: ''
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_object', 'get_object_put', 'get_reload',
      'get_FilePhotoLoad'])
  },
  watch: {
    get_object (row) {
      if (row) {
        this.medicalRecord = row
        this.medicalRecord.RoleIds = row.roles.map(item => {
          return item.id
        })
        this.medicalRecord.signId = row.sign.id
      }
    },
    get_object_put (row) {
      if (row) {
        this.account = row
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Informacion de usuario actualizado', color: '#041A72' } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
          this.GETObjectService({ url: `/Users/${this.user.id}` })
        }, 1500)
      }
    },
    get_reload ({ name, model }) {
      if (name === 'formData') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Imagen agregado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
            this.GETObjectService({ url: `/Users/${this.user.id}` })
          }, 1500)
        }
      }
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.GETObjectService({ url: `/Users/${this.user.id}` })
  },
  methods: {
    ...mapActions(['GETObjectService', 'PUTObjectService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'SHOW_DIALOG_MAIN', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    /* *==========*==========*==========*==========*==========* */
    loadPhoto (model) {
      this.form = this.get_FilePhotoLoad
      this.object = {}
      this.url = `/Users/${this.user.id}/uploadImage`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'formData', title: 'Cargar Foto', width: 500, params: { content: 'form-data', returnObjet: true } })
    },
    actionFieldData (obj) {
      this.medicalRecord[obj.id] = obj.data === undefined ? null : obj.data
    },
    updateUser () {
      if (this.$refs.form.validate()) {
        this.PUTObjectService({ url: `/Users/${this.user.id}`, data: this.medicalRecord })
      } else {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Hay campos obligatorios' } })
      }
    }
  }
}
</script>

<style scoped>
.textArea-border-radius {
  border-radius: 0px 0px 50px 50px !important;
}
</style>
