<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-tabs color="#602F73" background-color="#ECDEF7" grow>
          <v-tab class="table-tab mr-5 text-strong" @click="proximasClases">Proximas Clases</v-tab>
          <v-tab class="table-tab ml-5 text-strong" @click="pasadasClases">Clases Pasadas</v-tab>
        </v-tabs>
        <div v-if="showListCircule">
          <v-layout justify-center class="background-line-azul">
            <v-flex md2>
              <h2 class="text-center color-azul"> Lun </h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Mar </h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Mie </h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Jue </h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Vie</h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Sab </h2>
              <v-divider/>
            </v-flex>
          </v-layout>

          <v-layout justify-center align-center class="background-line-azul container-height-350">
            <v-flex md2>
              <span v-for="(item, index) in L" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#ECDEF7" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in Mar" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#ECDEF7" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in Mie" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#ECDEF7" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in J" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#ECDEF7" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in V" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#ECDEF7" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in S" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#ECDEF7" />
              </span>
            </v-flex>
          </v-layout>
        </div>
        <DataTableMain v-if="showList" :datatable="table" :on-success='successTableAction' :itemsAction='successTableAction' />
      </v-col>
    </v-row>
    <ShowFormDialog v-if="get_dialogForm.dialog" :model="object" :tipo="tipoForm" :on-success="successTableAction"/>
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CirculeViewField from '@/components/fields/account/CirculeViewField'
import DataTableMain from '@/components/tables/DataTableMain'
import ShowFormDialog from '@/components/dialogs/ShowFormDialog'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    CirculeViewField,
    DataTableMain,
    ShowFormDialog,
    MainFormDialog
  },
  mixins: [DatesUtils],
  data: () => ({
    table: {
      color: '#ECDEF7',
      className: 'background-morado-light',
      header: {
        options: [],
        titles: [
          { text: 'Nombres', value: 'client.firstName', class: 'black--text' },
          { text: 'Apellidos', value: 'client.lastName', class: 'black--text' },
          { text: 'Fecha Clase', value: 'DateDinamic', class: 'black--text', showItem: 'spinningClass.date' },
          { text: 'Lugar', value: 'bike', class: 'black--text' },
          { text: 'Estatus', value: 'Status', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    },
    fieldDateInit: { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'start', cols: 3 },
    fieldDateFinal: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'final', cols: 3 },
    params: {
      start: '',
      final: ''
    },
    user: {},
    week: {
    },
    D: [],
    L: [],
    Mar: [],
    Mie: [],
    J: [],
    V: [],
    S: [],
    showListCircule: true,
    showList: false,
    form: {},
    url: '',
    message: '',
    rol: null
  }),
  computed: {
    ...mapGetters(['get_dialogForm', 'get_dialogMain', 'get_objects', 'get_reload', 'get_object', 'get_DeleteForm'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        if (this.showListCircule) {
          this.selectionDay(rows)
        } else {
          this.table.body.data = rows
        }
      }
    },
    get_object (row) {
      if (row.message) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Error de edicion de coache ' } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
        }, 1500)
        return
      }
      row.RoleIds = row.roles.map(i => {
        return i.id
      })
      this.showUpdateCita(row)
    },
    get_reload ({ name, model }) {
      if (name === 'updatePartial') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: 'Ride eliminado correctamente' } })
          if (this.showListCircule) this.proximasClases()
          else this.pasadasClases()
        } else {
          this.SHOW_DIALOG_ALERT({ title: 'Advertencia', width: 500, params: { message: 'Clase no cancelada. La cancelación debe hacerse al menos 7 horas antes de la clase' } })
        }
      }
    }
  },
  mounted () {
    this.rol = JSON.parse(localStorage.getItem('permisos'))
    this.user = this.$cookies.get('user_sny')
    this.week = this.rangeDatetoWeek([0, 1, 2, 3, 4, 5, 6])
    this.params.start = this.week.start
    this.params.final = this.week.final
    if (this.rol.name === 'CLIENT') {
      this.GETListObjectsService({ url: `/Enrollments?start=${this.week.start}&final=${this.week.final}&clientId=${this.user.id}&status=1` })
      return
    }
    this.GETListObjectsService({ url: `/Enrollments?start=${this.week.start}&final=${this.week.final}&status=1` })
  },
  methods: {
    ...mapActions(['GETListObjectsService', 'GETObjectService']),
    ...mapMutations(['SHOW_DIALOG_FORM', 'SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    /* *==========*==========*==========*==========*==========* */
    successTableAction (item) {
      this[item.action](item)
    },
    actionFieldData (obj) {
      this.params[obj.id] = obj.data === undefined ? null : obj.data
    },

    searchDates () {
      this.GETListObjectsService({ url: `/Enrollmetns?start=${this.params.start}&final=${this.params.final}&clientId=${this.user.id}&status=1` })
    },
    selectionDay (rows) {
      console.log('rows', rows)
      this.D = []
      this.L = []
      this.Mar = []
      this.Mie = []
      this.J = []
      this.V = []
      this.S = []
      for (let index = 0; index < rows.length; index++) {
        const clase = rows[index]
        const nameDay = this.nameDayweek(clase.spinningClass.date.substr(0, 10))
        const hour = this.timeStringToDate(clase.spinningClass.date)
        const fecha = this.dateStringToDate(clase.spinningClass.date)
        const dateformat = this.formatoFecha(fecha, 'dd-mm-yyyy')
        clase.hour = hour
        clase.date = dateformat
        console.log('date', clase.date)
        switch (nameDay) {
          case 'domingo':
            this.D.push(clase)
            break
          case 'lunes':
            this.L.push(clase)
            break
          case 'martes':
            this.Mar.push(clase)
            break
          case 'miércoles':
            this.Mie.push(clase)
            break
          case 'jueves':
            this.J.push(clase)
            break
          case 'viernes':
            this.V.push(clase)
            break
          case 'sábado':
            this.S.push(clase)
            break
        }
      }
    },
    proximasClases () {
      this.week = this.rangeDatetoWeek([0, 1, 2, 3, 4, 5, 6])
      this.params.start = this.week.start
      this.params.final = this.week.final
      if (this.rol.name === 'CLIENT') {
        this.GETListObjectsService({ url: `/Enrollments?start=${this.week.start}&final=${this.week.final}&clientId=${this.user.id}&status=1` })
        return
      } else this.GETListObjectsService({ url: `/Enrollments?start=${this.week.start}&final=${this.week.final}&status=1` })
      // this.GETListObjectsService({ url: `/Enrollments?start=${this.week.start}&final=${this.week.final}&clientId=${this.user.id}&status=1` })
      this.showList = false
      this.showListCircule = true
    },
    pasadasClases () {
      this.week = this.rangeDatetoWeek([0, 1, 2, 3, 4, 5, 6])
      this.params.start = this.week.start
      this.params.final = this.week.final
      this.GETListObjectsService({ url: `/Enrollments?final=${this.week.start}&clientId=${this.user.id}&status=1` })
      this.showListCircule = false
      this.showList = true
    },
    showDialog (model) {
      this.object = model
      this.tipoForm = 'ConsultaRiderDetailForm'
      this.SHOW_DIALOG_FORM({ type: 'formView', title: '', width: 500, params: { color: '#602F73' } })
      // this.GETObjectService({ url: `Enrollments/${model.id}`})
    },
    showUpdateCita (model) {
      this.object = model
      this.tipoForm = 'ConsultaRiderDetailForm'
      this.SHOW_DIALOG_FORM({ type: 'formView', title: '', width: 500, params: { color: '#001B71' } })
    },

    CancelarCita ({ item }) {
      this.form = this.get_DeleteForm
      this.object = {}
      this.url = `/Enrollments/${item.id}/Cancel`
      this.message = 'Cita eliminada correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'updatePartial', title: 'Eliminar', width: 500, params: { returnObjet: true } })
      // console.log('model', model)
    }
  }
}
</script>

<style scoped>
.table-calendar {
  border: 1px #602F73 solid;
  background-color:#ECDEF7;
}
.table-calendar thead {
  background-color:#ECDEF7 !important;
}
.table-tab {
  border: 1px #602F73 solid;
  border-radius: 25px 25px 0px 0px
}
.v-tab--active{
  border: 1px #602F73 solid;
  background-color: #602F73;
  color: white;
}
</style>
