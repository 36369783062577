<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" sm="4" md="3">
        <DatePicker :item-action="fieldDateInit" :on-success="actionFieldData" />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <DatePicker :item-action="fieldDateFinal" :on-success="actionFieldData" />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-btn color="#602F73" @click="searchDates" dark>
          <v-icon left dark>mdi-calendar-search</v-icon>
          Buscar
        </v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-tabs color="#602F73" background-color="#ECDEF7" grow>
          <v-tab class="table-tab mr-5" >Historial</v-tab>
          <v-tab class="table-tab ml-5" >Información</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <p class="text-md-h4 font-weight-bold text-center">
          No tiene clases Programadas
          <v-btn color="#602F73" @click="agendarCita" dark>
            <v-icon left dark>mdi-calendar-search</v-icon> Agendar Clase
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from '@/components/fields/DatePicker'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    DatePicker
  },
  mixins: [DatesUtils],
  data: () => ({
    fieldDateInit: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_init', cols: 3 },
    fieldDateFinal: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_final', cols: 3 },
    params: {},
    user: {}
  }),
  mounted () {
    this.user = this.$cookies.get('user_sny')
    const date = new Date()
    const fecha = this.formatoFecha(date, 'yyyy-mm-dd')
    this.params.fecha_init = fecha
    this.params.fecha_final = fecha
  },
  methods: {
    actionFieldData (obj) {
      this.params[obj.id] = obj.data === undefined ? null : obj.data
    },
    searchDates () {
    },
    agendarCita () {
    }
  }
}
</script>

<style scoped>
.table-calendar {
  border: 1px #602F73 solid;
}
th{
  border-bottom: 10px #602F73 solid;
}
.table-tab {
  border: 1px #602F73 solid;
  border-radius: 25px 25px 0px 0px
}
.v-tab--active{
  border: 1px #602F73 solid;
  background-color: #602F73;
  color: white;
}
</style>
