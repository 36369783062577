<template>
  <v-container fluid>
    <!--
    <v-row dense>
      <v-col cols="12" sm="4" md="3">
        <DatePicker :setterModel="params.start" :item-action="fieldDateInit" :on-success="actionFieldData" />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <DatePicker :setterModel="params.final" :item-action="fieldDateFinal" :on-success="actionFieldData" />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-btn color="#602F73" @click="searchDates" dark>
          <v-icon left dark>mdi-calendar-search</v-icon>
          Buscar
        </v-btn>
      </v-col>
    </v-row>
    -->

    <v-row dense>
      <v-col cols="12">
        <div class="table-tab font-weight-bold px-5 py-2 text-center text-strong">Lista de Espera</div>
        <v-simple-table class="table-calendar" dense fixed-header height="400px">
          <thead>
            <tr class="text-uppercase">
              <th class="text-h5 px-5 text-center background-morado-light">lun</th>
              <th class="text-h5 px-5 text-center background-morado-light">mar</th>
              <th class="text-h5 px-5 text-center background-morado-light">mie</th>
              <th class="text-h5 px-5 text-center background-morado-light">jue</th>
              <th class="text-h5 px-5 text-center background-morado-light">vie</th>
              <th class="text-h5 px-5 text-center background-morado-light">sab</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-uppercase text-center">
              <td>
                <span v-for="(item, index) in week.L" :key="index">
                  <CirculeViewField :model="item" />
                </span>
              </td>
              <td>
                <span v-for="(item, index) in week.Mar" :key="index">
                  <CirculeViewField :model="item" />
                </span>
              </td>
              <td>
                <span v-for="(item, index) in week.Mie" :key="index">
                  <CirculeViewField :model="item" />
                </span>
              </td>
              <td>
                <span v-for="(item, index) in week.J" :key="index">
                  <CirculeViewField :model="item" />
                </span>
              </td>
              <td>
                <span v-for="(item, index) in week.V" :key="index">
                  <CirculeViewField :model="item" />
                </span>
              </td>
              <td>
                <span v-for="(item, index) in week.S" :key="index">
                  <CirculeViewField :model="item" />
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DatePicker from '@/components/fields/DatePicker'
import CirculeViewField from '@/components/fields/account/CirculeViewField'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'

export default {
  name: 'CyclingMyAccount',
  components: {
    DatePicker,
    CirculeViewField
  },
  mixins: [DatesUtils],
  data: () => ({
    fieldDateInit: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'start', cols: 3 },
    fieldDateFinal: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'final', cols: 3 },
    params: {
      start: '',
      final: ''
    },
    user: {},
    week: {
      D: [],
      L: [],
      Mar: [],
      Mie: [],
      J: [],
      V: [],
      S: []
    }
  }),
  computed: {
    ...mapGetters(['get_objects'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.selectionDay(rows)
      }
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    const weekDay = this.rangeDatetoWeek([0, 1, 2, 3, 4, 5, 6])
    this.params.start = weekDay.start
    this.params.final = weekDay.final
    this.GETListObjectsService({ url: `/Enrollments?start=${this.params.start}&final=${this.params.final}&clientId=${this.user.id}&status=2` })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations([]),
    // *==========*==========*==========*==========* \\
    actionFieldData (obj) {
      this.params[obj.id] = obj.data === undefined ? null : obj.data
    },
    selectionDay (rows) {
      for (let index = 0; index < rows.length; index++) {
        const clase = rows[index]
        const nameDay = this.nameDayweek(clase.spinningClass.date)
        const hour = this.timeStringToDate(clase.spinningClass.date)
        clase.hour = hour
        console.log('nameDay', nameDay)
        switch (nameDay) {
          case 'domingo':
            this.week.D.push(clase)
            break
          case 'lunes':
            this.week.L.push(clase)
            break
          case 'martes':
            this.week.Mar.push(clase)
            break
          case 'miércoles':
            this.week.Mie.push(clase)
            break
          case 'jueves':
            this.week.J.push(clase)
            break
          case 'viernes':
            this.week.V.push(clase)
            break
          case 'sábado':
            this.week.S.push(clase)
            break
        }
      }
      this.showCalendar = true
    },
    searchDates () {
      this.GETListObjectsService({ url: `/Enrollments?start=${this.params.start}&final=${this.params.final}&clientId=${this.user.id}&status=2` })
    },
    agendarCita () {
    }
  }
}
</script>

<style scoped>
.table-calendar {
  border: 1px #602F73 solid;
  background-color:#ECDEF7;
}
.table-tab {
  width: 35%;
  background-color: #602F73;
  border: 1px #602F73 solid;
  border-radius: 25px 25px 0px 0px;
  color: white;
}
.v-tab--active{
  border: 1px #602F73 solid;
  background-color: #602F73;
  color: white;
}
</style>
