<template>
  <v-dialog v-model="get_dialogMain.dialog" :width="get_dialogMain.width" justify-center persistent>
    <v-layout justify-end></v-layout>
    <div class="container-dialog-main dialog-main pa-1 container-relative">
      <div class="container-absolute-right pr-1 pt-2">
        <v-btn :color="get_dialogMain.params.fisio ? '#001B71': '#764C9A'" icon @click="close">
          <v-icon large>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-row dense>
        <v-col cols="12" sm="4" md="4">
          <v-img v-if="model.bannerPhoto" class="img-link dialog-image" :src="model.bannerPhoto"></v-img>
          <v-img v-else src="@/assets/fondo2.png" />
        </v-col>
        <v-col cols="12" sm="8" md="8">
          <v-row v-if="get_dialogMain.params.fisio" dense class="pl-4">
            <v-col cols="12" class="text-h6 text-sm-h5 text-md-h4">
              <label class="color-azul text-strong-1">{{ model.firstName }} {{ model.lastName }}</label>
            </v-col>
            <div class="text-h6 text-sm-h5 text-md-h5 px-4 py-1 rounded-pill" style="border: 3px #9963CA solid;">
              <label class="text-strong-1 font-weight-bold text-uppercase color-azul">
                FISIO
              </label>
              <!-- model.roles[0].name -->
            </div>

            <v-col cols="12">
              <div class="color-azul-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Edad: </label> {{ model.age }}
              </div>
              <!-- <div class="color-azul-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Cursos /Certificaciones: </label> {{ model.certificates }}
              </div>
              <div class="color-azul-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Carrera / ocupacion: </label> {{ model.profession }}
              </div> -->
              <div class="color-azul-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Hobbies: </label> {{ model.hobbies }}
              </div>
              <div class="color-azul-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Musica Favorita: </label> {{ model.music }}
              </div>
              <div class="color-azul-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Cumpleaños: </label> {{ BirthDate(model.birthDate) }}
              </div>
              <div class="color-azul-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Signo: </label> {{ model.sign.name }}
              </div>
            </v-col>
          </v-row>

          <v-row v-else dense class="pl-4">
            <v-col cols="12" class="text-h6 text-sm-h5 text-md-h4">
              <label class="color-azul text-strong-1">{{ model.firstName }} {{ model.lastName }}</label>
            </v-col>
            <div class="text-h6 text-sm-h5 text-md-h5 px-4 py-1 rounded-pill" style="border: 3px #9963CA solid;">
              <label class="text-strong-1 font-weight-bold text-uppercase color-azul">
                COACH
              </label>
              <!-- model.roles[0].name -->
            </div>
            <v-col cols="12">
              <div class="color-morado-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Edad: </label> {{ model.age }}
              </div>
              <!-- <div class="color-morado-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Cursos /Certificaciones: </label> {{ model.certificates }}
              </div>
              <div class="color-morado-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Carrera / ocupacion: </label> {{ model.profession }}
              </div> -->
              <div class="color-morado-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Hobbies: </label> {{ model.hobbies }}
              </div>
              <div class="color-morado-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Musica Favorita: </label> {{ model.music }}
              </div>
              <div class="color-morado-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Cumpleaños: </label> {{ BirthDate(model.birthDate) }}
              </div>
              <div class="color-morado-medium text-strong-1 py-3">
                <label class="font-weight-bold color-azul">Signo: </label> {{ model.sign.name }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  props:['model'],
  mixins: [DatesUtils],
  data: () => ({}),
  computed: {
    ...mapGetters(['get_dialogMain'])
  },
  methods: {
    ...mapMutations(['HIDDE_DIALOG_MAIN']),
    BirthDate (date) {
      return this.BirthDateName(date)
    },
    close () {
      this.HIDDE_DIALOG_MAIN()
    }
  }
}
</script>
<style scoped>
.container-dialog-main{
  background-color: white;
}
.dialog-main {
  border-radius: 20px !important;
}
</style>
