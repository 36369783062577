<template>
  <div v-if="model.id">
    <v-list-item v-if="type === 'cycling'" two-line>
      <v-list-item-content>
        <div class="text-center">
          <v-sheet v-if="model.enabled" @click="sendAction"
            class="rounded-circle mx-auto transition-swing circle-morado-main"
            :max-width="circleView"
            :height="circleView"
            width="100%"
            elevation="0">
            <v-row dense>
              <v-col cols="12">
                <div class="text-uppercase text-md-h4 text-sm-h5 text-h6">
                  <span class="text-strong-1 font-weight-bold"> {{model.id}}</span>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet v-else @click="sendAction"
            class="rounded-circle mx-auto transition-swing circle-morado-disable-main"
            :max-width="circleView"
            :height="circleView"
            width="100%"
            elevation="0">
            <v-row dense>
              <v-col cols="12">
                <div class="text-uppercase text-md-h4 text-sm-h5 text-h6">
                  <span class="text-strong-1 font-weight-bold"> {{model.id}}</span>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
        <!-- <div id="circle" class="container-relative-center mb-2"></div> -->
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: ['model', 'type', 'onSuccess'],
  data: () => ({}),
  computed: {
    circleView () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 50
        case 'sm': return 100
        case 'md': return 120
        case 'lg': return 120
        case 'xl': return 150
      }
      return 100
    }
  },
  created () {},
  methods: {
    sendAction () {
      this.onSuccess(this.model)
    }
  }
}
</script>

<style scoped>
.circle-morado-main{
  border-color: #041A72;
  border-style: dotted;
  color: #041A72;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background-color: #9F7DBA;
}
.circle-morado-main:hover {
  background-color: #041A72;
  color: white;
}
.circle-morado-disable-main{
  border-color: #b8b8b8;
  border-style: dotted;
  color: #b8b8b8;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background-color: #9F7DBA;
}

  #circle {
    border: 1px #b8b8b8 solid;
    border-radius: 50%;
    width: 6vmax;
    height: 6vmax;
    margin: auto;
    padding: 10px;
  }
  #circle-blue {
    border: 1px #001B71 solid;
    border-style: dotted;
    border-width: 5px;
    border-radius: 50%;
    color:#001B71;
    width: 6vmax;
    height: 6vmax;
    margin: auto;
    padding: 10px;
  }
  #circle-blue:hover {
    background: #041A72;
    color:white;
  }
  .text-vertical-center {
    display: flex;
    align-items: center;
  }
  </style>
