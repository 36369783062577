import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import { HeaderGral, HeaderMultipart, HeaderConekta } from '@/store/modules/config'
import Forms from '@/store/modules/forms'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialogMain: {
      dialog: false,
      type: '',
      width: 500,
      title: '',
      params: {}
    },
    dialogAlert: {
      dialog: false,
      type: '',
      width: 500,
      title: '',
      params: {}
    },
    dialogForm: { dialog: false, type: '', width: 500, title: '', params: {} },
    dialogSession: { dialog: false, type: '', width: 500, title: '', params: {} },
    objects: [],
    object: {},
    object_post: {},
    object_put: {},
    object_delete: {},

    object_two: {},
    object_two_post: {},
    object_two_put: {},

    object_dialog: {},
    pisition_class: {},
    reload: {},
    loading: false,
    session: '',
    statusAction: false,
    conekta_token: null,
    conekta_order: null
  },
  getters: {
    get_dialogMain (state) { return state.dialogMain },
    get_dialogAlert (state) { return state.dialogAlert },
    get_dialogForm (state) { return state.dialogForm },
    get_dialogSession (state) { return state.dialogSession },

    get_objects (state) { return state.objects },
    get_object (state) { return state.object },

    get_object_post (state) { return state.object_post },
    get_object_put (state) { return state.object_put },
    get_object_delete (state) { return state.object_delete },

    get_object_two (state) { return state.object_two },
    get_object_two_post (state) { return state.object_two_post },
    get_object_two_put (state) { return state.object_two_put },

    get_object_dialog (state) { return state.object_dialog },
    get_pisition_class (state) { return state.pisition_class },
    get_reload (state) { return state.reload },
    get_loading (state) { return state.loading },
    get_session (state) { return state.session },
    get_statusAction (state) { return state.statusAction },
    get_conekta_token (state) { return state.conekta_token },
    get_conekta_order (state) { return state.conekta_order }
  },
  mutations: {
    SHOW_DIALOG_MAIN (state, data) {
      state.dialogMain.dialog = true
      state.dialogMain.type = data.type
      state.dialogMain.form = (data.form) ? data.form : 'form'
      state.dialogMain.width = (data.width) ? data.width : 500
      state.dialogMain.title = (data.title) ? data.title : ''
      state.dialogMain.params = data.params
    },
    HIDDE_DIALOG_MAIN (state) {
      state.dialogMain.dialog = false
      state.dialogMain.type = ''
    },
    SHOW_DIALOG_ALERT (state, data) {
      state.dialogAlert.dialog = true
      state.dialogAlert.width = (data.width) ? data.width : 500
      state.dialogAlert.title = (data.title) ? data.title : ''
      state.dialogAlert.params = data.params
    },
    HIDDE_DIALOG_ALERT (state) {
      state.dialogAlert.dialog = false
    },
    SHOW_DIALOG_FORM (state, data) {
      state.dialogForm.dialog = true
      state.dialogForm.type = data.type
      state.dialogForm.form = (data.form) ? data.form : 'form'
      state.dialogForm.width = (data.width) ? data.width : 500
      state.dialogForm.title = (data.title) ? data.title : ''
      state.dialogForm.params = data.params
    },
    HIDDE_DIALOG_FORM (state) {
      state.dialogForm.dialog = false
      state.dialogForm.type = ''
    },
    SHOW_DIALOG_SESSION (state, data) {
      state.dialogSession.dialog = true
      state.dialogSession.type = data.type
      state.dialogSession.form = (data.form) ? data.form : 'form'
      state.dialogSession.width = (data.width) ? data.width : 500
      state.dialogSession.title = (data.title) ? data.title : ''
      state.dialogSession.params = data.params
    },
    HIDDE_DIALOG_SESSION (state) {
      state.dialogSession.dialog = false
      state.dialogSession.type = ''
    },

    /* ========== * ========== * ========== * ========== */
    GET_LIST_DATA_SERVICES (state, data) {
      state.objects = []
      state.objects = data
    },
    GET_DATA_SERVICES (state, data) {
      state.object = {}
      state.object = data
    },
    GET_DATA_TWO_SERVICES (state, data) {
      state.object_two = {}
      state.object_two = data
    },
    GET_DATA_DIALOG_SERVER (state, data) {
      state.object_dialog = {}
      state.object_dialog = data
    },
    POST_DATA_SERVICES (state, data) {
      state.object_post = {}
      state.object_post = data
    },
    PUT_DATA_SERVICES (state, data) {
      state.object_put = {}
      state.object_put = data
    },
    PUT_DATA_TWO_SERVICES (state, data) {
      state.object_two_put = {}
      state.object_two_put = data
    },
    POST_DATA_TWO_SERVICES (state, data) {
      state.object_two_post = {}
      state.object_two_post = data
    },
    DELETE_DATA_SERVICES (state, data) {
      state.object_delete = {}
      state.object_delete = data
    },

    SET_POSITION_CLASS_CONFIRM (state, data) {
      state.pisition_class = {}
      state.pisition_class = data
    },
    SET_RELOAD_DATA (state, data) {
      state.reload = data
    },
    SET_LOADING_DATA (state, data) {
      state.loading = data
    },
    SET_DATA_SESSION (state, data) {
      state.session = data
    },
    SET_DATA_STATUS_ACTION (state, data) {
      state.statusAction = data
    },
    POST_DATA_CONEKTA_TOKEN (state, data) {
      state.conekta_token = data
    },
    POST_DATA_CONEKTA_ORDER (state, data) {
      state.conekta_order = data
    }
  },
  actions: {
    // Actions Get´s
    GETListObjectsService ({ commit }, action) {
      const header = HeaderGral()
      axios.get(`${action.url}`, header).then((response) => {
        commit('GET_LIST_DATA_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    GETObjectService ({ commit }, action) {
      const header = HeaderGral()
      axios.get(`${action.url}`, header).then((response) => {
        commit('GET_DATA_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    GETObjectTwoService ({ commit }, action) {
      const header = HeaderGral()
      axios.get(`${action.url}`, header).then((response) => {
        commit('GET_DATA_TWO_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          } else if (error.response) {
            commit('GET_DATA_TWO_SERVICES', error.response.data)
          }
        })
    },

    POSTObjectService ({ commit }, action) {
      const header = HeaderGral()
      axios.post(`${action.url}`, action.data, header).then((response) => {
        commit('POST_DATA_SERVICES', response.data)
      })
        .catch((error) => {
          console.log('error_1', error)
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          } else if (error.response) {
            commit('POST_DATA_SERVICES', error.response.data)
          }
        })
    },
    POSTObjectTwoService ({ commit }, action) {
      const header = HeaderGral()
      axios.post(`${action.url}`, action.data, header).then((response) => {
        commit('POST_DATA_TWO_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          } else if (error.response) {
            commit('POST_DATA_TWO_SERVICES', error.response.data)
          }
        })
    },

    PUTObjectService ({ commit }, action) {
      const header = HeaderGral()
      axios.put(`${action.url}`, action.data, header).then((response) => {
        commit('PUT_DATA_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    PUTObjectTwoService ({ commit }, action) {
      const header = HeaderGral()
      axios.put(`${action.url}`, action.data, header).then((response) => {
        commit('PUT_DATA_TWO_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    PATCHObjectTwoService ({ commit }, action) {
      const header = HeaderGral()
      axios.patch(`${action.url}`, action.data, header).then((response) => {
        commit('PUT_DATA_TWO_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          } else if (error.response) {
            commit('PUT_DATA_TWO_SERVICES', error.response.data)
          }
        })
    },

    DELETEObjectService ({ commit }, action) {
      const header = HeaderGral()
      axios.delete(`${action.url}`, header).then((response) => {
        commit('DELETE_DATA_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },

    POSTMultipartService ({ commit }, action) {
      const boundary = `; boundary=${action.data._boundary}`
      const header = HeaderMultipart(boundary)
      axios.post(action.url, action.data, header)
        .then(response => {
          commit('GET_DATA_DIALOG_SERVER', response.data)
        })
        .catch(error => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },

    POSTConektaToken ({ commit }, action) {
      const header = HeaderConekta('key_wZJm08ruEXJIU4d8lRhY600') // key_wZJm08ruEXJIU4d8lRhY600
      axios.post(action.url, action.data, header)
        .then(response => {
          commit('POST_DATA_CONEKTA_TOKEN', response.data)
        })
        .catch(error => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    POSTConektaOrderToken ({ commit }, action) {
      const header = HeaderConekta('key_wZJm08ruEXJIU4d8lRhY600') // key_wZJm08ruEXJIU4d8lRhY600
      axios.post(action.url, action.data, header)
        .then(response => {
          commit('POST_DATA_CONEKTA_ORDER', response.data)
        })
        .catch(error => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          } else if (error.response) {
            commit('POST_DATA_CONEKTA_ORDER', error.response.data)
          }
        })
    }
  },
  modules: {
    Forms
  }
})
