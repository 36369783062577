<template>
  <v-container>
    <v-row dense align="center" justify="center">
      <v-col cols="8">
        <v-alert border="left" colored-border color="#602F73" elevation="4">
          <p class="text-light p-1">
            Por favor, escribe tu correo de usuario.
            Recibirás un enlace para crear tu nueva contraseña
          </p>
        </v-alert>
        <v-card outlined tile>
          <v-card-title>
            <label class="text-h5 text-uppercase ma-auto py-5">
              <span class="text-strong-1">Restablecer Cuenta</span>
            </label>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="reset.email"
                  placeholder="Ingresar su correo electrónico"
                  :rules="fieldRequired"
                  required
                  solo />
              </v-col>

              <v-col cols="12" sm="6">
                <v-btn color="#CD7EE5" @click="returnProfile" large dark>
                  <span class="text-strong-1">Regresar al Login</span>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="text-right">
                <v-btn color="#602F73" class="text-right py-5" @click="ActiveAccount" :loading="loadingBtn" large dark>
                  <v-icon left>mdi-email</v-icon>
                  <span class="text-strong-1">Enviar</span>
                </v-btn>
              </v-col>

              <v-col v-if="showmessage" cols="12">
                <v-alert border="top" colored-border color="success" elevation="4">
                  <p class="text-light p-1 text-center">
                    Favor de revisar su correo electrónico, se le envió el enlace para restablecer la contraseña.
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <FooterMain class="pt-5" color="white" class-name="color-azul" color-icon="color-morado"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Validate } from '@/mixins/validateFormMixin'
import router from '@/router'
export default {
  components: {},
  mixins: [Validate],
  data: () => ({
    token: '',
    reset: {},
    showmessage: false,
    loadingBtn: false
  }),
  computed: {
    ...mapGetters(['get_object_post'])
  },
  watch: {
    get_object_post (row) {
      if (row.message) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 600, params: { message: row.message, confirm: true } })
        return
      }
      this.showmessage = true
      this.loadingBtn = false
    }
  },
  mounted () {},
  methods: {
    ...mapActions(['POSTObjectService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    ActiveAccount () {
      if (this.reset.email && this.reset.email !== '') {
        this.loadingBtn = true
        this.POSTObjectService({ url: '/Users/ResetPasswordEmail', data: this.reset })
      }
    },
    returnProfile () {
      router.push({ name: 'PerfilView' })
    }
  }
}
</script>
  <style scoped>
  </style>
