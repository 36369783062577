<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <p class="text-md-h3 text-center text-strong color-azul">
          <span class="text-strong"> Pagos </span>
        </p>
      </v-col>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0" color="#C9E7FF">
          <v-card-text>
            <div class="table-tab font-weight-bold px-5 py-2 text-center text-strong">Historial</div>
            <v-virtual-scroll :items="paids" :item-height="100" height="450" class="container-border-color-azul">
              <template v-slot:default="{ item }">
                <v-list-item class="mx-5">
                  <v-list-item-content class="mx-5">
                    <v-list-item-title class="font-weight-bold">
                      <div class="color-azul title font-weight-bold">
                        Fecha: {{formatoFecha(dateStringToDate(item.date), 'dd-mm-yyyy')}}
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <div class="font-weight-bold color-azul subtitle-1 pb-2">Consulta {{item.appointNo}}</div>
                      <div class="subtitle-2 font-weight-bold text-uppercase color-azul-medium">{{item.therapist.firstName}} {{item.therapist.lastName}}</div>
                      <!-- <div class="py-2 subtitle-2 white--text">Tarjeta: **** **** **** 970{{ item.id }}</div> -->
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="mr-5">
                    <v-row dense align="center">
                      <v-col cols="12">
                        <p class="mx-1 color-azul text-h5 font-weight-bold">$ {{item.cost}}</p>
                      </v-col>
                      <v-col cols="12" v-if="item.payed">
                        <v-chip color="#001B71" dark>
                          <span class="text-uppercase font-weight-bold">Pagado</span>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                  <v-list-item-action class="mr-5">
                    <v-row v-if="showPaid">
                      <v-col cols="12">
                        <v-menu bottom left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="#001B71" x-small fab dark>
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="AddPaidCita(item)" link>
                              <v-list-item-title>
                                <v-icon left>mdi-cash-multiple</v-icon> Realizar Pago
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
export default {
  name: 'CyclingMyAccount',
  components: {
    MainFormDialog
  },
  mixins: [DatesUtils],
  data: () => ({
    paids: [],
    form: {},
    object: {},
    url: '',
    message: '',
    showPaid: false
  }),
  computed: {
    ...mapGetters(['get_objects', 'get_dialogMain', 'get_reload',
      'get_PaidPaymentForm'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.paids = rows
      }
    },
    get_reload ({ name, model }) {
      if (name === 'updatePartialPaid') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Pago de cita se realizó correctamente' } })
        }
      }
      this.GETListObjectsService({ url: '/Appointments?&canceled=false' })
    }
  },
  mounted () {
    const rol = JSON.parse(localStorage.getItem('permisos'))
    this.validateAction(rol)
    this.user = this.$cookies.get('user_sny')
    this.GETListObjectsService({ url: '/Appointments?&canceled=false' })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT', 'SHOW_DIALOG_MAIN']),
    /* *==========*==========* */
    /* *==========*==========* */
    AddPaidCita (item) {
      if (item.payed) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'La cita esta pagada' } })
        return
      }
      // console.log(item)
      this.form = this.get_PaidPaymentForm
      this.object = { id: item.id }
      this.url = `/Appointments/${item.id}/payment`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'updatePartialPaid', title: 'Pago de Cita', width: 600, params: { returnObjet: true } })
    },
    validateAction (rol) {
      if (rol.name === 'ADMINISTRATOR') this.showPaid = true
    }
  }
}
</script>

<style scoped>
.table-calendar {
  border: 1px #602F73 solid;
}
.table-tab {
  width: 40%;
  background-color: #001B71;
  border: 1px #001B71 solid;
  border-radius: 50px 50px 0px 0px;
  color: white;
}
</style>
