<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mx-auto background-morado-light" elevation="0">
          <v-card-text>
            <v-btn @click="createCouch" color="#602F73" dark>
              <v-icon left>mdi-plus</v-icon> Nuevo
            </v-btn>
            <v-btn class="mx-2" @click="newRefresh" color="#602F73" dark>
              <v-icon left>mdi-refresh</v-icon> Refrescar
            </v-btn>
            <v-spacer />
            <v-text-field v-model="buscar" @keyup="searchRiders" label="Buscar" class="inputSearch" clearable outlined dense />
          </v-card-text>
          <div class="table-tab subtitle-1 font-weight-bold px-5 text-center">Riders</div>
          <v-progress-linear :active="loading" :indeterminate="loading" top color="#602F73"></v-progress-linear>
          <v-virtual-scroll :items="Packages" :item-height="100" height="450" class="container-border-color-morado">
            <template v-slot:default="{ item }">
              <v-divider />
              <v-list-item class="mx-5">
                <!-- <v-list-item-action>
                  <v-row>
                    <CirculePhotoField :model="item" :photo="item.photo" color="#ECDEF7"/>
                  </v-row>
                </v-list-item-action>
                 -->

                <v-list-item-content class="ml-5">
                  <v-list-item-title class="font-weight-bold">
                    <div class="color-morado text-md-h5 text-sm-h6 text-h6 font-weight-bold">
                      {{ item.firstName }} {{ item.lastName }}
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip id="circle-morado-line" class="mr-2" @click="viewPackagesUser(item)" outlined>
                      <label class="subtitle-2 font-weight-bold text-uppercase">Ver Paquete</label>
                    </v-chip>
                    <v-chip id="circle-morado-line" class="ml-2"@click="viewUserPerfil(item)" outlined>
                      <label class="subtitle-2 font-weight-bold text-uppercase">Ver Perfil</label>
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="mr-5">
                  <v-row>
                    <v-col cols="12">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" color="#A87BC9" x-small fab dark>
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <!-- <v-list-item @click="EditCuenta(item)" link>
                            <v-list-item-title>Editar Cuenta</v-list-item-title>
                          </v-list-item> -->

                          <v-list-item @click="DeleteCuenta(item)" link>
                            <v-list-item-title>Eliminar Cuenta</v-list-item-title>
                          </v-list-item>

                          <v-list-item @click="Addpaquetes(item)" link>
                            <v-list-item-title>Asignar Paquete</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="AddCita(item)" link>
                            <v-list-item-title>Agendar Cita</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" 
      :fields="form"
      :model="object"
      :urls="url"
      :message="message"
      :params="paramsMain"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
// import CirculePhotoField from '@/components/fields/circules/CirculePhotoField'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    MainFormDialog
  },
  mixins: [DatesUtils],
  data: () => ({
    user: {},
    Packages: [],
    PackagesSearch: [],
    form: {},
    object: {},
    url: '',
    message: '',
    loading: false,
    buscar: '',
    paramsMain: {}
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_objects', 'get_reload',
      'get_FilePhotoLoad', 'get_UserForm', 'get_DeleteForm', 'get_ViewPackageuserForm', 'get_PackagesForm', 'get_AgendarCitaForm'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.Packages = []
        this.PackagesSearch = []

        this.Packages.push(...rows)
        this.PackagesSearch.push(...rows)
        this.loading = false
      }
    },
    get_reload ({ name, model }) {
      if (name === 'formData') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Imagen agregado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
        }
      } else if (name === 'create') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Rider creado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
        }
      } else if (name === 'delete') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: 'Rider eliminado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
        }
      }
      this.GETListObjectsService({ url: '/Users/riders' })
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.newRefresh({})
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    showLastClases (item) {
      return `${item.remainingClasses} / ${item.totalClasses}`
    },
    loadPhoto (item) {
      this.form = this.get_FilePhotoLoad
      this.object = {}
      this.url = `/Users/${item.id}/uploadImage`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'formData', title: 'Cargar Foto', width: 500, params: { content: 'form-data', returnObjet: true } })
    },
    createCouch () {
      this.paramsMain = {}
      this.form = this.get_UserForm
      this.object = {
        firstName: '',
        lastName: '',
        birthDate: '',
        phoneNumber: '',
        email: '',
        signId: null,
        RoleIds: [4],
        password: 'Rider'
      }
      this.url = '/Users'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Nuevo Cliente', width: 700, params: { returnObjet: true } })
    },
    newRefresh (item) {
      this.loading = true
      // this.GETListObjectsService({ url: '/Users/riders' })
      this.GETListObjectsService({ url: '/Users?roleId=4&getPhoto=false' })
    },
    EditCuenta () {},
    DeleteCuenta (item) {
      this.form = this.get_DeleteForm
      this.object = {}
      this.url = `/Users/${item.id}`
      this.message = 'Registro eliminado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'delete', title: 'Eliminar', width: 500, params: { returnObjet: true } })
    },
    Addpaquetes (item) {
      this.form = this.get_PackagesForm
      this.object = { packageId: 0, userId: item.id }
      this.url = '/Packages/purchased'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Asignar Paquete', width: 600, params: { returnObjet: true } })
    },
    AddCita (item) {
      this.form = this.get_AgendarCitaForm
      this.object = { spinningClassId: 0, bike: 0, userId: item.id }
      this.url = '/Enrollments'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Agendar Cita', width: 600, params: { returnObjet: true } })
    },
    formatdate (date) {
      const fecha = this.dateStringToDate(date)
      const format = this.formatoFecha(fecha, 'dd-mm-yyyy')
      return format
    },
    searchRiders (i) {
      if (this.buscar.length >= 3) {
        this.loading = true
        const data = this.PackagesSearch.filter(item => {
          if (item.firstName && item.lastName) {
            const nombrecompleto = `${item.firstName} ${item.lastName}`
            return (nombrecompleto.toLowerCase().includes(this.buscar.toLowerCase()))
          }
        })
        this.Packages = data
      } else if (this.buscar === '') {
        this.Packages = []
        this.Packages = this.PackagesSearch
      }
      this.loading = false
    },
    viewPackagesUser (item) {
      this.form = this.get_ViewPackageuserForm
      this.object = item
      this.object.names = `${item.firstName} ${item.lastName}`
      this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Paquete Activo', width: '70%', params: { returnObjet: true, disable_button: true } })
    },
    viewUserPerfil (item) {
      const UserForm = this.get_UserForm.map(i => {
        i.readonly = true
        i.rules = false
        return i
      })
      this.form = UserForm
      const RoleIds = item.roles.map(i => { return i.id })
      item.RoleIds = RoleIds
      this.object = item
      this.url = '/Users'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'view', title: 'Ver Perfil', width: 700, params: { returnObjet: true, disable_button: true } })
    }
  }
}
</script>

<style scoped>

.table-calendar {
  border: 1px #602F73 solid;
}
.table-tab {
  width: 35%;
  background-color: #602F73;
  border: 1px #602F73 solid;
  border-radius: 25px 25px 0px 0px;
  color: white;
}
.v-tab--active{
  border: 1px #602F73 solid;
  background-color: #602F73;
  color: white;
}
</style>
