export default ({
  state: {
    FilePhotoLoad: [
      { field: 'fileInputDataServer', name: 'Cargar Foto', nameid: 'file', rules: true, accept: 'image/png, image/jpeg, image/bmp', cols: 12 }
    ],
    UpdatePasswordForm: [
      { field: 'text', type: 'password', name: 'Contraseña Anterior', nameid: 'oldPassword', rules: true, cols: 12 },
      { field: 'text', type: 'password', name: 'Nueva Contraseña', nameid: 'newPassword', rules: true, cols: 12 },
      { field: 'text', type: 'password', name: 'Confirmar Contraseña', nameid: 'confirmnPassword', rules: true, cols: 12 }
    ],
    UserForm: [
      { field: 'text', type: 'text', name: 'Nombre/s', nameid: 'firstName', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Apellido/s', nameid: 'lastName', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Celular', nameid: 'phoneNumber', rules: true, cols: 6 },
      { field: 'textDatePicker', name: 'Fecha Nacimiento', nameid: 'birthDate', default: true, rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Email', nameid: 'email', rules: true, cols: 6 },
      { field: 'selectDataServer', name: 'Signo Zodiacal', nameid: 'signId', url: '/Signs', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Hobbies', nameid: 'hobbies', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Musica favorita', nameid: 'music', rules: true, cols: 12 },
      // { field: 'text', type: 'text', name: 'Cursos / Certificados', nameid: 'certificates', rules: true, cols: 6 },
      // { field: 'text', type: 'text', name: 'Carrera / Ocupación', nameid: 'profession', rules: true, cols: 6 }
    ],
    PackagesForm: [
      { field: 'selectDataServer', name: 'Paquetes Disponibles', nameid: 'packageId', url: '/Packages', rules: true, cols: 12 }
    ],
    PackagesCreateForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'name', rules: true, cols: 12 },
      { field: 'number', type: 'number', name: 'Numero de Clases', nameid: 'classes', rules: true, cols: 12 },
      { field: 'number', type: 'number', name: 'Precio', nameid: 'price', cols: 12 },
      { field: 'number', type: 'number', name: 'Dias de Vigencia', nameid: 'daysOfValidity', rules: true, cols: 12 },
      { field: 'number', type: 'number', name: 'Numero de Compras por Usuario', nameid: 'maxPurchases', rules: true, cols: 12 }
    ],
    AgendarCitaForm: [
      { field: 'selectDataServer', name: 'Clases', nameid: 'spinningClassId', objectname: 'spinningClass', url: '/SpinningClass', value: 'id', action: 'showFieldAddItems', isHidden: ['bike'], default: { dates: true }, rules: true, cols: 12 },
      { field: 'select', name: 'Bicicleta', nameid: 'bike', items: [], hidden: true, rules: true, cols: 12 }
    ],
    SpinningClassForm: [
      { field: 'selectDataServer', name: 'Coach', nameid: 'userId', objectname: 'user', url: 'Users/couches', value: 'id', rules: true, cols: 12 },
      { field: 'textDatePicker', name: 'Fecha Clase', nameid: 'date', rules: true, cols: 6 },
      // { field: 'text', type: 'text', name: 'Hora Clase', nameid: 'time', rules: true, cols: 6 },
      { field: 'textTimePickerHours', name: 'Hora Clase', nameid: 'time', rules: true, cols: 6 },
      { field: 'select', name: 'Bicicleta', nameid: 'bike', items: [], hidden: true, rules: true, cols: 12 }
    ],
    DeleteForm: [
      { field: 'viewTextTitle', name: '¿Desea eliminar el registro?', cols: 12 }
    ],
    DeleteCitaForm: [
      { field: 'viewTextTitle', name: '¿Desea eliminar la cita registrada?', cols: 12 }
    ],
    ConfirmPaidPackageForm: [
      { field: 'viewTextTitle', name: '¿ Confirma la compra del paquete seleccionado ?', cols: 12 }
    ],
    PatientFisioForm: [
      // { field: 'selectDataServer', name: 'Paciente', nameid: 'clientId', objectname: 'client', url: 'Users?roleId=4', value: 'id', rules: true, cols: 12 },
      { field: 'textDatePicker', name: 'Fecha Cita', nameid: 'date', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Motivo de Consulta', nameid: 'appointReason', rules: true, cols: 12 }
    ],
    SchedulePatientFisioForm: [
      { field: 'textDatePicker', name: 'Fecha Cita', nameid: 'date', rules: true, cols: 12 }
    ],
    ScheduleConfirmPatientCitaForm: [
      { field: 'text', type: 'text', name: 'Motivo de Consulta', nameid: 'appointReason', rules: true, cols: 12 }
    ],

    ScheduleCitaForm: [
      { field: 'textDatePicker', name: 'Fecha Cita', nameid: 'date', rules: true, cols: 12 },
      { field: 'textTimePickerHours', name: 'Hora Cita', nameid: 'time', rules: true, cols: 12 }
    ],

    ConfirmCompleteScheduleForm: [
      { field: 'viewTextTitle', name: 'Confirma la finalizacion de la terapia !', cols: 12 }
    ],
    PaidPaymentForm: [
      { field: 'number', type: 'number', name: 'Monto', nameid: 'payment', rules: true, cols: 12 }
    ],
    PatientForm: [
      { field: 'text', type: 'text', name: 'Nombre/s', nameid: 'firstName', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Apellido/s', nameid: 'lastName', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Celular', nameid: 'phoneNumber', rules: true, cols: 6 },
      { field: 'textDatePicker', name: 'Fecha Nacimiento', nameid: 'birthDate', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Email', nameid: 'email', rules: true, cols: 6 },
      { field: 'selectDataServer', name: 'Signo Zodiacal', nameid: 'signId', url: '/Signs', rules: true, cols: 6 }
    ],
    ViewPackageuserForm: [
      { field: 'viewText', name: 'Nombre Completo :', nameid: 'names', rules: true, cols: 12 },
      { field: 'viewText', name: 'Correo: ', nameid: 'email', rules: true, cols: 12 },
      { field: 'tableDinamic', name: 'Listado de Clases', nameid: 'id', url: '/Packages', cols: 12 }
      // { field: 'selectDataServer', name: 'Signo Zodiacal', nameid: 'signId', url: '/Signs', rules: true, cols: 6 }
    ]
  },
  getters: {
    get_FilePhotoLoad (store) { return store.FilePhotoLoad },
    get_UpdatePasswordForm (store) { return store.UpdatePasswordForm },
    get_UserForm (store) { return store.UserForm },
    get_PatientForm (store) { return store.PatientForm },
    get_PackagesForm (store) { return store.PackagesForm },
    get_PackagesCreateForm (store) { return store.PackagesCreateForm },

    get_AgendarCitaForm (store) { return store.AgendarCitaForm },
    get_SpinningClassForm (store) { return store.SpinningClassForm },
    get_DeleteForm (store) { return store.DeleteForm },
    get_ConfirmPaidPackageForm (store) { return store.ConfirmPaidPackageForm },

    get_PatientFisioForm (store) { return store.PatientFisioForm },
    get_SchedulePatientFisioForm (store) { return store.SchedulePatientFisioForm },
    get_ScheduleCitaForm (store) { return store.ScheduleCitaForm },
    get_ScheduleConfirmPatientCitaForm (store) { return store.ScheduleConfirmPatientCitaForm },

    get_DeleteCitaForm (store) { return store.DeleteCitaForm },
    get_PaidPaymentForm (store) { return store.PaidPaymentForm },
    get_ConfirmCompleteScheduleForm (store) { return store.ConfirmCompleteScheduleForm },

    get_ViewPackageuserForm (store) { return store.ViewPackageuserForm }

  },
  mutations: {},
  actions: {}
})
