<template>
  <v-card elevation="0" tile :color="color">
    <v-list-item three-line>
      <v-list-item-content>
        <v-row dense>
          <v-col cols="12">
            <div v-if="model.photo">
              <div id="circle-comp">
                <v-img :src="model.photo" class="circle-base-main"/>
              </div>
            </div>
            <div v-else>
              <div :id="`${classname}`" class="text-vertical-center"></div>
            </div>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn :color="(fisio)? '#001B71': '#A87BC9'" @click="loadPhoto" text >
              <v-icon left>mdi-plus</v-icon>Subir Foto
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <!--
    <div id="circle-enabled" class="container-relative-center" @click="sendAction">
      <div class="text-container-absolute title white--text"></div>
    </div>
    -->
  </v-card>
</template>

<script>
export default {
  props: ['model', 'OnSuccess', 'color', 'classname', 'fisio'],
  data: () => ({}),
  created () {},
  methods: {
    loadPhoto () {
      this.OnSuccess()
    }
  }
}
</script>

<style scoped>
#circle-comp {
  border-radius: 50%;
  width: 8vmax;
  height: 8vmax;
  margin: auto;
}

.text-vertical-center {
  display: flex;
  align-items: center;
}

</style>
