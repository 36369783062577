import { render, staticRenderFns } from "./SquarePackageField.vue?vue&type=template&id=65577c22&scoped=true&"
import script from "./SquarePackageField.vue?vue&type=script&lang=js&"
export * from "./SquarePackageField.vue?vue&type=script&lang=js&"
import style0 from "./SquarePackageField.vue?vue&type=style&index=0&id=65577c22&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65577c22",
  null
  
)

export default component.exports