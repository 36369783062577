<template>
  <v-app id="inspire">
    <template v-if="$route.meta.account">
      <HeaderMain :show="showAction" />
      <v-main>
        <v-container fluid class="ma-0 pa-0" fill-height>
          <v-layout justify-center align-center>
            <v-flex md11>
              <v-row v-if="$route.meta.fisio" dense no-gutters>
                <v-col cols="12" sm="3" md="3" class="background-azul border-der">
                  <NavigationFisioMain />
                </v-col>
                <v-col cols="12" sm="9" md="9" class="background-azul-light border-izq">
                  <router-view />
                </v-col>
              </v-row>
              <v-row v-else dense no-gutters>
                <v-col cols="12" sm="3" md="3" class="background-morado border-der">
                  <NavigationMain />
                </v-col>
                <v-col cols="12" sm="9" md="9" class="background-morado-light border-izq">
                  <router-view />
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </template>
    <template v-else>
      <HeaderMain :show="showAction" />
      <v-main>
        <router-view />
      </v-main>
    </template>
    <DialogMain v-if="get_dialogAlert.dialog" />
    <DialogSessionMain v-if="get_dialogSession.dialog" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { IsUserSession } from '@/store/modules/config'
export default {
  name: 'App',
  data: () => ({}),
  computed: {
    ...mapGetters(['get_dialogAlert', 'get_dialogSession']),
    showAction () {
      return IsUserSession()
    }
  }
}
</script>
<style>
@import url(./fonts/Fonts.css);
@import url(./assets/css/colors.css);
@import url(./assets/css/main.css);

</style>
