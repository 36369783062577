<template>
  <v-row dense>
    <v-col cols="12">
      <v-row
        v-if="item"
        dense
      >
        <v-col
          cols="3"
          class="text-center"
        >
          <div><label class="font-weight-bold black--text">Nombre Paquete</label></div>
          {{ (item.packageName) ? item.packageName : 'Sin Paquete' }}
        </v-col>

        <v-col
          cols="3"
          class="text-center"
        >
          <div><label class="font-weight-bold black--text">Total de Clases</label></div>
          {{ (item.totalClasses) ? item.totalClasses : 0 }}
        </v-col>

        <v-col
          cols="3"
          class="text-center"
        >
          <div><label class="font-weight-bold black--text">Clases Restantes</label></div>
          {{ (item.remainingClasses) ? item.remainingClasses : 0 }}
        </v-col>

        <v-col
          cols="3"
          class="text-center"
        >
          <div><label class="font-weight-bold black--text">Fecha de vencimiento</label></div>
          {{ formatoFechaAbreviacion (item.lastValidDate) }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3" class="text-center">
          <div><label class="font-weight-bold black--text">Total de clases tomadas</label></div>
          {{ this.clases }}
        </v-col>
      </v-row>
    <!-- <DataTableMain :datatable="table" :on-success='successTableAction' :itemsAction='successTableAction' /> -->
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { HeaderGral } from '@/store/modules/config'
//  import DataTableMain from '@/components/tables/DataTableMain'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  components: {
    // DataTableMain
  },
  mixins: [DatesUtils],
  props: ['itemAction', 'setterModel'],
  data: () => ({
    item: {},
    clases: 0,
    items: [],
    table: {
      color: '#FFFFFF',
      className: '',
      header: {
        options: [],
        titles: [
          { text: 'Paquete', value: 'name', class: 'black--text' },
          { text: 'Clases', value: 'classes', class: 'black--text' },
          { text: 'Precio', value: 'price', class: 'black--text' },
          { text: 'Dias de Vigencia', value: 'daysOfValidity', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    }
  }),
  watch: {
    setterModel (val) {
      if (val) {
        this.getDinamicDataClases()
        this.getDinamicDataActiva()
      }
    }
  },
  created () {
    // this.getDinamicDataActiva()
  },
  methods: {
    itemText (item) {
      if (item && (this.itemAction.nameid === 'empresa_id')) {
        return `${item.nombre}`
      } else if (item && (this.itemAction.nameid === 'packageId')) {
        return `${item.name} - ${item.daysOfValidity} días - $ ${item.price}`
      } else if (item && (this.itemAction.nameid === 'spinningClassId')) {
        const fecha = this.showFormatDateAndHour(item)
        return `${item.user.firstName} ${item.user.lastName} - ${item.trainingRoom.name} ( ${fecha} )`
      } else if (item && this.itemAction.nameid === 'userId') {
        return `${item.fullName}`
      } else if (item && this.itemAction.nameid === 'clientId') {
        return `${item.firstName} ${item.lastName}`
      }
      return `${item.name}`
    },
    getDinamicData (url) {
      const header = HeaderGral()
      if (this.setterModel) url += `?userId=${this.setterModel}`
      axios.get(url, header).then((response) => {
        this.table.body.data = []
        this.table.body.data.push(...response.data)
        this.getDinamicDataActiva()
      }).catch((error) => {
        if (error == null) {
          console.log('Error')
        }
      })
    },
    getDinamicDataActiva () {
      const header = HeaderGral()
      axios.get(`Enrollments/?status=1&clientId=${this.setterModel}`, header).then((response) => {
        const data = response.data
        this.clases = data.length
      }).catch((error) => {
        if (error == null) {
          console.log('Error')
        }
      })
    },
    getDinamicDataClases () {
      const header = HeaderGral()
      axios.get(`Packages/activePackage?userId=${this.setterModel}`, header).then((response) => {
        this.item = response.data
      }).catch((error) => {
        if (error == null) {
          console.log('Error')
        }
      })
    },

    setModel () {
      if (this.setterModel) {
        this.item = this.setterModel
      }
    },
    setDefault (items) {
      if (this.itemAction.default) {
        if (this.itemAction.nameid === 'ejercicio_id') {
          // const year = this.getYear()
          const year = new Date().toISOString().substr(0, 4)
          items.map((periodo) => {
            if (periodo.anio >= year) {
              this.item = periodo.id
            }
          })
        }
      }
    },
    showFormatDateAndHour (item) {
      const date = this.dateStringToDate(item.date)
      const dateformat = this.formatoFecha(date, 'yyyy-mm-dd')
      const hour = this.timeStringToDate(item.date, true)
      return `${dateformat} ${hour}`
    },
    successTableAction (i) {
      this[i.action](i)
    }
  }
}
</script>

  <style>
  </style>
