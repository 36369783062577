<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="itemAction.name"
        prepend-inner-icon="mdi-clock-time-four-outline"
        clearable
        v-bind="attrs"
        readonly
        dense
        outlined
        @click:clear="clearField"
        v-on="on"
      />
    </template>
    <v-time-picker
      v-if="menu2"
      v-model="time"
      full-width
      format="24hr"
      @change="save"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'TimePicker',
  props: ['itemAction', 'onSuccess', 'setterModel'],
  data: () => ({
    time: null,
    menu2: false
  }),
  watch: {
    setterModel (val) {
      this.seterModel()
    }
  },
  created () {
  },
  methods: {
    allowedHours (v) {
      return (v > 6 && v < 18)
    },
    clearField (obj) {
      this.onSuccess({ id: this.itemAction.nameid, data: null })
    },
    save (time) {
      this.$refs.menu.save(time)
      if (time != null) {
        this.onSuccess({ id: this.itemAction.nameid, data: time })
      }
    },
    seterModel () {
      try {
        console.log(this.setterModel)
        if (this.setterModel) {
          this.time = this.setterModel
        }
      } catch (error) {
        this.time = ''
      }
    }
  }
}
</script>

<style>

</style>
