<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-layout justify-center align-center>
          <v-flex md11>
            <v-row dense class="align-center">
              <v-divider class="border-divider" color="#041a72" dark />
              <label class="container-border-color-two color-azul text-uppercase font-weight-bold text-md-h4 text-sm-h5 text-xl-h6">
                <span class="text-strong-1">Nuestras Fisios</span>
              </label>
              <v-divider class="border-divider" color="#041a72" dark />
            </v-row>
          </v-flex>
        </v-layout>
      </v-col>

      <v-col cols="12" align="center" v-if="loadingData">
        <v-progress-circular
          :size="70"
          :width="10"
          color="#041a72"
          indeterminate
        />
      </v-col>

      <v-col cols="12" v-if="!loadingData">
        <v-layout justify-center align-center class="py-3">
          <v-flex md11 xs12>
            <v-row dense class="align-center">
              <v-col cols="3" v-for=" (item, index) in fisios" :key="index">
                <AvatarMainField :model="item" type="fisio" :on-success="InfoPArsonView" />
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-col>

    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <FooterMain color="grey lighten-5" class-name="color-azul" color-icon="color-morado"/>
      </v-col>
    </v-row>
    <!-- <ShowFormDialog v-if="get_dialogForm.dialog" :model="object" :tipo="tipoForm" :on-success="successTableAction"/> -->
    <InfoPersonDialog v-if="get_dialogMain.dialog" :model="object"/>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import AvatarMainField from '@/components/fields/AvatarMainField'
import InfoPersonDialog from '@/components/dialogs/InfoPersonDialog.vue'
// import ShowFormDialog from '@/components/dialogs/ShowFormDialog'
export default {
  components: {
    AvatarMainField,
    InfoPersonDialog
  },
  data: () => ({
    info: {},
    fisios: [],
    object: {},
    tipoForm: '',
    loadingData: true
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_objects'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.fisios = rows
        this.loadingData = false
      }
    }
  },
  mounted () {
    this.GETListObjectsService({ url: '/Users?roleId=3&getPhoto=true' })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_FORM', 'SHOW_DIALOG_MAIN']),
    successTableAction (item) {
      this[item.action](item)
    },
    InfoPArsonView (data) {
      this.object = data
      // this.tipoForm = 'InfoPersonDetailForm'
      this.SHOW_DIALOG_MAIN({ type: 'formView', title: 'Informacion Fisio', width: 800, params: { fisio: true } })
    }
  }
}
</script>

<style scoped>
.title-physio{
  border: 1px red solid;
  margin: 10px;
}
</style>
