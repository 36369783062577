<template>
  <v-select
    v-model="item"
    :items="items"
    :label="itemAction.name"
    :item-text="itemText"
    item-value="id"
    :disabled="itemAction.disabled"
    :readonly="itemAction.readonly"
    clearable
    outlined
    dense>
    <template #selection="{ item }">
      <v-list-item-action-text dense>
        <span class="font-weight-bold subtitle-2">{{ itemText(item) }}</span>
      </v-list-item-action-text>
    </template>

    <template #item="{ item }">
      <v-list-item-action-text dense>
        <span class="font-weight-bold font-weight-black">{{ itemText(item) }}</span>
      </v-list-item-action-text>
    </template>
  </v-select>
</template>

<script>
import axios from 'axios'
import { HeaderGral } from '@/store/modules/config'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  props: ['itemAction', 'onSuccess', 'setterModel'],
  mixins: [DatesUtils],
  data: () => ({
    item: null,
    items: [],
    table: {}
  }),
  watch: {
    item (val) {
      let model = null
      this.items.map((item) => {
        if (item[this.itemAction.value] === val) {
          model = item
        }
      })
      this.onSuccess({ id: this.itemAction.nameid, data: val, nameObject: this.itemAction.objectname, objectMain: model, fieldMain: this.itemAction })
    },
    items (vals) {
      if (this.setterModel) {
        this.item = this.setterModel
      }
    },
    setterModel (data) {
      // console.log('setterModel', data)
    }
  },
  created () {
    if (this.itemAction.url) {
      this.getDinamicData(this.itemAction.url)
    }
  },
  methods: {
    itemText (item) {
      if (item && (this.itemAction.nameid === 'empresa_id')) {
        return `${item.nombre}`
      } else if (item && (this.itemAction.nameid === 'packageId')) {
        return `${item.name} - ${item.daysOfValidity} días - $ ${item.price}`
      } else if (item && (this.itemAction.nameid === 'spinningClassId')) {
        const fecha = this.showFormatDateAndHour(item)
        return `${item.user.firstName} ${item.user.lastName} - ${item.trainingRoom.name} ( ${fecha} )`
      } else if (item && this.itemAction.nameid === 'userId') {
        return `${item.fullName}`
      } else if (item && this.itemAction.nameid === 'clientId') {
        return `${item.firstName} ${item.lastName}`
      }
      return `${item.name}`
    },
    getDinamicData (url) {
      const header = HeaderGral()
      axios.get(url, header).then((response) => {
        this.items = []
        this.items.push(...response.data)
        // console.log('response.data', response.data)
      }).catch((error) => {
        if (error == null) {
          console.log('Error')
        }
      })
    },
    setModel () {
      if (this.setterModel) {
        this.item = this.setterModel
      }
    },
    setDefault (items) {
      if (this.itemAction.default) {
        if (this.itemAction.nameid === 'ejercicio_id') {
          // const year = this.getYear()
          const year = new Date().toISOString().substr(0, 4)
          items.map((periodo) => {
            if (periodo.anio >= year) {
              this.item = periodo.id
            }
          })
        }
      }
    },
    showFormatDateAndHour (item) {
      const date = this.dateStringToDate(item.date)
      const dateformat = this.formatoFecha(date, 'yyyy-mm-dd')
      const hour = this.timeStringToDate(item.date, true)
      return `${dateformat} ${hour}`
    }
  }
}
</script>

  <style>
  </style>
