<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <v-row dense>
      <v-col cols="12">
        <div class="container-image">
          <v-container fill-height>
            <v-layout
              justify-center
              align-center
            >
              <v-flex md6>
                <div class="text-h4 text-sm-h3 text-md-h1 text-uppercase white--text text-center">
                  <span class="text-strong-1">Nosotras</span>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </v-col>
    </v-row>

    <v-row class="background-nosotras">
      <v-col cols="12">
        <div class="container-height-200">
          <div class="text-md-h2 text-sm-h3 text-h5 font-weight-bold color-azul ma-auto">
            Vero, Rebeca & Gaby.
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-container fill-height>
          <v-layout
            justify-center
            align-center
          >
            <v-flex md12>
              <p class="text-light text-center py-5">
                Synergy nació de la idea de crear un espacio enfocado a la promoción de la salud integral a
                través de clases de indoor cycling impartidas por expertas en movimiento, capacitadas para
                brindar el mejor servicio y llevarte al máximo de tus capacidades, además de un
                acompañamiento fisioterapéutico enfocado al estado de salud individual de cada usuario.
              </p>
              <p class="text-light text-center pt-5">
                Buscando siempre la calidad del movimiento y del ejercicio aplicado tanto al componente
                físico como mental, fomentamos el autoconocimiento y el amor propio en todo momento,
                siendo estos  parte fundamental de nuestra filosofía como empresa.
              </p>
              <div class="text-md-h3 container-height-200">
                <label
                  id="add-tilde"
                  class="text-strong color-morado-dark ma-auto"
                >A state of health</label>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="text-center"
          >
            <label class="text-md-h4 text-sm-h4 text-h5 color-azul-light-3"><span class="text-strong">Calidad</span> </label>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="text-center"
          >
            <label class="text-md-h4 text-sm-h4 text-h5 color-azul-light-3"><span class="text-strong">Humanidad</span> </label>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="text-center"
          >
            <label class="text-md-h4 text-sm-h4 text-h5 color-azul-light-3"><span class="text-strong">Bienestar</span> </label>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="text-center"
          >
            <label class="text-md-h4 text-sm-h4 text-h5 color-azul-light-3"><span class="text-strong">Formación Integral</span> </label>
          </v-col>
          <div class="container-height-100 text-md-h2 text-sm-h3 text-h3" />
        </v-row>
      </v-col>
    </v-row>
    <FooterMain
      color="grey lighten-5"
      class-name="color-azul"
      color-icon="color-morado"
    />
    <v-row dense>
      <v-col
        cols="12"
        sm="4"
        md="3"
      >
        <v-card
          color="#001B71"
          tile
          dark
          height="100%"
        >
          <v-card-title class="text-center">
            <h2 class="text-strong text-uppercase ma-auto color-morado mt-2">
              Contacto
            </h2>
          </v-card-title>
          <v-card-text>
            <v-container fill-height>
              <v-row
                align="center"
                justify="center"
                dense
              >
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <p class="p-1 white--text font-weight-bold py-5">
                    <v-icon color="#a782b9">
                      mdi-phone
                    </v-icon>
                    <span class="text-light">(999) 908 3852</span>
                  </p>
                  <p class="p-1 white--text font-weight-bold py-5">
                    <v-icon color="#a782b9">
                      mdi-web
                    </v-icon>
                    <span class="text-light color-morado"> www.synergyinmovement.com</span>
                  </p>
                  <p class="p-1 white--text font-weight-bold py-5">
                    <v-icon color="#a782b9">
                      mdi-map-marker
                    </v-icon>
                    <span class="text-light"> Plaza Alpha, Temozón. Mérida, Yucatán.</span>
                  </p>
                  <p class="p-1 white--text font-weight-bold py-5">
                    <v-icon color="#a782b9">
                      mdi-view-dashboard
                    </v-icon>
                    <span class="text-light color-morado"> @synergyinmovement</span>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="9"
      >
        <v-card
          color="#A782B9"
          tile
          dark
          min-height="550"
          elevation="0"
        >
          <v-card-title class="text-center">
            <h2 class="text-strong text-uppercase ma-auto color-azul mt-2">
              faq`s
            </h2>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                class="text-center"
              >
                <div class="white--text py-5">
                  <p class="text-strong">
                    ¿ Qué áreas puedo trabajar en fisioterapia ?
                  </p>
                  <label class="text-light p-x4 color-azul font-text-1 px-5 font-weight-bold">
                    Tratamiento pre y post operatorio, ortopedia, neurlogía, acondicionamiento
                    cardiológico y pulmunar, recuperación de lesiones, trastornos circulatorios, entre otros.
                  </label>
                </div>

                <div class="white--text py-5">
                  <p class="text-strong">
                    ¿ Cómo debo ir vestido a mi consulta de fisioterapia ?
                  </p>
                  <label class="text-light p-x4 color-azul font-text-1 px-5 font-weight-bold">
                    Acude con ropa cómoda y flexible, shorts deportivos o camisas sin mangas dependiendo de la zona de lesión/patología.
                  </label>
                </div>

                <div class="white--text py-5">
                  <p class="text-strong">
                    ¿ Cuánto tiempo antes debo llegar a mi ride ?
                  </p>
                  <label class="text-light p-x4 color-azul font-text-1 px-5 font-weight-bold">
                    Debes llegar con 10 minutos de anticipación para acomodarte y estar lista/listo, la clase comienza puntual.
                  </label>
                </div>
                <div class="white--text py-5">
                  <p class="text-strong">
                    ¿ Qué tan difícil está la clase si es mi primera vez haciendo indoor ?
                  </p>
                  <label class="text-light p-x4 color-azul font-text-1 px-5 font-weight-bold">
                    Nuestras coaches están completamente capacitadas para irte guiando durante la clase
                    cuando estas comenzando a entrenar en indoor, de igual forma te darán claves en
                    todo momento para que logres adaptar el nivel de la clase a tus capacidades.
                  </label>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: {},
  data: () => ({
    info: {}
  }),
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapMutations([])
  }
}
</script>

<style scoped>
.container-image {
  background-image: url(../assets/web/synergy_nosotras_2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 45% 12%;
  max-width: 100%;
  height: 750px !important;
  /* height: 750px; *?
}
.background-text {
  background-image: url(../assets/fondo_cycling.jpeg);
  /* min-height: 50vmax; */
}
.background-nosotras {
  background: url("../assets/fondo_cycling.jpeg");
  background-size: 100%;
  background-position: 100% 25%;
  background-repeat: no-repeat;
}
.title-physio{
  border: 1px red solid;
  margin: 10px;
}
</style>
