<template>
  <v-card tile width="auto">
    <v-card-text class="py-3">
      <v-form ref="form">
        <v-row dense>
          <v-col
            v-for="(field, i) in form"
            :key="i"
            :cols="field.cols"
            :offset="field.offset"
            :hidden="field.hidden"
          >
            <p v-if="field.field == 'viewText' && !field.hidden">
              <label class="font-weight-bold">{{ field.name }} </label> {{ model[field.nameid] }}
            </p>
            <p
              v-if="field.field == 'viewTextTitle' && !field.hidden"
              class="text-center"
            >
              <span class="text-center ma-auto title">
                {{ field.name }}
              </span>
            </p>
            <p v-if="field.field == 'viewTextList' && !field.hidden">
              <label class="font-weight-bold">{{ field.name }} </label>
              <v-chip
                v-for="(item, index) in model[field.nameid]"
                :key="index"
                :color="field.color"
                class="ma-1"
                small
                label
                outlined
              >
                <span class="font-weight-bold text-uppercase">{{ item[field.itemnameid] }}</span>
              </v-chip>
            </p>

            <v-text-field
              v-if="field.field == 'text' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :type="field.type"
              :rules="field.rules ? fieldRequired : []"
              :readonly="field.readonly"
              outlined
              dense
            />

            <v-text-field
              v-if="field.field == 'number' && !field.hidden"
              v-model.number="model[field.nameid]"
              :label="field.name"
              :type="field.type"
              min="0"
              :rules="field.rules ? fieldRequired : []"
              :readonly="field.readonly"
              outlined
              dense
            />

            <v-textarea
              v-else-if="field.field == 'textArea' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :name="field.dimens"
              :rules="field.rules ? fieldRequired : []"
              :readonly="field.readonly"
              outlined
              dense
            />

            <v-select
              v-else-if="field.field == 'select' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :items="field.items"
              :rules="field.rules ? fieldRequired : []"
              :readonly="field.readonly"
              outlined
              dense
            />

            <div v-else-if="field.field == 'radioButton' && !field.hidden">
              <label class="black--text font-weight-bold font-weight-black subtitle-2">{{ field.name }}</label>
              <v-radio-group
                v-model="model[field.nameid]"
                :row="field.row"
                :rules="field.rules ? fieldRequired : []"
                class="pa-0 ma-0"
                @change="RadioButtonEvent(field)"
              >
                <v-radio
                  v-for="value in field.items"
                  :key="value"
                  :label="value"
                  :value="value"
                />
              </v-radio-group>
            </div>

            <div v-else-if="field.field == 'checkbox'">
              <p class="ma-0 pa-0 text--black font-weight-bold font-weight-black subtitle-2">
                {{ field.name }}
              </p>
              <v-checkbox
                v-for="value in field.items"
                :key="value"
                v-model="model[field.nameid]"
                :class="field.row ? 'mr-3 pa-0 checkbook-horizontal' : 'ma-0 pa-0'"
                :label="value"
                :value="value"
                :rules="field.rules ? fieldRequired : []"
              />
            </div>

            <TableDinamicField
              v-else-if="field.field == 'tableDinamic' && !field.hidden"
              :setter-model="model[field.nameid]"
              :item-action="field"
            />

            <FileInputDinamicField
              v-else-if="field.field == 'fileInputDataServer' && !field.hidden"
              :item-action="field"
              :on-success="actionFieldData"
            />
            <SelectedDinamicField
              v-else-if="field.field == 'selectDataServer' && !field.hidden"
              :item-action="field"
              :setter-model="model[field.nameid]"
              :on-success="actionFieldData"
            />
            <DatePicker
              v-else-if="field.field == 'textDatePicker' && !field.hidden"
              :setter-model="model[field.nameid]"
              :item-action="field"
              :on-success="actionFieldData"
            />

            <TimePickerMain
              v-else-if="field.field == 'textTimePickerHours' && !field.hidden"
              :setter-model="model[field.nameid]"
              :item-action="field"
              :on-success="actionFieldData"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-layout justify-end>
        <v-btn
          class="mr-3"
          color="#602F73"
          depressed
          outlined
          @click="onCancel"
        >
          Cerrar
        </v-btn>
        <v-btn
          v-if="!params.disable_button"
          class="mr-3"
          color="#602F73"
          depressed
          outlined
          @click="sendForm"
        >
          {{ showtitle() }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Validate } from '@/mixins/validateFormMixin'
import FileInputDinamicField from '@/components/fields/account/FileInputDinamicField'
import SelectedDinamicField from '@/components/fields/account/SelectedDinamicField'
import DatePicker from '@/components/fields/DatePicker'
import TimePickerMain from '@/components/fields/TimePicker'
import TableDinamicField from '@/components/fields/account/TableDinamicField'
export default {
  name: 'MainProcessForm',
  components: {
    FileInputDinamicField,
    SelectedDinamicField,
    DatePicker,
    TimePickerMain,
    TableDinamicField
  },
  mixins: [Validate],
  props: ['form', 'model', 'onSuccess', 'onCancel', 'buttonTitle', 'error', 'params'],
  data: () => ({
    selected: [],
    indice: '',
    BtnLoading: false
  }),
  methods: {
    ...mapActions(['postServiceActionRemoveRol']),
    ...mapMutations(['SET_DATA_STATUS_ACTION']),

    showtitle () {
      if (this.buttonTitle === 'delete') return 'Eliminar'
      if (this.buttonTitle === 'salePackage') return 'Confirmar'
      if (this.buttonTitle === 'updatePartial') return 'Confirmar'
      return 'Guardar'
    },
    RadioButtonEvent (item) {
      if (item.action) {
        this[item.action](item)
      }
    },
    checkboxEvent (item) {},
    actionFieldData (obj) {
      // eslint-disable-next-line vue/no-mutating-props
      this.model[obj.id] = (obj.data) ? obj.data : null
      if (obj.nameObject) {
        // eslint-disable-next-line vue/no-mutating-props
        this.model[obj.nameObject] = obj.objectMain
      }
      this.actionDinamicData(obj)
    },
    actionDinamicData (obj) {
      if (obj.fieldMain) {
        if (obj.fieldMain.action) {
          this[obj.fieldMain.action](obj)
        }
      }
    },
    showFieldAddItems ({ objectMain, fieldMain }) {
      if (fieldMain.isHidden) {
        fieldMain.isHidden.map((item) => {
          this.form.map((f) => {
            if (item === f.nameid) {
              if (f.nameid === 'bike') {
                f.items = []
                const availableBikes = objectMain.availableBikes
                f.items.push(...availableBikes)
                f.hidden = false
                return
              }
              f.hidden = true
              // eslint-disable-next-line vue/no-mutating-props
              this.model[f.nameid] = null
            }
          })
        })
      }
    },
    sendForm () {
      this.SET_DATA_STATUS_ACTION(true)
      if (this.$refs.form.validate()) {
        const item = this.validateComponents()
        if (item !== null) {
          this.$swal({
            type: 'error',
            icon: 'error',
            title: 'Advertencia!',
            text: ' Campo ' + item.name + ' no debe ser vacio!!'
          })
          this.SET_DATA_STATUS_ACTION(false)
          return
        }
        this.onSuccess(this.model)
      }
    },
    validateComponents () {
      let itemSelected = null
      this.form.map(item => {
        if (item.rules) {
          if (!item.hidden) {
            if (!this.model[item.nameid]) {
              itemSelected = item
            } else if (this.model[item.nameid] === null) {
              itemSelected = item
            }
          }
        }
      })
      return itemSelected
    }
  },
  computed: {
    ...mapGetters([])
  },
  watch: {}
}
</script>

  <style>
  .box-color-red {
    border: 1px red solid;
  }
  div .checkbook-horizontal {
    display: inline-block;
  }
  </style>
