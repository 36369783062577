<template>
  <v-app-bar height="110" color="grey lighten-5" app elevation="0">
    <a @click="send('home')">
      <v-img class="img-link" src="@/assets/logo_synergy.png" :max-width="titleSynergy" />
    </a>

    <v-spacer />
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn to="/Cycling" text plain class="mx-2 text-h6">
        <label class="text-SackersGothicStd-Light">CYCLING</label>
      </v-btn>
      <v-btn to="/Physio" text plain class="mx-2 text-h6">
        <label class="text-SackersGothicStd-Light">PHYSIO</label>
      </v-btn>
      <v-btn to="/Nosotras" text plain class="mx-2 text-h6">
        <label class="text-SackersGothicStd-Light">NOSOTRAS</label>
      </v-btn>
      <v-btn v-if="!show" to="/Perfil" text plain class="mx-2 text-h6">
        <label class="text-SackersGothicStd-Light">PERFIL</label>
      </v-btn>
      <v-btn v-if="show" to="/MiCuenta/Calendario" text plain class="mx-2 text-h6">
        <label class="text-SackersGothicStd-Light">MI CUENTA</label>
      </v-btn>
      <v-btn class="mx-1" icon href="https://www.facebook.com/synergyinmovement" target="_blank">
        <v-icon large>mdi-facebook</v-icon>
      </v-btn>
      <v-btn class="mx-1" icon href="https://www.instagram.com/synergyinmovement/?hl=es" target="_blank">
        <v-icon large>mdi-instagram</v-icon>
      </v-btn>
    </v-toolbar-items>

    <span class="hidden-md-and-up">
      <v-menu>
        <template v-slot:activator="{ attrs, on }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on" small></v-app-bar-nav-icon>
        </template>
        <v-list dense>
          <v-list-item to="/Cycling" link dense>
            <v-list-item-subtitle>CYCLING</v-list-item-subtitle>
          </v-list-item>

          <v-list-item to="/Physio" link dense>
            <v-list-item-subtitle>PHYSIO</v-list-item-subtitle>
          </v-list-item>

          <v-list-item to="/Nosotras" link dense>
            <v-list-item-subtitle>NOSOTROS</v-list-item-subtitle>
          </v-list-item>

          <v-list-item to="/Perfil" link dense>
            <v-list-item-subtitle>PERFIL</v-list-item-subtitle>
          </v-list-item>

          <v-list-item to="/MiCuenta/Calendario" link dense>
            <v-list-item-subtitle>MI CUENTA</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
    <!--
    <v-btn icon><v-icon>mdi-facebook</v-icon></v-btn>
    <v-btn icon><v-icon>mdi-instagram</v-icon></v-btn>
    -->
  </v-app-bar>
</template>

<script>
import router from '@/router'
export default {
  name: 'HelloWorld',
  props:['show'],
  data: () => ({
    menuItems: [
      { title: 'CYCLING', path: '/Cycling', icon: 'mdi-home' },
      { title: 'PHYSIO', path: '/Physio', icon: 'mdi-home' },
      { title: 'NOSOTROS', path: '/Nosotras', icon: 'mdi-home' },
      { title: 'PERFIL', path: '/Perfil', icon: 'mdi-home' },
      { title: 'MI CUENTA', path: '/MiCuenta/Calendario', icon: 'mdi-home' }
    ]
  }),
  computed: {
    titleSynergy () {
      if (this.$vuetify.breakpoint.xl) return 500
      if (this.$vuetify.breakpoint.lg) return 400
      if (this.$vuetify.breakpoint.md) return 300
      if (this.$vuetify.breakpoint.sm) return 200
      return 150
    }
  },
  methods: {
    send (name) {
      router.push({ name: name })
    }
  }
}
</script>
<style scoped>

</style>
