<template>
  <div>
    <component
      :is="type"
      :model="object"
      :on-success="actions"
    />
  </div>
</template>

<script>
import SpinningDetail from '@/components/details/SpinningDetail.view.vue'
export default {
  components: {
    SpinningDetail
  },
  props: ['object', 'type', 'itemsAction'],
  data: () => ({}),
  methods: {
    actions (actons) {
      this.itemsAction(actons)
    }
  }
}
</script>

  <style>
  </style>
