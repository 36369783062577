export const NumberUtils = {
  data () {
    return {}
  },
  methods: {
    round (num, decimales = 2, divider = false) {
      const signo = (num >= 0 ? 1 : -1)
      num = num * signo
      if (decimales === 0) { // con 0 decimales
        return signo * Math.round(num)
      }
      // round(x * 10 ^ decimales)
      num = num.toString().split('e')
      num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)))
      // x * 10 ^ (-decimales)
      num = num.toString().split('e')
      const number = signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales))
      if (divider) {
        return this.DividerFormat(number)
      }
      return number
    },
    intlRound (numero) {
      const opciones = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }
      return Number(numero).toLocaleString('en', opciones)
    },
    DividerFormat (numb) {
      const str = numb.toString().split('.')
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return str.join('.')
    }
  }
}
