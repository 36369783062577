<template>
  <v-container fluid class="pa-0 ma-0">
    <v-toolbar color="#A87BC9" elevation="0" dark>
      <v-toolbar-title class="ma-auto">
        <label class="text-md-h4 text-sm-h5 text-xs-h6 text-uppercase">
          <span class="text-strong-1 white--text">agenda semanal</span>
        </label>
      </v-toolbar-title>
    </v-toolbar>
    <v-row dense>
      <v-col cols="12">
        <ul class="nav">
          <li class="text-md-h5 text-sm-h5 text-xs-h6">
            <v-chip outlined class="ma-2" id="circle-morado"></v-chip>
            <label label class="text-uppercase text-Gothambook font-weight-bold">Disponibles</label>
          </li>
          <li class="text-md-h5 text-sm-h5 text-xs-h6">
            <v-chip outlined class="ma-2" id="circle"></v-chip>
            <label class="text-uppercase text-Gothambook font-weight-bold">No Disponibles</label>
          </li>
          <li class="text-md-h5 text-sm-h5 text-xs-h6">
            <v-chip outlined class="ma-2" id="circle-azul-dark"></v-chip>
            <label class="text-uppercase text-Gothambook font-weight-bold">Selección</label>
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-tabs v-if="validarDispositivo()" v-model="tabMain" color="#A87BC9" background-color="#FFFFFF" show-arrows grow>
      <v-tab v-for="(w, i) in week.days" :key="i" @click="selectedDayCalendar(w)">
        <p class="color-azul text-md-h4 text-sm-h5 text-sm-h6">
          <span class="text-strong font-weight-bold text-uppercase">{{ w.name }}</span>
          <span class="text-light text-uppercase"> {{ w.day }} </span>
        </p>
      </v-tab>
    </v-tabs>

    <v-row v-if="validarDispositivo()" class="align-center my-2">
      <v-col cols="12">
        <div class="text-md-h5 text-sm-h6 mx-2">
          <span class="color-gris text-light text-uppercase">AM</span>
        </div>
      </v-col>
      <v-divider class="mx-5" color="#B2B2B2" dark />
    </v-row>
    <v-tabs-items v-if="validarDispositivo() && showCalendar" v-model="tabMain" >
      <v-tab-item v-for="(w, i) in week.days" :key="i">
        <v-card flat>
          <v-card-text>
            <div v-for="(hour,j) in selectedAM" :key="j">
              <CirculeField :model="hour" type="cycling" :on-success="actionItem" />
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-row v-if="validarDispositivo()" class="align-center my-2">
      <v-col cols="12">
        <div class="text-md-h5 text-sm-h6 mx-2">
          <span class="color-gris text-light text-uppercase">PM</span>
        </div>
      </v-col>
      <v-divider class="mx-5" color="#B2B2B2" dark />
    </v-row>
    <v-tabs-items v-if="validarDispositivo() && showCalendar" v-model="tabMain">
      <v-tab-item v-for="(w, i) in week.days" :key="i">
        <v-card flat>
          <v-card-text>
            <div v-for="(hour,j) in selectedPM" :key="j">
              <CirculeField :model="hour" type="cycling" :on-success="actionItem" />
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- ++++++++++++ escritorio ++++++++++++ -->
    <v-row v-if="!validarDispositivo()" dense >
      <!-- <v-col cols="12">
        <ul class="nav">
          <li class="text-md-h5 text-sm-h5 text-xs-h6">
            <v-chip outlined class="ma-2" id="circle-morado"></v-chip>
            <label label class="text-uppercase text-Gothambook font-weight-bold">Disponibles</label>
          </li>
          <li class="text-md-h5 text-sm-h5 text-xs-h6">
            <v-chip outlined class="ma-2" id="circle"></v-chip>
            <label class="text-uppercase text-Gothambook font-weight-bold">No Disponibles</label>
          </li>
          <li class="text-md-h5 text-sm-h5 text-xs-h6">
            <v-chip outlined class="ma-2" id="circle-azul-dark"></v-chip>
            <label class="text-uppercase text-Gothambook font-weight-bold">Selección</label>
          </li>
        </ul>
      </v-col> -->

      <v-row class="align-center my-2">
        <v-col cols="12">
          <div class="text-md-h5 text-sm-h6 mx-2">
            <span class="color-gris text-light text-uppercase">AM</span>
          </div>
        </v-col>
        <v-divider class="mx-5" color="#B2B2B2" dark />
      </v-row>

      <v-col cols="12" v-if="showCalendar">
        <v-layout justify-center align-center>
          <v-flex class="text-center" md2 v-for="(w, i) in week.days" :key="i">
            <p class="color-azul text-md-h4 text-sm-h5 text-sm-h6">
              <span class="text-strong font-weight-bold text-uppercase">{{ w.name }} </span>
              <span class="text-light text-uppercase"> {{ w.day }} </span>
            </p>
          </v-flex>
        </v-layout>

        <v-layout justify-center align-center>
          <v-flex v-for="(d, i) in week.days" :key="i" md2 class="text-center">
            <div v-for="(hour,j) in d.hours_am" :key="j">
              <CirculeField :model="hour" type="cycling" :on-success="actionItem" />
            </div>
          </v-flex>
        </v-layout>

        <v-row class="align-center my-2">
          <v-col cols="12">
            <div class="text-md-h5 text-sm-h6 mx-2">
              <span class="color-gris text-light text-uppercase">PM</span>
            </div>
          </v-col>
          <v-divider class="mx-5" color="#B2B2B2" dark />
        </v-row>

        <v-layout justify-center align-center>
          <v-flex v-for="(d, i) in week.days" :key="i" md2 class="text-center">
            <div v-for="(hour,j) in d.hours_pm" :key="j">
              <CirculeField :model="hour" type="cycling" :on-success="actionItem" />
            </div>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>

    <FooterMain color="grey lighten-5" className="color-azul" colorIcon="color-morado" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CirculeField from '@/components/fields/CirculeField.vue'
// import CirculeFieldSafari from '@/components/fields/CirculeFieldSafari.vue'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import router from '@/router'

export default {
  name: 'PhysioCalendar',
  components: {
    CirculeField
  },
  mixins: [DatesUtils],
  data: () => ({
    tabMain: 0,
    selectedAM: [],
    selectedPM: [],
    showCalendar: false,
    week: [],
    server: [],
    navegador: ''
  }),
  computed: {
    ...mapGetters(['get_objects'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.server = rows
        this.selectionDay(rows)
        if (this.validarDispositivo()) {
          this.selectedDayCalendar(this.week.days[0])
        }
        this.showCalendar = true
      }
    }
  },
  mounted () {
    // console.log('navegador', this.SelectNavegador())
    // this.navegador = this.SelectNavegador()
    this.week = this.rangeDatetoWeek()
    // this.GETListObjectsService(this.week)
    this.GETListObjectsService({ url: `/SpinningClass?start=${this.week.start}&final=${this.week.final}` })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    SelectNavegador () {
      const agente = window.navigator.userAgent
      const navegadores = ['Chrome', 'Firefox', 'Safari', 'Opera', 'Trident', 'MSIE', 'Edge'];
      for (var i in navegadores) {
        if (agente.indexOf(navegadores[i]) !== -1) return navegadores[i]
      }
      return ''
    },
    // *==========*==========*==========*==========* \\
    selectionDay (rows) {
      for (let index = 0; index < this.week.days.length; index++) {
        let clase = {}
        clase = this.week.days[index]
        clase.hours_am = []
        clase.hours_pm = []
        const i = this.dateStringToDate(`${clase.date} 00:00:00`)
        const f = this.dateStringToDate(`${clase.date} 11:59:59`)

        const ii = this.dateStringToDate(`${clase.date} 12:00:00`)
        const ff = this.dateStringToDate(`${clase.date} 23:59:59`)

        for (let index = 0; index < rows.length; index++) {
          const element = rows[index]
          const compare = this.dateStringToDate(element.date)
          const hour = this.timeStringToDate(element.date)

          const morning = this.compareBetweenDate(i, compare, f)
          const afternoon = this.compareBetweenDate(ii, compare, ff)

          const today = new Date()
          const fechaEvent = this.dateStringToDate(element.date)
          const result = this.compareDate(fechaEvent, today)
          element.show = true
          if (element.availableBikes.length <= 0) element.show = false
          element.disableclass = false
          if (result) element.disableclass = true

          if (morning) {
            element.hour = hour
            clase.hours_am.push(element)
          } else if (afternoon) {
            element.hour = hour
            clase.hours_pm.push(element)
          }
        }
      }
    },
    actionItem (item) {
      const today = new Date()
      const fechaEvent = this.dateStringToDate(item.date)
      const result = this.compareDate(fechaEvent, today)
      if (result) {
        router.push({ name: 'CyclingMapsClassView', params: { id: item.id } })
      } else {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'La clase ha expirado', confirm: true } })
        // setTimeout(() => { this.HIDDE_DIALOG_ALERT() }, 1500)
      }
      // console.log(result)
    },
    selectedDayCalendar (day) {
      console.log(day)
      this.selectedAM = []
      this.selectedAM.push(...day.hours_am)
      this.selectedPM = []
      this.selectedPM.push(...day.hours_pm)
    },
    validarDispositivo () {
      const userAgent = window.navigator.userAgent
      const mobilekeywords = ['Mobi', 'Android', 'iPhone', 'Windows Phone', 'Blackberry', 'iPod'] // 'iPad',
      return mobilekeywords.some(heyword => userAgent.includes(heyword))
    }
  }
}
</script>

<style scoped>
.nav {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}
.nav li {
  display: inline-block;
  /* font-size: x-large; */
  padding: 20px;
}

</style>
