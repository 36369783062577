<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0" color="#ECDEF7">
          <v-card-text>
            <div class="table-tab font-weight-bold px-5 py-2 text-center text-strong">Paquete Vigente</div>
            <v-virtual-scroll :items="Packages" :item-height="50" height="450" class="container-border-color-morado">
              <template v-slot:default="{ item }">
                <v-divider />
                <v-list-item class="mx-5">
                  <v-list-item-content class="mx-5">
                    <v-list-item-title class="font-weight-bold">
                      <div class="color-morado text-md-h4 text-sm-h5 font-weight-bold">
                        {{ item.totalClasses }} Rides
                      </div>
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      <v-chip outlined id="circle">
                        <label v-if="item.remainingClasses <= 1" class="subtitle-2 font-weight-bold text-uppercase">
                          Queda {{ item.remainingClasses }} Clase
                        </label>
                        <label v-else class="subtitle-2 font-weight-bold text-uppercase">
                          Quedan {{ item.remainingClasses }} Clases
                        </label>
                      </v-chip>
                      <div class="py-2 subtitle-2">
                        <v-icon x-small color="#A87BC9">mdi-checkbox-blank-circle</v-icon>
                        Paquete vence el {{ formatdate(item.lastValidDate) }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="mr-5">
                    <v-row align="center">
                      <p class="mx-1 color-morado text-md-h4 text-sm-h5 font-weight-bold">
                        {{ showLastClases(item) }}
                      </p>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccount',
  components: {},
  mixins: [DatesUtils],
  data: () => ({
    user: {},
    Packages: []
  }),
  computed: {
    ...mapGetters(['get_object'])
  },
  watch: {
    get_object (row) {
      if (row.success) {
        console.log('row', row)
        return
      }
      this.Packages.push(row)
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.GETObjectService({ url: `/Packages/activePackage?userId=${this.user.id}` })
  },
  methods: {
    ...mapActions(['GETObjectService']),
    ...mapMutations([]),
    // *==========*==========*==========*==========* \\
    showLastClases (item) {
      return `${(item.totalClasses - item.remainingClasses)} / ${item.totalClasses}`
    },
    formatdate (date) {
      const fecha = this.dateStringToDate(date)
      const format = this.formatoFecha(fecha, 'dd-mm-yyyy')
      return format
    }
  }
}
</script>

<style scoped>
.table-calendar {
  border: 1px #602F73 solid;
}
.table-tab {
  width: 40%;
  background-color: #602F73;
  border: 1px #602F73 solid;
  border-radius: 50px 50px 0px 0px;
  color: white;
}
.v-tab--active{
  border: 1px #602F73 solid;
  background-color: #602F73;
  color: white;
}
#circle {
  border: 1px #A87BC9 solid;
  color: #001B71;
  border-style: dotted;
  border-width: 3px;
}
</style>
