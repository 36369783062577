<template>
  <v-card width="auto" elevation="0">
    <v-card-text class="px-5">
      <v-row dense>
        <v-col cols="4">
          <v-img v-if="model.photo" class="img-link" :src="model.photo"></v-img>
        </v-col>
        <v-col cols="8">
          <v-row dense>
            <v-col cols="12">
              <p class="color-azul  text-strong-1">{{ model.firstName }} {{ model.lastName }}</p>
              <v-chip color="#a782b9" outlined dense>{{model.roles[0].name}}</v-chip>
            </v-col>
            <v-col cols="12">
              <div class="text-strong-1 subtitle-1 my-4">
                <label class="font-weight-bold color-azul">Edad: </label> 25 años
              </div>
              <div class="text-strong-1 subtitle-1 my-4">
                <label class="font-weight-bold color-azul">Carrera / ocupacion: </label> {{ model.profession }}
              </div>
              <div class="text-strong-1 subtitle-1 my-4">
                <label class="font-weight-bold color-azul">Cursos /Certificaciones: </label> {{ model.certificates }}
              </div>
              <div class="text-strong-1 subtitle-1 my-4">
                <label class="font-weight-bold color-azul">Hobbies: </label> {{ model.hobbies }}
              </div>
              <div class="text-strong-1 subtitle-1 my-4">
                <label class="font-weight-bold color-azul">Musica Favorita: </label> {{ model.music }}
              </div>
              <div class="text-strong-1 subtitle-1 my-4">
                <label class="font-weight-bold color-azul">Cumpleaños: </label> {{ model.birthDate }}
              </div>
              <div class="text-strong-1 subtitle-1 my-4">
                <label class="font-weight-bold color-azul">Signo: </label> {{ model.sign.name }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'InfoPersonDetailForm',
  props: ['model', 'onSuccess'],
  data: () => ({
    message: '',
    show: false,
    selectActividadField: {
      name: 'Empresa',
      nameid: 'empresa_id',
      url: '/empresas',
      value: 'id'
    }
  }),
  computed: {
    ...mapGetters([])
  },
  methods: {
    onCancel () {
      this.onSuccess({ action: 'CancelarCita', item: this.model })
    }
  }
}
</script>

<style>
</style>
