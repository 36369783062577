<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-tabs color="#001B71" background-color="#C9E7FF" grow>
          <v-tab class="table-tab mr-5" @click="proximasClases">Proximas Citas</v-tab>
          <v-tab class="table-tab ml-5" @click="pasadasClases">Citas Pasadas</v-tab>
        </v-tabs>
        <div v-if="showListCircule">
          <v-layout justify-center class="background-line-azul">
            <v-flex md2>
              <h2 class="text-center color-azul"> Lun </h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Mar </h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Mie </h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Jue </h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Vie</h2>
              <v-divider/>
            </v-flex>
            <v-flex md2>
              <h2 class="text-center color-azul"> Sab </h2>
              <v-divider/>
            </v-flex>
          </v-layout>
          <v-layout justify-center align-center class="background-line-azul container-height-350">
            <v-flex md2>
              <span v-for="(item, index) in L" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#C9E7FF" tipo="fisio" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in Mar" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#C9E7FF" tipo="fisio" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in Mie" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#C9E7FF" tipo="fisio" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in J" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#C9E7FF" tipo="fisio" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in V" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#C9E7FF" tipo="fisio" />
              </span>
            </v-flex>
            <v-flex md2>
              <span v-for="(item, index) in S" :key="index">
                <CirculeViewField :model="item" :on-success="showDialog" color="#C9E7FF" tipo="fisio" />
              </span>
            </v-flex>
          </v-layout>
        </div>
        <DataTableMain v-if="showList" :datatable="table" :on-success='successTableAction' :itemsAction='successTableAction' />
      </v-col>
    </v-row>
    <ShowFormDialog v-if="get_dialogForm.dialog" :model="object" :tipo="tipoForm" :on-success="successTableAction"/>

    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CirculeViewField from '@/components/fields/account/CirculeViewField'
import ShowFormDialog from '@/components/dialogs/ShowFormDialog'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'

import DataTableMain from '@/components/tables/DataTableMain'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    CirculeViewField,
    DataTableMain,
    ShowFormDialog,
    MainFormDialog
  },
  mixins: [DatesUtils],
  data: () => ({
    table: {
      color: '#C9E7FF',
      className: 'background-azul-light',
      header: {
        options: [],
        titles: [
          { text: 'Nombres', value: 'client.firstName', class: 'black--text' },
          { text: 'Apellidos', value: 'client.lastName', class: 'black--text' },
          { text: 'Fecha Clase', value: 'DateDinamic', class: 'black--text', showItem: 'date' },
          { text: 'Motivo Consulta', value: 'appointReason', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    },
    fieldDateInit: { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'start', cols: 3 },
    fieldDateFinal: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'final', cols: 3 },
    params: {
      start: '',
      final: ''
    },
    user: {},
    week: {},
    D: [],
    L: [],
    Mar: [],
    Mie: [],
    J: [],
    V: [],
    S: [],
    showListCircule: true,
    showList: false,
    object: {},
    tipoForm: '',
    form: [],
    url: '',
    message: ''
  }),
  computed: {
    ...mapGetters(['get_dialogForm', 'get_dialogMain', 'get_objects', 'get_reload',
      'get_DeleteCitaForm'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        if (this.showListCircule) {
          this.selectionDay(rows)
        } else {
          this.table.body.data = rows
        }
      }
    },
    get_reload ({ name, model }) {
      if (name === 'updatePartial') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Cita cancelada correctamente' } })
          if (this.showListCircule) this.GETListObjectsService({ url: `/Appointments?initDate=${this.week.start}&endDate=${this.week.final}` })
          else this.GETListObjectsService({ url: `/Appointments?endDate=${this.week.final}&canceled=false` })
        } else {
          this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Cita no cancelada. La cita debe ser cancelada por lo menos 48 horas antes de la cita' } })
        }
      }
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.week = this.rangeDatetoWeek()
    this.params.start = this.week.start
    this.params.final = this.week.final
    this.GETListObjectsService({ url: `/Appointments?initDate=${this.week.start}&endDate=${this.week.final}&canceled=false` })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_FORM', 'SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    /* *==========*==========*==========*==========*==========* */
    successTableAction (item) {
      this[item.action](item)
    },
    actionFieldData (obj) {
      this.params[obj.id] = obj.data === undefined ? null : obj.data
    },
    searchDates () {
    },
    selectionDay (rows) {
      this.D = []
      this.L = []
      this.Mar = []
      this.Mie = []
      this.J = []
      this.V = []
      this.S = []
      for (let index = 0; index < rows.length; index++) {
        const clase = rows[index]
        // console.log('clase', clase)
        const nameDay = this.nameDayweek(clase.date)
        const hour = this.timeStringToDate(clase.date)
        const fecha = this.dateStringToDate(clase.date)
        const dateformat = this.formatoFecha(fecha, 'dd-mm-yyyy')
        clase.hour = hour
        clase.date = dateformat
        switch (nameDay) {
          case 'domingo':
            this.D.push(clase)
            break
          case 'lunes':
            this.L.push(clase)
            break
          case 'martes':
            this.Mar.push(clase)
            break
          case 'miércoles':
            this.Mie.push(clase)
            break
          case 'jueves':
            this.J.push(clase)
            break
          case 'viernes':
            this.V.push(clase)
            break
          case 'sábado':
            this.S.push(clase)
            break
        }
      }
    },
    proximasClases () {
      this.week = this.rangeDatetoWeek()
      this.params.start = this.week.start
      this.params.final = this.week.final
      this.GETListObjectsService({ url: `/Appointments?initDate=${this.week.start}&endDate=${this.week.final}&canceled=false` })
      this.showList = false
      this.showListCircule = true
    },
    pasadasClases () {
      this.week = this.rangeDatetoWeek()
      this.params.start = this.week.start
      this.params.final = this.week.final
      this.GETListObjectsService({ url: `/Appointments?initDate=${this.week.start}&canceled=false` })
      this.showListCircule = false
      this.showList = true
    },
    showDialog (model) {
      // console.log('model', model)
      this.object = model
      this.tipoForm = 'ConsultaCitaDetailForm'
      this.SHOW_DIALOG_FORM({ type: 'formView', title: '', width: 500, params: { color: '#001B71' } })
    },
    CancelarCita ({ item }) {
      this.form = this.get_DeleteCitaForm
      this.object = {}
      this.url = `/Appointments/${item.id}/cancel`
      this.message = 'Cita eliminada correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'updatePartial', title: 'Eliminar', width: 500, params: { returnObjet: true } })
      // console.log('model', model)
    }
  }
}
</script>

<style scoped>
.table-tab {
  border: 1px #355DC8 solid;
  border-radius: 25px 25px 0px 0px;
  color: #001B71 !important;
  font-weight: bold;
}
.v-tab--active{
  border: 1px #355DC8 solid;
  background-color: #355DC8;
  color: white !important;
}
</style>
