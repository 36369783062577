<template>
  <v-card v-if="model.id" elevation="0" tile :color="color">
    <v-list-item three-line link @click="sendEvent">
      <v-list-item-content>
        <div v-if="tipo === 'fisio'">
          <div class="circle-base-main background-line-azul text-vertical-center">
            <div class="color-azul ma-auto text-center">
              <div class="subtitle-2 pa-1">{{model.therapist.firstName}}</div>
              <div class="subtitle-1 pa-1">{{model.hour}}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div id="circle-blue" class="text-vertical-center">
            <div class="ma-auto text-center">
              <div class="subtitle-2 white--text">{{model.hour}}</div>
              <div class="subtitle-2 white--text pa-1">{{model.date}}</div>
            </div>
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
    <!--
    <div id="circle-enabled" class="container-relative-center" @click="sendAction">
      <div class="text-container-absolute title white--text"></div>
    </div>
    -->
  </v-card>
</template>

<script>
export default {
  props: ['model', 'onSuccess', 'color', 'tipo'],
  data: () => ({}),
  created () {},
  methods: {
    sendEvent () {
      this.onSuccess(this.model)
    }
  }
}
</script>

<style scoped>
#circle {
  border: 1px #b8b8b8 solid;
  border-style: dotted;
  border-width: 5px;
  border-radius: 50%;
  width: 6vmax;
  height: 6vmax;
  margin: auto;
  padding: 10px;
}
#circle-blue {
  background: #A87BC9;
  border-width: 5px;
  border-radius: 50%;
  width: 7vmax;
  height: 7vmax;
  margin: auto;
  padding: 10px;
}
#circle-blue:hover {
  background: #041A72;
}
.text-vertical-center {
  display: flex;
  align-items: center;
}

</style>
