<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row dense no-gutters>
      <v-col cols="12" sm="6" md="6">
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover}" @click="sendActionMenu({ session: 'MyAccountUserView', defecto: 'CyclingView' })" tile>
            <img class="img-link" height="225px" src="@/assets/web/FOTO_CYCLING.png">
            </img>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="sendActionMenu({ session: 'PhysioExpedienteView', defecto: 'PhysioView' })" tile>
            <img class="img-link" height="225px" src="@/assets/web/FOTO_PHYSIO.png">
            </img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <v-row dense no-gutters class="backgroud-image-s-morado">
      <v-col cols="12">
        <v-layout justify-center>
          <v-flex xs12 sm8>
            <v-img src="@/assets/synergy_name.png" class="ma-auto" max-width="85%" :height="titleSynergy"></v-img>
            <p class="text-center">
              <span class="text-strong">"Sinergía", que significa "trabajando en conjunto"</span>
              <span class="text-light"> - es el momento en el que la suma de las partes crea un mejor rendimiento a cuando se actúa por separado.</span>
              <br/>
              <span class="text-strong">Es el momento en el que el todo suma.</span>
            </p>
            <div class="text-md-h3 container-height-200">
              <label class="text-strong color-morado-dark ma-auto" id="add-tilde">A state of health</label>
            </div>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <img class="img-link" src="@/assets/web/synergy_cycling_l.jpg">
      </v-col>
      <v-col cols="6">
        <img class="img-link" src="@/assets/web/synergy_cycling_r.jpg">
      </v-col>
    </v-row>
    <FooterMain color="grey lighten-5" className="color-azul" colorIcon="color-morado" />
  </v-container>
</template>

<script>
import router from '@/router'
export default {
  components: {},
  data: () => ({
    model: 0
  }),
  computed: {
    titleSynergy () {
      if (this.$vuetify.breakpoint.xl) return 500
      if (this.$vuetify.breakpoint.lg) return 400
      if (this.$vuetify.breakpoint.md) return 300
      return 200
    },
    columns () {
      if (this.$vuetify.breakpoint.xl) return 4
      if (this.$vuetify.breakpoint.lg) return 3
      if (this.$vuetify.breakpoint.md) return 2
      return 1
    },
    showImageHeight () {
      if (this.$vuetify.breakpoint.xl) return 900
      if (this.$vuetify.breakpoint.lg) return 700
      if (this.$vuetify.breakpoint.md) return 500
      return 250
    }
  },
  methods: {
    sendActionMenu ({ session, defecto }) {
      router.push({ name: defecto })
    },
    loadImage (image) {
      return require(`../assets/cycling/slider/${image}`)
    }
  }
}
</script>
<style scoped>
.backgroud-image-s-morado {
  /* border: 1px red solid; */
  background-image: url('@/assets/fondo_cycling.jpeg');
  background-size: 100%;
  background-position: 100% 35%;
}
.v-card { transition: opacity .4s ease-in-out; }
.v-card:not(.on-hover) { opacity: 0.6;}
.show-btns { color: rgba(90, 90, 90, 1) !important;}
</style>
