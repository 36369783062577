<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row dense>
      <v-col cols="12">
        <v-img class="img-link" src="@/assets/cycling/cycling_main.png" max-height="750">
          <div class="container-height-200">
            <p class="white--text text-h3 text-sm-h5 text-md-h2 text-center font-weight-bold ma-auto">
              <span class="text-strong-1">Cycling</span>
            </p>
          </div>
          <v-row no-gutters dense class="container-height-450">
            <v-col cols="12" sm="4" md="4" v-for="(item, index) in Items" :key="index" class="text-center" align-self="center">
              <v-btn :to="item.path" :color="item.color" x-large dark>
                <span class="text-h5 text-sm-h4 text-md-h4 font-weight-bold">
                  <span class="text-strong-1">{{ item.title }}</span>
                </span>
              </v-btn>

            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>

    <v-row dense class="background-clase">
      <v-col cols="12">
        <v-layout justify-center>
          <v-flex xs12 sm8 md8 >
            <div class="container-height-150">
              <p class="color-morado text-h2 ma-auto">
                <span class="text-strong-1">Tu clase</span>
              </p>
            </div>
            <p class="text-light text-center">
              Un espacio de 45 minutos para desafiarte y encontrar la sinergia entre tu cuerpo
              y mente para alcanzar tu mejor versión.
            </p>
            <p class="text-light text-center">
              En Synergy estamos listas para recibirte para que solo te preocupes por pasarla
              bien y disfrutar. Aqui te brindamos los zapatos necesarios para la clase y tenemos
              a tu disposición agua y toallas.
            </p>
            <p class="text-center text-h5 text-sm-h5 text-md-h4 pt-3">
              <span class="text-strong">Recomendaciones :</span>
            </p>
            <p class="text-light text-center">
              Intenta llegar 10 minutos antes de la hora de tu ride para ponerte tus tenis y que te podamos ayudar a ajustar tu bici.
              Recuerda que a la clase comienza puntual, el salón se cierra y solo tienes la primera canción para unirte en caso de llegar después.
            </p>

            <div class="text-center pb-10">
              <v-btn class="pa-6 ma-5" color="#A87BC9" to="/Cycling/Reservar" x-large dark> <!-- to="/Cycling/Horarios" -->
                <span class="text-h4 font-weight-bold">Horarios</span>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
    <v-row dense v-if="false">
      <v-col cols="12" sm="6" md="6">
        <img class="img-link" src="@/assets/cycling/slider/carrucel_1.png">
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <img class="img-link" src="@/assets/cycling/slider/carrucel_2.png">
      </v-col>
      <!--
      <v-col cols="12">
        <v-carousel v-model="model" cycle hide-delimiters show-arrows-on-hover dark>
          <template v-for="(item, index) in slider">
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index"
              reverse-transition="fade-transition" transition="fade-transition">
              <v-row class="flex-nowrap" style="height:100%">
                <template v-for="(n,i) in columns">
                  <template v-if="(+index + i) < slider.length">
                    <v-col :cols="(12 / columns)" :key="i">
                      <v-sheet v-if="(+index + i) < slider.length" class="pa-1">
                        <v-row align="center" justify="center">
                          <v-img class="img-link" :src="slider[(+index + i)].image" width="100%" :height="showImageHeight" />
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-col>
      -->
    </v-row>
    <FooterMain color="grey lighten-5" className="color-azul" colorIcon="color-morado" />
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',
  data: () => ({
    Items: [
      { title: 'Reservar', path: '/Cycling/Reservar', icon: 'mdi-home', color: '#ad87c7' },
      { title: 'Comprar', path: '/Cycling/Comprar', icon: 'mdi-home', color: '#ad87c7' },
      { title: 'Coaches', path: '/Cycling/Coaches', icon: 'mdi-home', color: '#ad87c7' }
    ],
    slider: [
      { image: require('@/assets/cycling/slider/carrucel_1.png'), name: 'carrucel_1' },
      { image: require('@/assets/cycling/slider/carrucel_2.png'), name: 'carrucel_2' },
      { image: require('@/assets/cycling/slider/carrucel_3.png'), name: 'carrucel_3' },
      { image: require('@/assets/cycling/slider/carrucel_4.png'), name: 'carrucel_4' },
      { image: require('@/assets/cycling/slider/carrucel_5.png'), name: 'carrucel_5' },
      { image: require('@/assets/cycling/slider/carrucel_6.png'), name: 'carrucel_6' }
    ]
  }),
  computed: {
    columns () {
      if (this.$vuetify.breakpoint.xl) return 4
      if (this.$vuetify.breakpoint.lg) return 3
      if (this.$vuetify.breakpoint.md) return 2
      return 1
    },
    showImageHeight () {
      if (this.$vuetify.breakpoint.xl) return 900
      if (this.$vuetify.breakpoint.lg) return 700
      if (this.$vuetify.breakpoint.md) return 500
      return 450
    }
  }
}
</script>

<style scoped>
.background-clase {
  background: url("../assets/fondo_cycling.jpeg");
  background-size: 100%;
  background-position: 100% 50%;
}
</style>
