<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row dense no-gutters class="background-morado-1">
      <v-col cols="12" md="3">
        <v-img v-if="photo" class="img-link" :src="photo" />
        <v-img v-else class="img-link" src="@/assets/synergy_logo.png" />
      </v-col>
      <v-col cols="12" md="9">
        <p class="text-md-h4 text-sm-h5 text-xs-h6 text-uppercase text-center white--text pt-5">
          <span class="text-strong-1 font-weight-bold">
            {{ dayName }} {{ hour }}
          </span>
        </p>
        <p class="text-md-h5 text-sm-h5 text-xs-h6 title text-uppercase text-center white--text">
          <span class="text-strong-1">{{ couch }}</span>
        </p>
        <v-img class="ma-auto" src="@/assets/position_coauch.png" max-width="300" />

        <v-row dense no-gutters justify="center" align="center" class="my-5">
          <v-col cols="12" align="center" v-if="loadingData">
            <v-progress-circular
              :size="70"
              :width="10"
              color="#602F73"
              indeterminate
            />
          </v-col>
          <v-col v-if="!loadingData" v-for="(bike, i) in bikes" :key="i" :cols="(bike.col) ? bike.col : columnaShow(i)" class="text-center ma-auto">
            <v-divider v-if="(bike.col)" class="py-2" />
            <CirculePositionField v-else type="cycling" :model="bike" :on-success="actionItem" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <ul class="nav">
              <li class="text-md-h5 text-sm-h5 text-xs-h6">
                <v-chip outlined class="ma-2" id="circle-azul"></v-chip>
                <label label class="text-uppercase text-Gothambook font-weight-bold">Disponibles</label>
              </li>
              <li class="text-md-h5 text-sm-h5 text-xs-h6">
                <v-chip outlined class="ma-2" id="circle-gris"></v-chip>
                <label class="text-uppercase text-Gothambook font-weight-bold">No Disponibles</label>
              </li>
              <li class="text-md-h5 text-sm-h5 text-xs-h6">
                <v-chip outlined class="ma-2" id="circle-azul-dark"></v-chip>
                <label class="text-uppercase text-Gothambook font-weight-bold">Selección</label>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <FooterMain color="grey lighten-5" className="color-azul" colorIcon="color-morado" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import CirculePositionField from '@/components/fields/CirculePositionField.vue'
import router from '@/router'
export default {
  name: 'PhysioCalendar',
  components: {
    CirculePositionField
  },
  mixins: [DatesUtils],
  data: () => ({
    id_spinning: 0,
    bikes: null,
    classMain: null,
    hour: '',
    dayName: '',
    couch: '',
    lugares: 0,
    photo: null,
    loadingData: true
  }),
  computed: {
    ...mapGetters(['get_object', 'get_pisition_class'])
  },
  watch: {
    get_object (row) {
      if (row) {
        this.photo = row.user.photo
        this.hour = this.timeStringToDate(row.date, true)
        this.dayName = this.nameDayweek(row.date.substr(0, 10))
        this.couch = `${row.user.firstName} ${row.user.lastName}`
        this.bikes = this.selectionBikes(row)
        this.lugares = row.availableBikes.length
        this.loadingData = false
      }
    }
  },
  mounted () {
    this.id_spinning = this.$route.params.id
    this.GETObjectService({ url: `/SpinningClass/${this.id_spinning}` })
    this.classMain = this.get_pisition_class
  },
  methods: {
    ...mapActions(['GETObjectService']),
    ...mapMutations(['SET_POSITION_CLASS_CONFIRM']),
    // *==========*==========*==========*==========* \\
    selectionBikes (row) {
      const bikes = []
      for (let index = 1; index <= row.trainingRoom.bikes; index++) {
        let objeto = {}
        for (let j = 0; j < row.availableBikes.length; j++) {
          const element = row.availableBikes[j]
          if (index === element) {
            objeto = { id: index, spinning_id: this.id_spinning, enabled: true }
            break
          }
        }
        if (!objeto.id) {
          objeto = { id: index, spinning_id: this.id_spinning, enabled: false }
        }
        bikes.push(objeto)
        if (index === 6 || index === 11) {
          bikes.push({ id: (index * -1), col: 12 })
        }
      }
      // console.log(bikes)
      return bikes
    },
    columnaShow (position) {
      if (position < 5) {
        if (this.$vuetify.breakpoint.sm) return 2
        else if (this.$vuetify.breakpoint.xs) return 3
        else return 2
      }
      if (position >= 5 && position <= 11) {
        if (this.$vuetify.breakpoint.sm) return 2
        else if (this.$vuetify.breakpoint.xs) return 3
        else return 2
      }
      if (position > 10) {
        if (this.$vuetify.breakpoint.sm) return 2
        else if (this.$vuetify.breakpoint.xs) return 3
        else return 3
      }
      return 3
    },
    actionItem (item) {
      if (this.lugares === 0) {
        router.push({ name: 'CyclingCitaConfirmClassView', params: { id: this.id_spinning, position: item.id } })
      } else if (item.enabled) {
        router.push({ name: 'CyclingCitaConfirmClassView', params: { id: this.id_spinning, position: item.id } })
      }
    }
  }
}
</script>

<style scoped>
.nav {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}
.nav li {
  display: inline-block;
  /* font-size: x-large; */
  padding: 20px;
}
</style>
