<template>
  <v-dialog persistent v-model="get_dialogMain.dialog" :width="get_dialogMain.width" justify-center>
    <div style="background-color: white;" class="pa-1">
      <v-row dense>
        <v-col cols="12">
          <v-layout justify-end>
            <p class="color-morado-dark text-h4 text-uppercase font-weight-bold text-center ma-auto py-3">
              {{ get_dialogMain.title }}
            </p>
            <v-btn color="#764c9a" icon @click="cancel">
              <v-icon large>mdi-close-circle</v-icon>
            </v-btn>
          </v-layout>
        </v-col>
        <v-col cols="12">
          <MainGeneralForm
            v-if="form"
            :form="fields" :model="form"
            :on-success="save"
            :on-cancel="cancel"
            :buttonTitle="get_dialogMain.type"
            :params="get_dialogMain.params"/>
            <v-progress-linear v-if="showLoading" color="#602F73" indeterminate height="6"></v-progress-linear>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainGeneralForm from '@/components/forms/MainGeneralForm'
export default {
  props: ['fields', 'model', 'urls', 'message', 'params'],
  data: () => ({
    form: {},
    error_count: 0,
    showLoading: false
  }),
  components: {
    MainGeneralForm
  },
  watch: {
    get_object_dialog (val) {
      if (this.get_dialogMain.params.returnObjet) {
        this.SET_RELOAD_DATA({ name: this.get_dialogMain.type, model: val })
      }
      this.cancel()
    },
    get_object_two_put (val) {
      this.SET_DATA_STATUS_ACTION(false)
      if (this.get_dialogMain.params.returnObjet) {
        this.SET_RELOAD_DATA({ name: this.get_dialogMain.type, model: val })
      }
      this.cancel()
    },
    get_object_post (val) {
      this.SET_DATA_STATUS_ACTION(false)
      if (this.get_dialogMain.params.returnObjet) {
        this.SET_RELOAD_DATA({ name: this.get_dialogMain.type, model: val })
      }
      this.cancel()
    },
    get_object_delete (val) {
      if (this.get_dialogMain.params.returnObjet) {
        this.SET_RELOAD_DATA({ name: this.get_dialogMain.type, model: val })
      }
      this.cancel()
    },
    get_conekta_order (val) {
      this.SET_DATA_STATUS_ACTION(false)
      if (this.get_dialogMain.params.returnObjet) {
        this.SET_RELOAD_DATA({ name: this.get_dialogMain.type, model: val })
      }
      this.cancel()
    }
  },
  mounted () {
    this.form = Object.assign({}, this.model)
    // console.log('form', this.form)
  },
  methods: {
    ...mapActions(['POSTMultipartService', 'PUTObjectTwoService', 'PATCHObjectTwoService', 'POSTObjectService', 'DELETEObjectService', 'POSTConektaOrderToken']),
    ...mapMutations(['HIDDE_DIALOG_MAIN', 'SET_RELOAD_DATA', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT', 'SET_LOADING_DATA', 'SET_DATA_STATUS_ACTION']),
    save (model) {
      this.showLoading = true
      if (this.get_dialogMain.params.content === 'form-data') {
        const formData = this.createFormData(model)
        this.POSTMultipartService({ url: this.urls, data: formData })
        return
      }
      if (this.get_dialogMain.type === 'formpassword') {
        if (model.confirmnPassword === model.newPassword) {
          this.PUTObjectTwoService({ url: this.urls, data: model })
        } else {
          this.SHOW_DIALOG_ALERT({ title: 'Error !', width: 500, params: { message: 'Las contraseñas debe ser idénticas' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
        }
        return
      }
      if (this.get_dialogMain.type === 'nuevoClase') {
        // model.date = new Date(`${model.date} ${model.time}:00`)
        model.date = `${model.date}T${model.time}:00`
      }
      if (this.get_dialogMain.type === 'delete') {
        this.DELETEObjectService({ url: this.urls })
        return
      }
      if (this.get_dialogMain.type === 'updatePartial') {
        // console.log('model', model)
        this.PATCHObjectTwoService({ url: this.urls, data: model })
        return
      }
      if (this.get_dialogMain.type === 'updatePartialPaid') {
        this.PATCHObjectTwoService({ url: this.urls + `?payment=${model.payment}`, data: model })
        return
      }
      if (this.get_dialogMain.type === 'updateDate') {
        model.date = `${model.date}T${model.time}:00`
        this.PUTObjectTwoService({ url: this.urls, data: model })
        return
      }
      if (this.get_dialogMain.type === 'update') {
        this.PUTObjectTwoService({ url: this.urls, data: model })
        return
      }
      if (this.get_dialogMain.type === 'conekta') {
        this.POSTConektaOrderToken({ url: this.urls, data: model })
        return
      }
      // console.log('model', model)
      this.POSTObjectService({ url: this.urls, data: model })
    },
    createFormData (object) {
      const formData = new FormData()
      this.fields.map((field) => {
        formData.append(`${field.nameid}`, object[field.nameid])
      })
      return formData
    },
    saveImport (model) {
      console.log('model', model)
      // this.postServiceAction({ url: this.urls, data: model })
    },
    cancel () {
      this.showLoading = false
      this.HIDDE_DIALOG_MAIN()
    }
  },
  computed: {
    ...mapGetters(['get_dialogMain', 'get_object_dialog', 'get_object_two_put', 'get_object_post', 'get_object_delete', 'get_conekta_order'])
  }
}
</script>

<style></style>
