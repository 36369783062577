<template>
  <v-container fluid>
    <v-card class="mx-auto" elevation="0" color="#C9E7FF">
      <v-card-text>
        <v-btn class="mb-2" @click="createPatient" color="#001B71" dark>
          <v-icon left>mdi-plus</v-icon> Nuevo
        </v-btn>
        <v-btn class="mb-2 mx-2" @click="newRefresh" color="#001B71" dark>
          <v-icon left>mdi-refresh</v-icon> Refrescar
        </v-btn>
        <v-tabs color="#001B71" background-color="#C9E7FF" grow>
          <v-tab class="table-tab mr-5" @click="proximasClases">Pacientes</v-tab>
          <v-tab class="table-tab ml-5" @click="pasadasClases">Expediente</v-tab>
        </v-tabs>
        <v-progress-linear :active="loading" :indeterminate="loading" top color="#001B71"></v-progress-linear>
        <v-virtual-scroll v-if="showListCircule" :items="pacients" :item-height="100" height="450" class="container-border-color-azul">
          <template #default="{ item }">
            <v-divider color="white" />
            <v-list-item class="mx-5">
              <v-list-item-content class="ml-5">
                <v-list-item-title class="font-weight-bold">
                  <div class="color-morado text-md-h5 text-sm-h6 text-h6 font-weight-bold">{{item.clientName}}</div>
                </v-list-item-title>

                <v-list-item-subtitle>
                  <v-chip outlined id="circle-white-line">
                    <label class="subtitle-2 font-weight-bold text-uppercase">FISIO - {{item.therapistName}}</label>
                  </v-chip>
                  <div class="font-weight-bold color-azul title pb-3">{{item.lastTherapyReason}}</div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="mr-5">
                <v-row>
                  <v-col cols="12">
                    <div class="mx-1 color-morado text-md-h6 text-sm-h5 text-h6 font-weight-bold ma-auto text-center">
                      <div class="font-weight-bold color-morado-dark text-center">No. Citas</div>
                      <div class="font-weight-bold color-morado-dark text-center">{{ item.lastTherapyAppointments }}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-action>

              <v-list-item-action class="mr-5">
                <v-row>
                  <v-col cols="12">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" color="#001B71" x-small fab dark>
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="AddCitaPatient(item)" link>
                          <v-list-item-title>
                            <v-icon left>mdi-calendar-today</v-icon> Agregar Cita
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="SetAsComplete(item)" link>
                          <v-list-item-title>
                            <v-icon left>mdi-calendar-today</v-icon> Finalizar Terapia
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="background-line-white my-1" />
          </template>
        </v-virtual-scroll>

        <v-virtual-scroll v-if="showList" :items="expedientes" :item-height="100" height="450" class="container-border-color-azul">
          <template #default="{ item }">
            <v-divider color="white" />
            <v-list-item class="mx-5">
              <v-list-item-content class="ml-5">
                <v-list-item-title class="font-weight-bold">
                  <div class="color-morado text-md-h5 text-sm-h6 text-h6 font-weight-bold">{{item.clientName}}</div>
                </v-list-item-title>

                <v-list-item-subtitle>
                  <v-chip outlined id="circle-white-line">
                    <label class="subtitle-2 font-weight-bold text-uppercase">{{item.therapistName}}</label>
                  </v-chip>
                  <div v-for="(i, k) in item.therapies" class="color-azul mt-5" :key="k">
                    <label class="font-weight-bold">{{ formatoFecha(dateStringToDate(i.date), 'dd-mm-yyyy') }}</label> - {{i.reason}}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="mr-5">
                <v-row>
                  <v-col cols="12">
                    <div class="mx-1 color-morado text-md-h6 text-sm-h5 text-h6 font-weight-bold ma-auto text-center">
                      <div class="font-weight-bold color-morado-dark text-center">{{item.currentTherapyReason}}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="background-line-white my-1" />
          </template>
        </v-virtual-scroll>
      </v-card-text>
    </v-card>
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    MainFormDialog
  },
  mixins: [DatesUtils],
  data: () => ({
    pacients: [],
    expedientes: [],
    table: {
      color: '#C9E7FF',
      className: 'background-azul-light',
      header: {
        options: [],
        titles: [
          { text: 'Nombres', value: 'client.firstName', class: 'black--text' },
          { text: 'Apellidos', value: 'client.lastName', class: 'black--text' },
          { text: 'Fecha Clase', value: 'DateDinamic', class: 'black--text', showItem: 'spinningClass.date' },
          { text: 'Lugar', value: 'bike', class: 'black--text' },
          { text: 'Estatus', value: 'Status', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    },
    showListCircule: true,
    showList: false,
    form: {},
    object: {},
    url: '',
    message: '',
    loading: false
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_objects', 'get_reload',
      'get_PatientFisioForm', 'get_SchedulePatientFisioForm', 'get_PaidPaymentForm', 'get_PatientForm', 'get_ConfirmCompleteScheduleForm'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        if (this.showListCircule) this.pacients = rows
        else this.expedientes = rows
        this.loading = false
      }
    },
    get_reload ({ name, model }) {
      if (name === 'create') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Cita agendado correctamente' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
        }
      } else if (name === 'updatePartial') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Terapia finalizada correctamente' } })
        } else {
          this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: model.message } })
        }
      }
      this.GETListObjectsService({ url: '/Users/pacients' })
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.GETListObjectsService({ url: '/Users/pacients' })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    /* *==========*==========* */
    /* *==========*==========* */
    proximasClases () {
      this.paids = []
      this.GETListObjectsService({ url: '/Users/pacients' })
      this.showList = false
      this.showListCircule = true
    },
    pasadasClases () {
      this.expedientes = []
      this.GETListObjectsService({ url: '/Users/pacientsFiles' })
      this.showListCircule = false
      this.showList = true
    },
    createPatient () {
      this.form = this.get_PatientForm
      this.object = {
        firstName: '',
        lastName: '',
        birthDate: '',
        phoneNumber: '',
        email: '',
        signId: null,
        RoleIds: [4],
        password: '12345'
      }
      this.url = '/Users'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Nuevo Paciente', width: 700, params: { returnObjet: true } })
    },
    newRefresh (item) {
      this.loading = true
      this.GETListObjectsService({ url: '/Users/pacients' })
    },
    AddCitaPatient (item) {
      // console.log(item)
      if (!item.therapyCompleted) {
        this.form = this.get_SchedulePatientFisioForm
        this.object = { clientId: item.clientId, appointReason: item.lastTherapyReason }
      } else {
        this.form = this.get_PatientFisioForm
        this.object = { clientId: item.clientId }
      }
      this.url = '/Appointments'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Nueva Cita', width: 600, params: { returnObjet: true } })
    },
    SetAsComplete (item) {
      console.log(item)
      this.form = this.get_ConfirmCompleteScheduleForm
      this.object = { clientId: item.clientId }
      this.url = `/Therapies/${item.lastTherapyId}/setAsComplete`
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'updatePartial', title: 'Finalizar Terapia', width: 600, params: { returnObjet: true } })
    }
  }
}
</script>

  <style scoped>
  .table-tab {
  border: 1px #355DC8 solid;
  border-radius: 25px 25px 0px 0px
}
.v-tab--active{
  border: 1px #355DC8 solid;
  background-color: #355DC8;
  color: white;
}
</style>
