<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <p class="text-md-h3 text-center text-strong color-azul">
          <span class="text-strong"> Historial </span>
        </p>
      </v-col>
      <v-col cols="12">
        <p class="font-weight-bold color-azul-medium text-strong-1">Consultas</p>
      </v-col>
    </v-row>
    <v-divider class="my-2 background-line-white" />
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success='successTableAction' :itemsAction='successTableAction' />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import { Validate } from '@/mixins/validateFormMixin'
export default {
  name: 'CyclingMyAccount',
  components: {
    DataTableMain
  },
  mixins: [DatesUtils, Validate],
  data: () => ({
    table: {
      color: '#C9E7FF',
      className: 'background-azul-light',
      header: {
        options: [],
        titles: [
          { text: 'Nombres', value: 'client.firstName', class: 'black--text' },
          { text: 'Apellidos', value: 'client.lastName', class: 'black--text' },
          { text: 'Fecha', value: 'DateDinamic', class: 'black--text', showItem: 'date' },
          { text: 'Descripcion', value: 'appointReason', class: 'black--text' }
          // ,{ text: 'Estatus', value: 'Status', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    },
    user: {}
  }),
  computed: {
    ...mapGetters(['get_objects'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.table.body.data = rows
      }
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    this.GETListObjectsService({ url: `/Appointments?clientId=${this.user.id}` })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations([]),
    /* *==========*==========* */
    /* *==========*==========* */
    successTableAction (item) {
      this[item.action](item)
    }
  }
}
</script>

<style scoped>
</style>
