<template>
  <v-container class="fill-height">
    <v-layout>
      <v-flex>
        <v-row dense>
          <v-card width="300" elevation="0" color="#A87BC9" class="mt-4 ma-auto">
            <v-card-title>
              <p class="text-md-h4 text-uppercase ma-auto">
                <v-icon color="white" x-large>mdi-emoticon-happy-outline</v-icon>
                <label class="text-strong color-morado-dark">synergy</label>
              </p>
              <p class="font-weight-bold text-uppercase ma-auto" v-if="user">
                <v-chip outlined id="circle-white-line">
                  <label class="text-strong-1 text-uppercase color-morado-dark">{{showName()}}</label>
                </v-chip>
              </p>
            </v-card-title>
            <v-card-text>
              <v-list color="#A87BC9" flat dark>
                <v-list-item-group v-model="selectedItem" color="#602F73">
                  <span v-for=" (item, i) in menuMain" :key="i">
                    <v-list-item link @click="menu(item.action)">
                      <v-list-item-content>
                        <v-list-item-title class="text-Gotham-Light text-uppercase">{{item.name}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </span>
                </v-list-item-group>
                <v-divider />
                <v-list-item link @click="menu('SignOff')">
                  <v-list-item-title class="text-Gotham-Light text-uppercase">Cerrar Sesion</v-list-item-title>
                </v-list-item>
              </v-list>

              <div class="container-height-bottom-100">
                <v-btn class="ml-3 text-Gotham-Light" color="#602F73" rounded dark>Cycling</v-btn>
                <!-- <v-btn class="ml-3 text-Gotham-Light" color="white" @click="menu('PhysioExpedienteView')" rounded outlined> Fisio </v-btn> -->
              </div>
            </v-card-text>
          </v-card>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import router from '@/router'
export default {
  data: () => ({
    selectedItem: 1,
    menuMain: [],
    cliente: [
      { name: 'Mi Perfil', action: 'MyAccountUserView' },
      { name: 'Calendario', action: 'MyAccountCalendarView' },
      { name: 'Lista de espera', action: 'MyAccountListWaitView' },
      { name: 'Pagos', action: 'MyAccountPaidsView' },
      { name: 'Paquete Vigente', action: 'MyAccounEnrollmentsView' }
    ],
    straff: [
      { name: 'Mi Perfil', action: 'MyAccountUserView' },
      { name: 'Calendario', action: 'MyAccountCalendarView' },
      { name: 'Coaches', action: 'MyAccounCoachesView' },
      { name: 'Riders', action: 'MyAccounRidersView' }
    ],
    admin: [
      { name: 'Mi Perfil', action: 'MyAccountUserView' },
      { name: 'Calendario', action: 'MyAccountCalendarView' },
      { name: 'Coaches', action: 'MyAccounCoachesView' },
      { name: 'Clases', action: 'MyAccounSpinningClass' },
      { name: 'Paquetes', action: 'MyAccountPackagesView' },
      { name: 'Riders', action: 'MyAccounRidersView' }
    ],
    user: null,
    rol: null
  }),
  computed: {
    ...mapGetters(['get_session'])
  },
  watch: {
    get_session (data) {
      this.$cookies.remove('user_sny')
      localStorage.clear()
      // router.push({ name: 'home' })
      window.location = '/'
    }
  },
  mounted () {
    this.rol = JSON.parse(localStorage.getItem('permisos'))
    this.user = this.$cookies.get('user_sny')
    this.validateMenu(this.rol)
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['SHOW_DIALOG_SESSION']),
    // *==========*==========*==========*==========* \\
    menu (item) {
      if (item === 'SignOff') {
        this.SHOW_DIALOG_SESSION({ title: 'Cerrar Sesión', width: 500, params: { message: '¿Desea cerrar sesión?' } })
        return
      }
      router.push({ name: item })
    },
    validateMenu (rol) {
      if (rol.name === 'CLIENT') {
        this.menuMain = []
        this.menuMain.push(...this.cliente)
      } else {
        this.menuMain = []
        this.menuMain.push(...this.admin)
      }
    },
    showName () {
      if (this.rol.name === 'CLIENT') return this.user.email
      return 'STAFF MOOD ON'
    }
  }

}
</script>

<style>

</style>
