<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card outlined tile>
          <v-card-title>
            <label class="text-h4 text-uppercase ma-auto">
              <span class="text-strong-1">Confirmar tu correo electrónico</span>
            </label>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="py-5 text-light text-center">Estás a un solo paso de activar tu cuenta.
                  <br/>
                  <br/>
                  <br/>
                  Haz clic en el botón para empezar a agendar citas con <span class="black--text font-weight-bold">FISIOS</span> o clases para
                  <span class="black--text font-weight-bold">CYCLING</span>
                </p>
                <div class="text-center py-5">
                  <v-btn color="#602F73" @click="ActiveAccount" large dark>
                    <span class="text-strong-1">¡Activar mi cuenta ahora !</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <FooterMain class="pt-5" color="white" class-name="color-azul" color-icon="color-morado"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import router from '@/router'
export default {
  components: {},
  data: () => ({
    token: ''
  }),
  computed: {
    ...mapGetters(['get_object_post'])
  },
  watch: {
    get_object_post (row) {
      if (row.message) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 600, params: { message: row.message, confirm: true } })
      }
      this.SHOW_DIALOG_ALERT({ title: 'Exitoso !', width: 600, params: { message: 'La cuenta se activó correctamente', confirm: true } })
      const user = { id: row.id, firstName: row.firstName, lastName: row.lastName, email: row.email }
      this.$cookies.set('user_sny', user)
      localStorage.setItem('permisos', JSON.stringify(row.roles[0]))
      setTimeout(() => { window.location = '/' }, 2000)
    }
  },
  mounted () {
    this.token = this.$route.params.token
  },
  methods: {
    ...mapActions(['POSTObjectService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    ActiveAccount () {
      this.POSTObjectService({ url: `Users/login/emailToken/${this.token}`, data: { token: this.token } })
    }
  }
}
</script>
  <style scoped>
  </style>
