<template>
  <v-dialog v-model="get_dialogAlert.dialog" persistent justify-center :width="get_dialogAlert.width">
    <v-card class="pa-0" outlined tile>
      <v-card-title class="pa-1">
        <div class="text-md-h5 text-sm-h5 text-h5 text-center ma-auto">
          <span :class="(get_dialogAlert.params.color)? `color-azul text-strong-1`: `color-morado-dark text-strong-1`">
            {{ get_dialogAlert.title }}
          </span>
        </div>
        <v-btn :color="(get_dialogAlert.params.color)? get_dialogAlert.params.color : `#764C9A`" icon @click="close">
          <v-icon large>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <p class="color-azul text-center text-strong-1 py-5">
              <v-icon x-small color="#A87BC9">mdi-checkbox-blank-circle</v-icon> {{ get_dialogAlert.params.message }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-layout justify-center>
          <v-btn @click="close" color="#602F73" dark> Cerrar </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(['get_dialogAlert'])
  },
  methods: {
    ...mapMutations(['HIDDE_DIALOG_ALERT']),
    close () {
      this.HIDDE_DIALOG_ALERT()
    }
  }
}
</script>
<style>
</style>
