<template>
  <v-container fluid>
    <v-row class="background-azul-light" align="center" no-gutters dense>
      <v-col cols="12" sm="4" md="4">
        <v-img class="img-link" src="@/assets/physio/physio_r.jpg"/>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <div class="container-height-150 text-md-h3 text-sm-h4 text-xs-h5 color-azul-medium">
          <label class="ma-auto text-strong-1 font-weight-bold">
            Bienvenido(a), <span v-if="user.email">{{ user.firstName }}</span>
          </label>
        </div>
        <div class="container-height-100 text-md-h4 text-sm-h5 text-xs-h6 color-azul">
          <p v-if="showMessage === 0" class="text-strong-1 text-center ma-auto">¡ Estás a un click de confirmar tu cita !</p>
          <p v-else-if="showMessage === 1" class="text-strong-1 text-center  ma-auto">¡ Para confirmar la cita es necesario iniciar sesion !</p>
        </div>
        <div v-if="showMessage === 0" class="text-md-h3 text-center py-5 font-weight-bold color-azul-medium">
          <span class="text-strong">{{ fecha }}</span>
        </div>
        <div v-if="showMessage === 0" class="text-sm-h5 text-md-h4 text-center font-weight-bold color-azul">
          <span class="text-strong">
            <v-icon color="#001B71" class="mdi-36px">mdi-clock-outline</v-icon>
            <span class="text-strong"> {{hour}} Hrs</span>
          </span>
        </div>
        <v-row dense class="pt-8 pb-3">
          <v-col cols="6" class="text-center" >
            <v-btn color="#001B71" class="text-md-h5 text-sm-h5" @click="sendAction('cancel')" large dark>
              <span class="text-light">Cancelar</span>
            </v-btn>
          </v-col>
          <v-col v-if="showMessage === 0" cols="6" class="text-center">
            <v-btn color="#001B71" class="text-md-h5 text-sm-h5" @click="sendAction('confirm')" :loading="loadingSend" large dark>
              <span class="text-light">Confirmar</span>
            </v-btn>
          </v-col>
          <v-col v-if="showMessage === 1" cols="6" class="text-center">
            <v-btn color="#001B71" class="text-md-h5 text-sm-h5" @click="sendAction('session')" large dark>
              <span class="text-light">Iniciar Sesion</span>
            </v-btn>
          </v-col>
        </v-row>
        <div class="container-height-100">
          <p class="text-center ma-auto color-azul-medium subtitle-1">
            Por favor, llenar el siguiente formulario
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <FooterMain color="grey lighten-4" class-name="color-azul" color-icon="color-morado"/>
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import { IsUserSession } from '@/store/modules/config'
import router from '@/router'
export default {
  name: 'PhysioCalendar',
  components: {
    MainFormDialog
  },
  mixins: [DatesUtils],
  data: () => ({
    id: 0,
    clase: {},
    hour: '',
    fecha: '',
    user: {
      firstName: '',
      lastName: ''
    },
    showMessage: 0,
    form: {},
    object: {},
    url: '',
    message: '',
    terapia: null,
    loadingSend: false
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_object', 'get_object_post', 'get_object_two', 'get_object_two_post', 'get_loading',
      'get_ScheduleConfirmPatientCitaForm'])
  },
  watch: {
    get_object (row) {
      if (row) {
        const fecha = this.dateStringToDate(row.date)
        this.fecha = this.formatoFecha(fecha, 'dd-mm-yyyy')
        this.hour = this.timeStringToDate(row.date)
        this.clase.date = row.date
        // this.fecha = row.date.substring(0, 10)
      }
    },
    get_reload ({ name, model }) {
      if (name === 'create') {
        if (model.success) {
          this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Tu cita ha sido confirmada !' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
            router.push({ name: 'PhysioView' })
          }, 1500)
        }
      }
    },
    get_object_two (data) {
      this.terapia = data
    },
    get_object_two_post (data) {
      this.loadingSend = false
      if (data.message) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Problemas al agendar la cita !' } })
        return
      }
      this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'La cita esta agendado !' } })
      setTimeout(() => {
        this.HIDDE_DIALOG_ALERT()
        router.push({ name: 'PhysioView' })
      }, 1500)
    },
    get_loading (val) {
      this.loadingSend = val
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.GETObjectService({ url: `/AppointmentAvailableDates/${this.id}` })
    if (IsUserSession()) {
      this.user = this.$cookies.get('user_sny')
      this.clase = { clientId: this.user.id }
      this.GETObjectTwoService({ url: `/Therapies/pacient/${this.user.id}/lastTherapy` })
    } else {
      this.showMessage = 1
    }
  },
  methods: {
    ...mapActions(['GETObjectService', 'GETObjectTwoService', 'POSTObjectTwoService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT', 'SHOW_DIALOG_MAIN']),
    // *==========*==========*==========*==========* \\
    sendAction (name) {
      if (name === 'cancel') {
        router.push({ name: 'PhysioCalendarView' })
      } else if (name === 'confirm') {
        if (this.terapia) {
          if (this.terapia.completed) {
            this.form = this.get_ScheduleConfirmPatientCitaForm
            this.object = this.clase
            this.url = '/Appointments'
            this.message = 'Registro Agregado Correctamente'
            this.SHOW_DIALOG_MAIN({ type: 'create', title: 'Confirmar Cita', width: 600, params: { returnObjet: true, showLoading: true } })
            return
          }
        }
        this.loadingSend = true
        this.POSTObjectTwoService({ url: '/Appointments', data: { clientId: this.terapia.clientId, date: this.clase.date, appointReason: this.terapia.reason } })
      } else if (name === 'session') {
        router.push({ name: 'PerfilView' })
      }
    }
  }

}
</script>

  <style>

  </style>
