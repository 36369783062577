<template>
  <v-container
    fluid
    class="pa-0 ma-0"
  >
    <v-row dense>
      <v-col cols="12">
        <v-layout
          justify-center
          align-center
          class="my-3"
        >
          <v-flex md11>
            <v-row
              dense
              class="align-center"
            >
              <v-divider
                class="border-divider"
                color="#602F73"
                dark
              />
              <label class="text-md-h4 text-sm-h5 text-xl-h6 container-border-color-rosado-dark text-uppercase">
                <span class="text-strong-1">Our Coaches</span>
              </label>
              <v-divider
                class="border-divider"
                color="#602F73"
                dark
              />
            </v-row>
          </v-flex>
        </v-layout>
      </v-col>

      <v-col cols="12" align="center" v-if="loadingData">
        <v-progress-circular
          :size="70"
          :width="10"
          color="#602F73"
          indeterminate
        />
      </v-col>

      <v-col cols="12" v-if="!loadingData">
        <v-layout
          justify-center
          align-center
        >
          <v-flex md11>
            <v-card
              tile
              elevation="0"
            >
              <v-card-text>
                <v-row dense>
                  <v-col
                    v-for=" (item, index) in couches"
                    :key="index"
                    cols="12"
                    sm="4"
                    md="3"
                  >
                    <AvatarMainField
                      :model="item"
                      type="cycling"
                      :on-success="viewData"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="12">
        <FooterMain
          color="grey lighten-5"
          class-name="color-azul"
          color-icon="color-morado"
        />
      </v-col>
    </v-row>
    <InfoPersonDialog
      v-if="get_dialogMain.dialog"
      :model="couche"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AvatarMainField from '@/components/fields/AvatarMainField'
import InfoPersonDialog from '@/components/dialogs/InfoPersonDialog.vue'
import router from '@/router'
export default {
  name: 'CyclingPackage',
  components: {
    AvatarMainField,
    InfoPersonDialog
  },
  data: () => ({
    couches: [],
    couche: {},
    loadingData: true
  }),
  computed: {
    ...mapGetters(['get_objects', 'get_dialogMain'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.couches = []
        this.couches.push(...rows)
        this.loadingData = false
      }
    }
  },
  mounted () {
    this.GETListObjectsService({ url: '/Users?roleId=2&getPhoto=true' })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN']),
    // *==========*==========*==========*==========* \\
    sendPage (name) {
      router.push({ name: name })
    },
    viewData (data) {
      this.couche = data
      this.SHOW_DIALOG_MAIN({ type: 'formView', title: 'Informacion Cycling', width: 900, params: {} })
    }
  }
}
</script>

<style>
</style>
