<template>
  <v-container fluid class="pa-0 ma-0">
    <v-toolbar color="#A87BC9" elevation="0" dark>
      <v-toolbar-title class="ma-auto">
        <span class="text-md-h4 text-sm-h5 text-xl-h6 text-strong-1 font-weight-bold text-uppercase text--white">Comprar</span>
      </v-toolbar-title>
    </v-toolbar>

    <v-row dense>
      <v-col cols="12">
        <v-layout justify-center align-center class="my-3">
          <v-flex md11>
            <v-row dense class="align-center">
              <v-divider class="border-divider" color="#602F73" dark />
              <label class="text-md-h4 text-sm-h5 text-xl-h6 container-border-color-rosado-dark text-uppercase">
                <span class="text-strong-1">Paquetes Indoor</span>
              </label>
              <v-divider class="border-divider" color="#602F73" dark />
            </v-row>
          </v-flex>
        </v-layout>
      </v-col>

      <v-col cols="12">
        <v-layout justify-center align-center>
          <v-flex md11>
            <v-card tile elevation="0">
              <v-card-text>
                <v-row dense>
                  <v-col cols="6" sm="4" md="3" v-for=" (item, index) in paquetes" :key="index">
                    <SquarePackageField :model="item" :on-success="salePackage" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout justify-center align-center class="my-3">
          <v-flex md3 class="text-center">
            <a class="subtitle-1 font-weight-light link-main text-uppercase" @click="sendPage('AvisoPrivacidadView')"><span class="text-Gotham-Light">ver aviso de privacidad</span></a>
          </v-flex>
          <v-flex md3 class="text-center">
            <a class="subtitle-1 font-weight-light link-main text-uppercase" @click="sendPage('TerminoyCondicionesView')"><span class="text-Gotham-Light">ver terminos y condiciones</span></a>
          </v-flex>
          <v-flex md3 class="text-center">
            <a class="subtitle-1 font-weight-light link-main text-uppercase" @click="sendPage('home')"><span class="text-Gotham-Light">volver al inicio</span></a>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="12">
        <FooterMain color="grey lighten-5" className="color-azul" colorIcon="color-morado" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Enrollments  => son las incripciones de las clases
// SpinningClass =>  Catalolo de clases  (Agenda de clases)
import { mapActions, mapGetters, mapMutations } from 'vuex'
import router from '@/router'
import SquarePackageField from '@/components/fields/SquarePackageField.vue'
import { IsUserSession, UserSession } from '@/store/modules/config'

export default {
  name: 'CyclingPackage',
  components: {
    SquarePackageField
  },
  data: () => ({
    paquetes: []
  }),
  computed: {
    ...mapGetters(['get_objects', 'get_object_post'])
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.paquetes.push(...rows)
      }
    },
    get_object_post (row) {
      if (row.message) {
        this.$swal({
          type: 'error',
          icon: 'error',
          title: 'Exito !'
        })
        return
      }
      this.$swal({
        type: 'success',
        icon: 'success',
        title: 'Exito !',
        text: '¡Paquete Comprado !',
        timer: 2000
      })
    }
  },
  mounted () {
    if (IsUserSession()) {
      this.GETListObjectsService({ url: `/Packages?userId=${UserSession().id}` })
    } else {
      this.GETListObjectsService({ url: '/Packages' })
    }
  },
  methods: {
    ...mapActions(['GETListObjectsService', 'POSTObjectService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    sendPage (name) {
      router.push({ name: name })
    },
    salePackage (model) {
      if (IsUserSession()) {
        router.push({ name: 'CyclingConfirmPaidView', params: { id: model.id } })
        // this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Compra con tarjeta no disponible' } })
        // setTimeout(() => { this.HIDDE_DIALOG_ALERT() }, 3500)
      } else {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Debe iniciar sesion para comprar el paquete' } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
        }, 2000)
      }
    }
  }
}
</script>

<style>
</style>
