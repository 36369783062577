<template>
  <v-container fluid>
    <v-row class="background-morado" align="center" no-gutters dense>
      <v-col cols="12" sm="4" md="4">
        <v-img class="img-link" src="@/assets/cycling/coaches/cuadro_vero.jpg" />
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <div class="container-height-150 text-md-h3 text-sm-h4 text-xs-h5 color-azul">
          <label class="ma-auto text-strong-1 font-weight-bold">
            Bienvenido(a), <span v-if="user.email">{{ user.firstName }} {{ user.lastName }}</span>
          </label>
        </div>

        <div class="container-height-100 text-md-h4 text-sm-h5 text-xs-h6 color-azul">
          <p v-if="showMessage === 0" class="text-strong-1 text-center ma-auto">¡ Estás a un click de agendar tu ride !</p>
          <p v-else-if="showMessage === 1" class="text-strong-1 text-center  ma-auto">¡ Para confirmar el ride es necesario iniciar sesión !</p>
          <p v-else-if="showMessage === 2" class="text-strong-1 text-center  ma-auto">¡ No cuentas con un paquete vigente !</p>
        </div>

        <p v-if="showMessage === 2" class="text-md-h4 text-sm-h5 text-center py-5 color-azul">
          ¿ Deseas adquirir uno para registrarte en esta clase ?
        </p>
        <div v-if="showMessage === 0" class="text-sm-h5 text-md-h4 text-center py-5 font-weight-bold color-azul">
          <span class="text-strong">{{ fecha }}</span>
        </div>
        <div v-if="showMessage === 0" class="text-sm-h5 text-md-h4 text-center font-weight-bold color-azul">
          <v-icon color="#001B71" class="mdi-36px">mdi-clock-outline</v-icon>
          <span class="text-strong"> {{hour}} Hrs</span>
        </div>

        <div v-if="showMessage=== 3 || showMessage=== 4" class="container-height-100 text-md-h4 text-sm-h5 text-xs-h6 color-azul">
          <p v-if="showMessage === 3" class="text-center ma-auto">
            La clase en el lugar seleccionado esta ocupado, pero puedes quedarte en lista de espera. <br/>
            si se libera el lugar se te asignará automaticamente.
          </p>
          <p v-if="showMessage === 4" class="text-center ma-auto">
            La clase esta llena, pero puedes quedarte en lista de espera. <br/>
            Si se libera un lugar se te asignará automáticamente.
          </p>
        </div>

        <v-row dense class="pt-8 pb-3">
          <v-col cols="6" class="text-center">
            <v-btn color="#001B71" class="text-md-h5 text-sm-h5" @click="sendAction('cancel')" large dark>
              <span class="text-light">Cancelar</span>
            </v-btn>
          </v-col>
          <v-col v-if="showMessage === 0" cols="6" class="text-center">
            <v-btn color="#001B71" class="text-md-h5 text-sm-h5" @click="sendAction('confirm')" :loading="loadingSend" large dark>
              <span class="text-light">Agendar</span>
            </v-btn>
          </v-col>
          <v-col v-if="showMessage === 1" cols="6" class="text-center">
            <v-btn color="#001B71" class="text-md-h5 text-sm-h5" @click="sendAction('session')" large dark>
              <span class="text-light">Iniciar sesión</span>
            </v-btn>
          </v-col>
          <v-col v-if="showMessage === 2" cols="6" class="text-center">
            <v-btn color="#001B71" class="text-md-h5 text-sm-h5" @click="sendAction('salePackage')" large dark>
              <span class="text-light">Comprar Paquete</span>
            </v-btn>
          </v-col>
          <v-col v-if="showMessage === 3 || showMessage === 4" cols="6" class="text-center">
            <v-btn color="#001B71" class="text-md-h5 text-sm-h5" @click="sendAction('listwaitBiker')" large dark>
              <span class="text-light">Entrar a Lista de Espera</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense no-gutters>
      <v-col cols="12">
        <FooterMain color="grey lighten-5" class-name="color-azul" color-icon="color-morado"/>
      </v-col>
    </v-row>
    <AvisoDialog v-if="get_dialogAlert.dialog" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import AvisoDialog from '@/components/dialogs/AvisoDialog.vue'
import { IsUserSession } from '@/store/modules/config'
import router from '@/router'
export default {
  name: 'PhysioCalendar',
  components: {
    AvisoDialog
  },
  mixins: [DatesUtils],
  data: () => ({
    id_spinning: 0,
    bike_id: 0,
    clase: {},
    hour: '',
    fecha: '',
    user: {
      firstName: '',
      lastName: ''
    },
    showMessage: 0,
    loadingSend: false
  }),
  computed: {
    ...mapGetters(['get_dialogAlert', 'get_object', 'get_object_post', 'get_object_two'])
  },
  watch: {
    get_object (row) {
      if (row) {
        // this.clase = row
        const fecha = this.dateStringToDate(row.date)
        this.fecha = this.formatoFecha(fecha, 'dd-mm-yyyy')
        this.hour = this.timeStringToDate(row.date)

        if (row.availableBikes.length > 0) {
          const bike = row.availableBikes.find((a) => a === this.bike_id)
          if (!bike) {
            this.showMessage = 4
          }
        } else {
          this.showMessage = 4
        }
        // this.fecha = row.date.substring(0, 10)
      }
    },
    get_object_post (row) {
      this.loadingSend = false
      if (row.message) {
        this.SHOW_DIALOG_ALERT({ title: 'Error !', width: 500, params: { message: row.message } })
        setTimeout(() => { this.HIDDE_DIALOG_ALERT() }, 1500)
        return
      }

      this.SHOW_DIALOG_ALERT({ title: 'Confirmación !', width: 500, params: { message: 'Clase agendada correctamente' } })
      setTimeout(() => {
        this.HIDDE_DIALOG_ALERT()
        router.push({ name: 'CyclingView' })
      }, 1500)
    },
    get_object_two (row) {
      if (IsUserSession()) {
        const rol = JSON.parse(localStorage.getItem('permisos'))
        if (rol.name === 'CLIENT') {
          if (row.success) this.showMessage = 2
        } else this.showMessage = 0
        return
      }
      if (row.success) {
        this.showMessage = 2
      }
    }
  },
  mounted () {
    this.id_spinning = this.$route.params.id
    this.bike_id = this.$route.params.position
    this.GETObjectService({ url: `/SpinningClass/${this.id_spinning}` })
    if (IsUserSession()) {
      this.user = this.$cookies.get('user_sny')
      this.clase = { userId: this.user.id, spinningClassId: this.id_spinning, bike: this.bike_id }
      this.GETObjectTwoService({ url: `/Packages/activePackage?userId=${this.user.id}` })
    } else {
      this.showMessage = 1
    }
  },
  methods: {
    ...mapActions(['GETObjectService', 'POSTObjectService', 'GETObjectTwoService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    sendAction (name) {
      if (name === 'cancel') {
        router.push({ name: 'CyclingCalendarView' })
      } else if (name === 'confirm') {
        this.loadingSend = true
        this.POSTObjectService({ url: '/Enrollments', data: this.clase })
      } else if (name === 'listwaitBiker') {
        this.loadingSend = true
        this.POSTObjectService({ url: '/Enrollments/WaitingList', data: this.clase })
      } else if (name === 'session') {
        router.push({ name: 'PerfilView' })
      } else if (name === 'salePackage') {
        router.push({ name: 'CyclingComprarView' })
      }
    }
  }

}
</script>

  <style>

  </style>
