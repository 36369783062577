<template>
  <div>
    <v-toolbar
      flat
      :color=" (datatable.color)? datatable.color : 'white'"
      class="elevation-1"
    >
      <v-toolbar-items class="hidden-sm-and-down">
        <span
          v-for="(item, index) in datatable.header.options"
          :key="index"
          class="pt-4"
        >
          <span v-if="item.hidden == ''">
            <v-btn
              v-if="item.action"
              small
              :color="item.color"
              dark
              class="mr-1"
              @click="action(item)"
            >
              {{ item.title }}
              <v-icon
                v-if="item.icon"
                right
                dark
              >{{ item.icon }}</v-icon>
            </v-btn>
            <v-menu
              v-else
              offset-y
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  color="teal darken-1"
                  class="white--text"
                  v-bind="attrs"
                  small
                  v-on="on"
                >
                  {{ item.title }} <v-icon
                    right
                    dark
                  >{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(option, index) in item.options"
                  :key="index"
                  link
                  dense
                  @click="action(option)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ option.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle>{{ option.title }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </span>
      </v-toolbar-items>
      <span class="hidden-md-and-up">
        <v-menu>
          <template #activator="{ attrs, on }">
            <v-app-bar-nav-icon
              v-bind="attrs"
              small
              v-on="on"
            />
            <!--
                  <v-btn color="teal darken-1" class="white--text"
                      v-bind="attrs" v-on="on" small> <v-icon dark>mdi-cog</v-icon>
                  </v-btn>-->
          </template>
          <v-list dense>
            <span
              v-for="(item, index) in datatable.header.options"
              :key="index"
            >
              <span v-if="item.hidden == ''">
                <v-list-item
                  v-if="item.action"
                  link
                  dense
                  @click="action(item)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-group
                  v-else
                  :value="true"
                  no-action
                  sub-group
                >
                  <template #activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }} <v-icon
                        right
                        dark
                      >{{ item.icon }}</v-icon></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(option, index) in item.options"
                    :key="index"
                    link
                    dense
                    @click="action(option)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ option.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle>{{ option.title }}</v-list-item-subtitle>
                  </v-list-item>

                </v-list-group>
              </span>
            </span>
          </v-list>
        </v-menu>
      </span>

      <v-spacer />

      <v-text-field
        v-model="buscar"
        label="Buscar"
        class="inputSearch"
        clearable
        outlined
        dense
      />
    </v-toolbar>

    <v-data-table
      v-model="datatable.modelMain"
      :item-key="datatable.itemKey"
      :show-select="datatable.showSelect"
      :single-select="datatable.singleSelect"
      :headers="datatable.header.titles"
      :items="datatable.body.data"
      :hide-default-footer="!datatable.footer.paging"
      :footer-props="footer"
      :search="buscar"
      :loading="datatable.header.loading"
      loading-text="Cargando por favor espere"
      :class="`${datatable.className} elevation-1`"
      dense
    >
      <template #no-data>
        <v-alert type="info" border="left" :value="true" class="ma-1" dense>
          Lo siento, no hay registros para mostrar
        </v-alert>
      </template>

      <template #item.Status="{ item }">
        <v-chip color="info" outlined label small>
          {{ item.status.name }}
        </v-chip>
      </template>
      <template #item.Activo="{ item }">
        <v-chip
          :color="(item.activo == 'si')? 'success':'error'"
          class="ma-1"
          small
          label
          outlined
        >
          <span class="font-weight-bold text-uppercase">{{ item.activo }}</span>
        </v-chip>
      </template>

      <template #item.Created="{ item }">
        {{ convertDate(item.created_at) }}
      </template>
      <template #item.DateMain="{ item }">
        <span class="font-weight-bold">
          {{ convertFormatDate(item.date) }}
        </span>
      </template>
      <template #item.DateDinamic="{ item, header }">
        <span class="black--text font-weight-bold">
          {{ FormatDateDinamic(item, header.showItem) }}
        </span>
      </template>
      <template #item.Updated="{ item }">
        {{ convertDate(item.updated_at) }}
      </template>

      <template #item.FieldDinamicData="{ item, header }">
        {{ events(header, item) }}
      </template>

      <template #item.FieldDinamicDataSimple="{ item, header }">
        <span> {{ events(header, item) }}</span>
      </template>

      <template #item.FieldDinamicDataChip="{ item, header }">
        <v-chip
          color="blue darken-1"
          class="ma-1"
          small
          label
          outlined
        >
          <span class="font-weight-bold text-uppercase"> {{ events(header, item) }}</span>
        </v-chip>
      </template>

      <template #item.SwitchPermiss="{ item }">
        <v-switch
          v-model="item.selected"
          label="Activo"
          color="success"
          hide-details
          class="switch"
          @change="setActiveRow({ action:'SetActionActive', item: item})"
        />
      </template>

      <template #item.AccessAll="{ item }">
        <v-chip
          v-if="item.descripcion.special"
          class="ma-1"
          color="info"
          dark
          outlined
        >
          {{ item.descripcion.special }}
        </v-chip>
        <v-chip
          v-else
          class="ma-1"
          color="info"
          dark
          outlined
        >
          Normal
        </v-chip>
      </template>

      <template #item.ActionsLocal="{ item }">
        <v-menu offset-y>
          <template #activator="{ attrs, on }">
            <v-btn
              color="#001B71"
              class="white--text ma-1"
              v-bind="attrs"
              small
              v-on="on"
            >
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <span
              v-for="(event, index) in datatable.body.actions"
              :key="index"
            >
              <v-list-item
                v-if="event.hidden == ''"
                link
                dense
                @click="items( { body:event, action:event.action, item:item})"
              >
                <v-list-item-title>
                  <v-icon :color="event.color">{{ event.icon }}</v-icon> {{ event.title }}
                </v-list-item-title>
              </v-list-item>
            </span>
          </v-list>
        </v-menu>
      </template>

      <template #item.Actions="{ item }">
        <span>
          <v-menu offset-y>
            <template #activator="{ attrs, on }">
              <v-btn
                color="#602F73"
                class="white--text ma-1"
                v-bind="attrs"
                small
                v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <!-- Acciones procesos -->
              <!-- <v-list-item-group>
                <span v-if="item.last_movement && item.last_movement.actions.length > 0">
                  <v-list-item
                    v-for="(action, index) in item.last_movement.actions"
                    :key="index"
                    link
                    @click="items({action:action.accion, item:item, body:action})"
                  >
                    <v-list-item-icon>
                      <v-icon :color="action.status_finish.color">mdi-decagram</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ action.status_finish.nombre }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </span>
              </v-list-item-group> -->
              <!-- Divisor -->
              <!-- <v-list-item dense>
                <v-divider />
                <v-subheader class="black--text font-weight-bold text-center">Generales</v-subheader>
                <v-divider />
              </v-list-item> -->
              <!-- Acciones Locales -->
              <v-list-item-group>
                <span
                  v-for="(event, index) in datatable.body.actions"
                  :key="index"
                >
                  <v-list-item
                    v-if="event.hidden == ''"
                    link
                    @click="items({action:event.action, item:item, body:action})"
                  >
                    <v-list-item-icon>
                      <v-icon :color="event.color">{{ event.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> {{ event.title }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </span>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </span>
      </template>

      <template #footer.page-text="props">
        {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { NumberUtils } from '@/mixins/NumberMixin'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'TableMain',
  mixins: [NumberUtils, DatesUtils],
  props: ['datatable', 'onSuccess', 'itemsAction'],
  data: () => ({
    expand: false,
    buscar: '',
    footer: {
      showFirstLastPage: true,
      'items-per-page-text': 'Registros por página'
    }
  }),
  methods: {
    action (item) {
      this.onSuccess(item)
    },
    items (item) {
      this.itemsAction(item)
    },
    events (header, item) {
      return this[header.action](header.field, item)
    },
    convertDate (date) {
      const dateString = new Date(date).toISOString().substr(0, 10)
      const timeString = new Date(date).toLocaleTimeString()

      return `${dateString} ${timeString}`
    },
    convertFormatDate (date) {
      if (!date) return

      const dateitem = this.formatoFecha(this.dateStringToDate(date), 'yyyy-mm-dd')
      const timeitem = this.timeStringToDate(date)
      return `${this.formatoFechaAbreviacion(dateitem)} ${timeitem}`
    },
    FormatDateDinamic (item, showItem) {
      const field = showItem.split('.')
      let obj = null
      //  if (item[element] instanceof Object)
      if (field.length <= 1) {
        obj = item[field[0]]
        return this.convertFormatDate(obj)
      }

      for (let index = 0; index < field.length; index++) {
        const element = field[index]
        if (obj) {
          obj = obj[element]
        } else {
          obj = item[element]
        }
      }
      return this.convertFormatDate(obj)
    },
    FormatNumberMoney (val, item) {
      const number = this.round(item[val], 2, true)
      return `$ ${number}`
    },
    ProcesadoAsistencias (val, item) {
      return (item.especial[val]) ? 'Aplicado' : 'No Aplicado'
    },

    setActiveRow (obje) {
      this.itemsAction(obje)
    }
  }
}
</script>

  <style>
  .inputSearch{
    padding-top: 25px !important;
  }
  </style>
