import { render, staticRenderFns } from "./PhysioExpediente.view.vue?vue&type=template&id=ed90c968&scoped=true&"
import script from "./PhysioExpediente.view.vue?vue&type=script&lang=js&"
export * from "./PhysioExpediente.view.vue?vue&type=script&lang=js&"
import style0 from "./PhysioExpediente.view.vue?vue&type=style&index=0&id=ed90c968&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed90c968",
  null
  
)

export default component.exports