<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <div class="ma-auto">
          <p class="text-center text-md-h3 color-azul">
            <span class="text-strong text-uppercase">AVISO DE PRIVACIDAD</span>
          </p>
          <v-img class="ma-auto" src="@/assets/logo_synergy.png" :max-width="titleSynergy" />
        </div>
      </v-col>
      <v-col cols="12">
        <p class="font-weight-bold text-uppercase text-center color-morado">
          <label class="text-strong-1">
            Identidad y Domicilio del Responsable del Tratamiento de Datos Personales.
          </label>
        </p>
        <p class="text-light">
          Con fundamento en la Ley Federal de Protección de Datos Personales en Posesión de Particulares (“LFPDPPP”) y el Reglamento de la misma, SINERGIA CORPORAL S.C.P. (en adelante, “SYNERGY”) con domicilio para oír y recibir notificaciones en Calle 10 entre 1 H número 205 de la colonia méxico Norte de esta ciudad de Mérida, Yucatán; le informa:
        </p>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" class="my-5">
        <v-divider/>
      </v-col>
      <v-col cols="2">
        <div class="color-azul text-center font-weight-bold">I.</div>
      </v-col>
      <v-col cols="10">
        <label class="text-uppercase font-weight-bold text-strong-1 color-morado"> Datos personales </label>
        <p class="text-light">
          Para la prestación de los servicios contratados, recabamos las siguientes vategorías de datos personales:
        </p>
        <ol class="text-light">
          <li>Datos de identificación (nombre, apellido, fecha y lugar de nacimiento).</li>
          <li>Datos de contacto (correo electrónico y teléfono).</li>
          <li>Datos sobre características físicas.</li>
          <li>Datos personales sensibles (salud).</li>
          <li>Datos laborales (puesyo, domicilio, número de teléfono, cédula profesional, etc.</li>
          <li>Datos de facturación: (nombre de la persona que hará el pago, domicilio fiscal, clave de RFC o número de identificación fiscal).</li>
          <li>Datos financieros (cuentas bancarias, formas de pago, etc.)</li>
        </ol>
      </v-col>
      <v-col cols="12" class="my-5">
        <v-divider/>
      </v-col>
      <v-col cols="2">
        <div class="color-azul text-center font-weight-bold">II.</div>
      </v-col>
      <v-col cols="10">
        <label class="text-uppercase font-weight-bold text-strong-1 color-morado"> Datos de salud </label>
        <p class="text-light">
          A través de la decración de Antecedentes Médicos por parte del Usuario a SYNERGY, éste último deberá considerarlos como "Sensibles" por la legislación aplicable, en particular aquellos referentes a su salud actual.
          Los datos personales sensibles serán tratados para el cumplimiento de las finalidades en el presente Aviso de Privacidad y, en particular, para la sana ejecución de los servicios por parte de SYNERGY.
        </p>
      </v-col>
      <v-col cols="12" class="my-5">
        <v-divider/>
      </v-col>
      <v-col cols="2">
        <div class="color-azul text-center font-weight-bold">III.</div>
      </v-col>
      <v-col cols="10">
        <label class="text-uppercase font-weight-bold text-strong-1 color-morado"> Finalidad </label>
        <ol class="text-light">
          <li>Proporcionar los serivicios contratados.</li>
          <li>Asegurar la calidad de los servicios contratados.</li>
          <li>Gestión, control y administración para la operación normal y/o cualquier eventualidad.</li>
          <li>Envío de comunicación sobre ofertas, promociones, servicios y/o productos porporcionados por SYNERGY.</li>
          <li>Facturación de los servicios y productos proporcionados, así como su cobro judicial o extrajudicial.</li>
          <li>Estadísticas y registros históricos.</li>
        </ol>
      </v-col>
      <v-col cols="12" class="my-5">
        <v-divider/>
      </v-col>
      <v-col cols="2">
        <div class="color-azul text-center font-weight-bold">IV.</div>
      </v-col>
      <v-col cols="10">
        <label class="text-uppercase font-weight-bold text-strong-1 color-morado"> Derechos del usuario </label>
        <p class="text-light">
          En cualquier momento, el usuario podrá ejercer sus derechos de acceso, rectificación, cancelación y oposición por escrito al domicilio antes mencionado y/o al estudio de SYNERGY, dicha solicitud deberá contener:
        </p>
        <ol class="text-light">
          <li>Nombre y copia de una Identificación Oficial.</li>
          <li>Teléfono, correo electrónico y dirección.</li>
          <li>Descripción detallada y precisa de los datos personales en los que busca ejercer alguno de sus derechos.</li>
        </ol>
      </v-col>
      <v-col cols="12" class="my-5">
        <v-divider/>
      </v-col>
      <v-col cols="2">
        <div class="color-azul text-center font-weight-bold">V.</div>
      </v-col>
      <v-col cols="10">
        <label class="text-uppercase font-weight-bold text-strong-1 color-morado"> Modificación </label>
        <p class="text-light">
          En cualquier momento SYNERGY podrá modificar el contenido y alcance del presente Aviso de Privacidad. 
          En dicho caso, SYNERGY publicará los cambios en el sitio web: <a href="https://synergyinmovement.com">https://synergyinmovement.com</a>  en el 
          "Link": Aviso de Privacidad.
        </p>
      </v-col>
      <v-col cols="12" class="my-5">
        <v-divider/>
      </v-col>
    </v-row>
    <FooterMain color="grey lighten-5" className="color-azul" colorIcon="color-morado" />
  </v-container>
</template>
<script>
export default {
  data: () => ({
  }),
  computed: {
    titleSynergy () {
      if (this.$vuetify.breakpoint.xl) return 900
      if (this.$vuetify.breakpoint.lg) return 700
      if (this.$vuetify.breakpoint.md) return 500
      if (this.$vuetify.breakpoint.sm) return 300
      return 250
    }
  }
}
</script>

<style>

</style>
