<template>
  <v-card v-if="model" elevation="0" :color="color">
    <v-list-item three-line>
      <v-list-item-content>
        <div v-if="model.photo">
          <div>
            <v-img :src="model.photo" id="circle-photo" />
          </div>
        </div>
        <div v-else>
          <div>
            <div id="circle-photo" class="disable-img"></div>
          </div>
        </div>
        <!-- <div id="circle" class="container-relative-center mb-2"></div> -->
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ['model', 'photo', 'color'],
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style scoped>
#circle-photo {
    border-radius: 50%;
    margin: auto;
    text-align: justify;
    width: 8em;
    height: 8em;
}
.disable-img{
  background: #b2b2b2;
}
</style>
