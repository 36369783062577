<template>
  <v-card
    v-if="model.id"
    class="background-morado-light elevation-2"
    tile
  >
    <v-card-title>Clase - {{model.trainingRoom.name}} </v-card-title>
    <v-card-subtitle> Bicicletas </v-card-subtitle>
    <v-card-text>
      <v-row dense no-gutters justify="center">
        <v-col v-for="(bike, i) in bikes" :key="i" cols="6" sm="3" md="2" class="text-center ma-auto">
          <div v-if="bike.enabled" class="pa-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-sheet
                  class="rounded-circle mx-auto transition-swing"
                  color="#A87BC9"
                  max-width="100"
                  width="100%"
                  height="100"
                  elevation="1"
                  v-bind="attrs"
                  v-on="on">
                  <v-row class="fill-height" align="center" justify="center">
                    <label class="display-1 text-strong">{{bike.id}}</label>
                  </v-row>
                </v-sheet>
              </template>
              <span>{{ bike.fullname }} {{bike.talla}}</span>
            </v-tooltip>
          </div>
          <div v-else class="pa-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-sheet
                  class="rounded-circle mx-auto transition-swing"
                  max-width="100"
                  width="100%"
                  height="100"
                  elevation="1"
                  v-bind="attrs"
                  v-on="on">
                  <v-row class="fill-height" align="center" justify="center">
                    <label class="display-1 text-strong">{{bike.id}}</label>
                  </v-row>
                </v-sheet>
              </template>
              <span>{{ bike.fullname }}  {{ bike.talla }}</span>
            </v-tooltip>
          </div>

          <!-- <CirculePositionField :model="bike" :on-success="actionItem" /> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
// import DataTableMain from '@/components/tables/DataTableMain'
export default {
  name: 'SpinningDetail',
  components: {
  },
  props: ['model', 'onSuccess'],
  data: () => ({
    bikes: []
  }),
  computed: {},
  watch: {
    model (row) {
      // console.log(row)
      if (row.id) {
        this.selectionBikes(row)
      }
    }
  },
  mounted () {},
  methods: {
    selectionBikes (row) {
      this.bikes = []
      for (let index = 1; index <= row.trainingRoom.bikes; index++) {
        let objeto = {}
        for (let j = 0; j < row.enrollments.length; j++) {
          const enrollment = row.enrollments[j]
          if (index === enrollment.bike) {
            objeto = {
              id: index,
              fullname: `${enrollment.client.firstName} ${enrollment.client.lastName}`,
              enabled: true,
              talla: ` - Talla ( ${enrollment.client.alergies} )` // alergies == Talla
            }
            break
          }
        }
        if (!objeto.id) objeto = { id: index, fullname: 'Disponible', enabled: false, talla: '' }

        this.bikes.push(objeto)
      }
    }
  }
}
</script>

  <style>
  </style>
