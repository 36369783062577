<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <div class="table-tab font-weight-bold px-5 py-2 text-center text-strong">Clases</div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" md="4" lang="3">
        <label class="color-morado-dark px-2">Fecha Inicio</label>
        <DatePickerSolo :item-action="fieldDateInit" :on-success="actionFieldData" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lang="3">
        <label class="color-morado-dark px-2">Fecha Final</label>
        <DatePickerSolo :item-action="fieldDateFinal" :on-success="actionFieldData" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lang="3"><br/>
        <v-layout justify-space-between>
          <v-btn color="#602F73" @click="updateFiltro" dark>
            <v-icon left dark>mdi-calendar-search</v-icon> Buscar
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success="successTableAction" :itemsAction="successTableAction" />
      </v-col>
      <v-col cols="12">
        <ViewDetailsMain class="my-2" :object="objectDetail" type="SpinningDetail" :items-action="successTableAction" />
      </v-col>
    </v-row>
    <AvisoDialog v-if="get_dialogAlert.dialog" />
    <MainFormDialog v-if="get_dialogMain.dialog" :fields="form" :model="object" :urls="url" :message="message" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DatePickerSolo from '@/components/fields/account/DatePicker_solo'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
export default {
  name: 'CyclingMyAccountSpinningClass',
  components: {
    DataTableMain,
    MainFormDialog,
    ViewDetailsMain,
    DatePickerSolo
  },
  mixins: [DatesUtils],
  data: () => ({
    fieldDateInit: { field: 'textDatePicker', name: '', nameid: 'start', default: true, rules: true, cols: 3 },
    fieldDateFinal: { field: 'textDatePicker', name: '', nameid: 'final', default: true, rules: true, cols: 3 },
    start: null,
    final: null,
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      color: '#ECDEF7',
      className: 'background-morado-light',
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-refresh', color: '#602F73', action: 'newRefresh', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: '#602F73', action: 'newData', code: 'libre', hidden: '' }
        ],
        titles: [
          { text: 'Nombres', value: 'user.firstName', class: 'color-morado-dark text-strong' },
          { text: 'Apellidos', value: 'user.lastName', class: 'color-morado-dark text-strong' },
          { text: 'Nombre Clase', value: 'trainingRoom.name', class: 'color-morado-dark text-strong' },
          { text: 'Fecha Clase', value: 'DateMain', class: 'color-morado-dark text-strong' },
          { text: '# Lugares', value: 'trainingRoom.bikes', class: 'color-morado-dark text-strong' },
          { text: '', value: 'Actions', class: 'color-morado-dark', align: 'right' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Editar', icon: 'mdi-pencil', color: '#A87BC9', action: 'EditData', code: 'libre', hidden: '' },
          { title: 'Eliminar', icon: 'mdi-delete', color: '#A87BC9', action: 'DeleteData', code: 'libre', hidden: '' }
        ]
      },
      footer: {
        paging: true
      }
    },
    params: {
      start: '',
      final: ''
    },
    user: {},
    form: [],
    object: {},
    url: '',
    message: ''
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_objects', 'get_reload', 'get_object_two_put', 'get_dialogAlert',
      'get_SpinningClassForm', 'get_DeleteForm']),
    objectDetail () {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    get_objects (rows) {
      if (rows) {
        this.table.modelMain = []
        if (rows.length > 0) this.table.modelMain.push(rows[0])
        this.table.body.data = rows
        this.table.header.loading = false
      }
    },
    get_reload ({ name, model }) {
      if (name === 'nuevoClase') {
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: 'Clase creada correctamente' } })
        setTimeout(() => {
          // this.HIDDE_DIALOG_ALERT()
          this.GETListObjectsService({ url: '/SpinningClass' })
        }, 1500)
      } else if (name === 'updateDate') {
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: 'Clase actualizada correctamente' } })
        setTimeout(() => {
          // this.HIDDE_DIALOG_ALERT()
          this.GETListObjectsService({ url: '/SpinningClass' })
        }, 1500)
      } else if (name === 'delete') {
        if (model.message) {
          this.SHOW_DIALOG_ALERT({ title: 'Advertencia', width: 500, params: { message: 'No fue posible eliminar la clase' } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
          }, 1500)
          return
        }
        this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: 'Clase eliminada correctamente' } })
        setTimeout(() => {
          // this.HIDDE_DIALOG_ALERT()
          this.GETListObjectsService({ url: '/SpinningClass' })
        }, 1500)
      }
    }
  },
  mounted () {
    this.user = this.$cookies.get('user_sny')
    const date = new Date()
    const fecha = this.formatoFecha(date, 'yyyy-mm-dd')
    this.GETListObjectsService({ url: `/SpinningClass?start=${fecha}&final=${fecha}` })
  },
  methods: {
    ...mapActions(['GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    /* *==========*==========*==========*==========*==========* */
    actionFieldData (obj) {
      this[obj.id] = obj.data
    },
    successTableAction (item) {
      this[item.action](item)
    },
    newRefresh (item) {
      this.table.header.loading = true
      this.updateFiltro()
      // this.GETListObjectsService({ url: '/SpinningClass' })
    },
    newData (item) {
      this.form = this.get_SpinningClassForm
      this.object = { spinningClassType: 1, trainingRoomId: 1 }
      this.url = '/SpinningClass'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'nuevoClase', title: 'Nueva Clase', width: 650, params: { returnObjet: true } })
    },
    EditData ({ item }) {
      const dateitem = this.formatoFecha(this.dateStringToDate(item.date), 'yyyy-mm-dd')
      const timeitem = this.timeStringToDate(item.date)
      this.object = {
        id: item.id,
        date: dateitem,
        time: timeitem,
        userId: item.user.id,
        spinningClassType: item.spinningClassType,
        trainingRoomId: item.trainingRoom.id
      }
      this.form = this.get_SpinningClassForm
      this.url = `/SpinningClass/${item.id}`
      this.message = 'Registro Actualizado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'updateDate', title: 'Editar Clase', width: 650, params: { returnObjet: true } })
    },
    DeleteData ({ item }) {
      this.form = this.get_DeleteForm
      this.object = {}
      this.url = `/SpinningClass/${item.id}`
      this.message = 'Registro eliminado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'delete', title: 'Eliminar', width: 500, params: { returnObjet: true } })
    },
    updateFiltro () {
      this.table.header.loading = true
      if (!this.start) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 600, params: { message: 'Fecha de inicio no debe ser vacío' } })
        return
      }
      if (!this.final) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 600, params: { message: 'Fecha de final no debe ser vacío' } })
        return
      }
      this.GETListObjectsService({ url: `/SpinningClass?start=${this.start}&final=${this.final}` })
    }
  }
}
</script>
<style scoped>
th{
  border-bottom: 10px #602F73 solid;
}
.table-tab {
  width: 35%;
  background-color: #602F73;
  border: 1px #602F73 solid;
  border-radius: 25px 25px 0px 0px;
  color: white;
}
.v-tab--active{
  border: 1px #602F73 solid;
  background-color: #602F73;
  color: white;
}
</style>
