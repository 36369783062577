<template>
  <div v-if="model.id">
    <v-list-item v-if="type === 'cycling'" two-line>
      <v-list-item-content>
        <div class="text-center">
          <v-sheet v-if="model.disableclass" @click="sendAction"
            class="rounded-circle mx-auto transition-swing circle-morado-main"
            :max-width="circleView"
            :height="circleView"
            width="100%"
            elevation="0">
            <v-row dense>
              <v-col cols="12">
                <div class="white--text text-uppercase p-x2">
                  <span class="text-light font-weight-bold">{{ model.user.firstName }}</span>
                </div>
                <div class="white--text text-uppercase p-x2">
                  <span class="text-light font-weight-bold">{{ model.hour }}</span>
                </div>
              </v-col>
            </v-row>
          </v-sheet>

          <v-sheet v-else @click="sendAction"
            class="rounded-circle mx-auto transition-swing circle-morado-disable-main"
            :max-width="circleView"
            :height="circleView"
            width="100%"
            elevation="0">
            <v-row dense>
              <v-col cols="12">
                <div class="text-uppercase p-x2">
                  <span v-if="model.disableclass" class="color-azul text-light font-weight-bold text-button"> wait list </span><br/>
                  <span class="text-light font-weight-bold"> {{ model.user.firstName }} </span>
                </div>
                <div class="text-uppercase p-x2">
                  <span class="text-light font-weight-bold">{{ model.hour }}</span>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-else-if="type === 'fisio'" two-line>
      <v-list-item-content>
        <div class="text-center">
          <v-sheet v-if="model.show" @click="sendAction"
            class="rounded-circle mx-auto transition-swing circle-azul-main"
            :max-width="circleView"
            :height="circleView"
            width="100%"
            elevation="0">
            <v-row dense>
              <v-col cols="12">
                <div class="white--text text-uppercase p-x2">
                  <span class="text-light font-weight-bold">{{ model.hour }}</span>
                </div>
              </v-col>
            </v-row>
          </v-sheet>

          <v-sheet v-else @click="sendAction"
            class="rounded-circle mx-auto transition-swing circle-morado-disable-main"
            :max-width="circleView"
            :height="circleView"
            width="100%"
            elevation="0">
            <v-row dense>
              <v-col cols="12">
                <div class="text-uppercase p-x2">
                  <span class="text-light font-weight-bold">{{ model.user.firstName }}</span>
                </div>
                <div class="text-uppercase p-x2">
                  <span class="text-light font-weight-bold">{{ model.hour }}</span>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-list-item-content>
    </v-list-item>

  <!-- <v-card v-if="model.id" elevation="0">
    <v-list-item three-line @click="sendAction">
      <v-list-item-content v-if="fisio">
        <div>
          <div id="circle-fisio" class="text-vertical-center">
            <div class="ma-auto">
              <div class="text-md-h5 white--text pa-2">{{model.hour}}</div>
            </div>
          </div>
        </div>
      </v-list-item-content>
      <v-list-item-content v-else>
        <div v-if="!model.show">
          <div id="circle">
            <div class="subtitle-2 color-azul font-italic font-weight-bold pa-1">WAIT LIST</div>
            <div class="text-md-h5 color-gris">{{model.user.firstName}}</div>
            <div class="text-md-h5 color-gris">{{model.hour}}</div>
          </div>
        </div>

        <div v-else>
          <div id="circle-blue" class="text-vertical-center">
            <div class="ma-auto">
              <div class="text-md-h5 white--text">{{model.user.firstName}}</div>
              <div class="text-md-h5 white--text pa-2">{{model.hour}}</div>
            </div>
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card> -->
  </div>
</template>

<script>
export default {
  props: ['model', 'type', 'onSuccess'],
  data: () => ({}),
  computed: {
    circleView () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm': return 100
        case 'md': return 110
        case 'lg': return 120
        case 'xl': return 300
      }
      return 100
    }
  },
  created () {
  },
  methods: {
    sendAction () {
      this.onSuccess(this.model)
    }
  }
}
</script>

<style scoped>
.circle-morado-main{
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #A87BC9;
  cursor: pointer;
}
.circle-morado-main:hover {
  background-color: #041A72;
}
.circle-morado-disable-main{
  border-color: #b8b8b8;
  border-style: dotted;
  color: #b8b8b8;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.circle-azul-main{
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #6D9BE3;
  cursor: pointer;
}
.circle-azul-main:hover {
  background-color: #041A72;
}
</style>
