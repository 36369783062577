import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
  breakpoint: {
    thresholds: {
      xs: 575,
      sm: 767,
      md: 991 + 16,
      lg: 1199 + 16,
      xl: Infinity
    }
  }
})

export default new Vuetify({
})
