<template>
  <v-container
    fluid
    class="pa-0 ma-0"
  >
    <div class="text-md-h3 py-5 color-azul background-morado container-height">
      <span class="ma-auto white--text">
        Welcome to <span class="font-weight-bold text-uppercase">synergy</span>
      </span>
    </div>
    <v-toolbar
      color="#602F73"
      elevation="0"
      dark
    >
      <v-toolbar-title class="ma-auto white--text">
        <span class="text-md-h4 text-sm-h5">@synergyinmovement</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-row
      dense
      class="mt-10"
    >
      <v-col cols="12">
        <v-layout
          justify-center
          align-center
        >
          <v-flex md10>
            <v-row dense>
              <v-col cols="8">
                <v-form ref="form">
                  <v-card
                    outlined
                    class="seccion-form">
                    <v-card-title><span class="text-md-h3 text-sm-h4 text-h5 font-weight-bold color-azul">Pago</span></v-card-title>
                    <v-card-text>
                      <v-layout justify-center align-center>
                        <v-flex md9 sm xs12>
                          <v-row dense>
                            <v-col cols="12">
                              <SquarePackageField :model="packageOne" />
                            </v-col>
                            <!-- <v-col cols="12">
                              <v-text-field v-model="cardMain.number" label="Ingresar el Numero de Tarjeta" placeholder="Ingresar el Numero de Tarjeta" :rules="fieldRequired" dense outlined solo />
                            </v-col>
                            <v-col cols="12" md="6">
                              <DatePickerMainMonth :itemAction="dateMonth" :on-success="actionFieldData"/>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="cardMain.cvc" label="Código de Seguridad" placeholder="Código de Seguridad" type="password" :rules="fieldRequired" dense outlined solo />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field v-model="cardMain.name" label="Nombre del Tarjetahabiente" placeholder="Nombre del Tarjetahabiente" :rules="fieldRequired" dense outlined solo />
                            </v-col>
                            <v-col cols="12">
                              <v-btn class="mb-2" color="#A87BC9" dark block @click="sendSalePackage">
                                <v-icon left>
                                  mdi-credit-card
                                </v-icon>pagar
                              </v-btn>
                              <v-btn id="circle-morado-line" @click="cancel" rounded outlined large block>
                                <span class="color-azul">Cancelar</span>
                              </v-btn>
                            </v-col> -->
                          </v-row>
                        </v-flex>
                      </v-layout>
                      <div id="conektaIframeContainer" style="width: 100%; height: 450px;"></div>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="4">
                <v-img
                  class="img-link"
                  src="@/assets/web/pago.jpeg"
                />
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>

        <v-layout
          justify-center
          align-center
          class="my-10"
        >
          <v-flex
            md3
            class="text-center"
          >
            <a @click="sendPage('AvisoPrivacidadView')" class="subtitle-1 font-weight-light link-main text-uppercase"> ver aviso de privacidad </a>
          </v-flex>
          <v-flex
            md3
            class="text-center"
          >
            <a @click="sendPage('TerminoyCondicionesView')" class="subtitle-1 font-weight-light link-main text-uppercase"> ver terminos y condiciones </a>
          </v-flex>
          <v-flex
            md3
            class="text-center"
          >
            <a @click="sendPage('home')" class="subtitle-1 font-weight-light link-main text-uppercase"> volver al inicio </a>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
    <FooterMain
      color="grey lighten-5"
      class-name="color-azul"
      color-icon="color-morado"
    />
    <MainFormDialog
      v-if="get_dialogMain.dialog"
      :fields="form"
      :model="object"
      :urls="url"
      :message="message"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainFormDialog from '@/components/dialogs/main/MainFormDialog'
import SquarePackageField from '@/components/fields/SquarePackageField.vue'
import { DatesUtils } from '@/mixins/DatesUtilsMixin'
import { Validate } from '@/mixins/validateFormMixin'
import { UserSession } from '@/store/modules/config'
import router from '@/router'
export default {
  name: 'PhysioCalendar',
  components: {
    MainFormDialog,
    SquarePackageField
  },
  mixins: [DatesUtils, Validate],
  data: () => ({
    packageOne: null,
    package_id: 0,
    cardMain: {},
    dateMonth: { name: 'Fecha de Vencimiento', nameid: 'exp_month_yaer', paid: true },

    salePackage: {},
    form: [],
    object: {},
    url: '',
    message: '',
    token: null,
    isLoaded: false,
    user: null,
    order: {
      amount: 0,
      currency: 'MXN',
      amount_refunded: 0,
      customer_info: {},
      line_items: [],
      charges: [{ payment_method: { type: '' } }]
    }
  }),
  computed: {
    ...mapGetters(['get_dialogMain', 'get_ConfirmPaidPackageForm', 'get_conekta_token',
      'get_object', 'get_reload', 'get_object_two_post'])
  },
  watch: {
    get_object (data) {
      this.packageOne = data
      this.crearOrder()
    },
    get_reload ({ name, model }) {
      // console.log('model', model)
      if (model.message || model.Message) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 700, params: { message: model.message } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
          location.reload()
        }, 2500)
        return
      }
      if (name === 'conekta') {
        if (model.object === 'error') {
          this.SHOW_DIALOG_ALERT({ title: 'Error !', width: 700, params: { message: model.details[0].message } })
          setTimeout(() => {
            this.HIDDE_DIALOG_ALERT()
            location.reload()
          }, 2500)
          return
        }
        this.POSTObjectTwoService({ url: '/Packages/purchased', data: { userId: this.user.id, packageId: this.packageOne.id } })
      }
      // console.log(data)

      // const confirmMessage = 'La compra del paquete se realizo correctamente'
      // this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: confirmMessage } })
      // setTimeout(() => {
      //   this.HIDDE_DIALOG_ALERT()
      //   // location.href = '/Cycling/Comprar'
      //   // this.cancel()
      // }, 2500)
    },
    get_object_two_post (model) {
      if (model.message || model.Message) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 700, params: { message: model.message } })
        setTimeout(() => {
          this.HIDDE_DIALOG_ALERT()
          location.reload()
        }, 2500)
        return
      }
      const confirmMessage = 'La compra del paquete se realizo correctamente'
      this.SHOW_DIALOG_ALERT({ title: 'Confirmación', width: 500, params: { message: confirmMessage } })
      setTimeout(() => {
        this.HIDDE_DIALOG_ALERT()
        // location.href = '/Cycling/Comprar'
        this.cancel()
      }, 2500)
    },
    get_conekta_token (data) {
      this.importScriptIframe(data)
    }
  },
  mounted () {
    this.user = UserSession()
    this.package_id = this.$route.params.id
    this.GETObjectService({ url: `/Packages/${this.package_id}` })
    this.importScript()
  },
  created () {
    // if (!this.isLoaded) {
    //   this.importScript()
    // } else {
    //   window.Conekta.setPublicKey('')
    //   this.importScript()
    // }
  },
  methods: {
    ...mapActions(['POSTServiceConekta', 'GETObjectService', 'POSTConektaToken', 'POSTObjectTwoService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    // *==========*==========*==========*==========* \\
    actionFieldData (obj) {
      // console.log(obj)
      if (obj.id === 'exp_month_yaer') {
        const Fecha = obj.data.split('/')
        this.cardMain.exp_month = Fecha[0]
        this.cardMain.exp_year = Fecha[1]
      }
    },
    conektaSuccessResponseHandler (token) {
      const itemp = {
        payment_method: {
          type: 'card',
          name: 'compra de paquete',
          token_id: token.id
        }
      }
      this.order.charges = []
      this.order.charges.push(itemp)
      this.form = this.get_ConfirmPaidPackageForm
      this.object = this.order
      this.url = 'https://api.conekta.io/orders'
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'conekta', title: 'Confirmacion', width: 500, params: { returnObjet: true } })

      // const fecha = this.getDateTime().toString()
      // this.form = this.get_ConfirmPaidPackageForm
      // this.object = { UserId: user.id, PackageId: this.package_id, created: fecha, token_id: token.id }
      // this.object.PaymentMethod = {}
      // this.url = '/Packages/coneckta/purchased'
      // this.message = 'Registro Agregado Correctamente'
      // this.SHOW_DIALOG_MAIN({ type: 'salePackage', title: 'Confirmacion', width: 500, params: { returnObjet: true } })
      // } else {
      //   this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: 'Debe iniciar sesion para comprar el paquete' } })
      // }
    },
    conektaErrorResponseHandler (response) {
      // console.log('response', response)
      this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 500, params: { message: response.message, returnObjet: true } })
      setTimeout(() => { location.reload() }, 2500)
    },
    sendSalePackage () {
      const obj = { card: this.cardMain }
      window.Conekta.Token.create(obj, this.conektaSuccessResponseHandler, this.conektaErrorResponseHandler)
      this.isLoaded = true
    },
    importScript () {
      const script = document.createElement('script')
      script.src = 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js'
      script.id = 'conekta-script'
      script.async = true
      document.body.appendChild(script)
      this.POSTConektaToken({ url: 'https://api.conekta.io/tokens', data: { checkout: { returns_control_on: 'Token' } } })
      // const script = document.createElement('script')
      // script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
      // script.id = 'conekta-script'
      // script.onload = () => {
      //   if (!window.Conekta) {
      //     console.error('Conekta is not set')
      //     return
      //   }
      //   this.isLoaded = true
      //   window.Conekta.setPublicKey('key_a03ZUTUDlwakNjHX40lw80a')
      //   // key_Fv3WN5Tuw0qAB0cQRils00f
      //   console.log('is conect')
      //   // console.log('window', window.Conekta.getPublicKey())
      // }
      // script.async = true
      // document.body.appendChild(script)
    },
    importScriptIframe (data) {
      const vuethis = this
      window.ConektaCheckoutComponents.Card({
        targetIFrame: '#conektaIframeContainer', // Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
        allowTokenization: true,
        checkoutRequestId: data.checkout.id, // Checkout request ID, es el mismo ID generado en el paso 1
        publicKey: 'key_a03ZUTUDlwakNjHX40lw80a', // Llave: key_a03ZUTUDlwakNjHX40lw80a
        options: {
          styles: { // inputType modifica el diseño del Web Checkout Tokenizer
          // inputType: 'basic' // 'basic' | 'rounded' | 'line'
            inputType: 'rounded',
            // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
            // buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
            buttonType: 'rounded',
            // Elemento que personaliza el borde de color de los elementos
            states: {
              empty: {
                borderColor: '#FFAA00' // Código de color hexadecimal para campos vacíos
              },
              invalid: {
                borderColor: '#FF00E0' // Código de color hexadecimal para campos inválidos
              },
              valid: {
                borderColor: '#0079c1' // Código de color hexadecimal para campos llenos y válidos
              }
            }
          },
          languaje: 'es', // 'es' Español | 'en' Ingles
          // Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
          button: {
            colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
            // text: 'Agregar Tarjeta***', //Nombre de la acción en el botón ***Se puede personalizar
            backgroundColor: '#301007' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
          },
          // Elemento que personaliza el diseño del iframe
          iframe: {
            colorText: '#65A39B', // Código de color hexadecimal para el color de la letra de todos los campos a llenar
            backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
          }
        },
        onCreateTokenSucceeded: function (token) {
          vuethis.conektaSuccessResponseHandler(token)
        },
        onCreateTokenError: function (error) {
          vuethis.conektaErrorResponseHandler(error)
        }
      })
    },
    cancel () {
      // router.push({ name: 'CyclingComprarView' })
      location.href = '/Cycling/Comprar'
    },
    sendPage (name) {
      router.push({ name: name })
    },
    crearOrder () {
      this.order.customer_info = {
        name: `${this.user.firstName} ${this.user.lastName}`,
        email: this.user.email,
        phone: this.user.phone,
        corporate: false
      }
      this.order.amount = parseInt(this.packageOne.price * 100)
      const item = {
        name: this.packageOne.name,
        unit_price: parseInt(this.packageOne.price * 100),
        quantity: 1,
        description: `${this.packageOne.name} ${this.packageOne.Classes} Clases`,
        brand: 'SYNERGY'
      }
      this.order.line_items = []
      this.order.line_items.push(item)
    }
  }

}
</script>
<style scoped>
.seccion-form {
  border: 3px #602F73 solid;
}

</style>
