<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row dense no-gutters>
      <v-col cols="12" class="background-main">
        <div class="container-height-200">
          <p class="color-morado text-h3 text-sm-h5 text-md-h2 text-center font-weight-bold ma-auto">
            <span class="text-strong-1">Physiotherapy</span>
          </p>
        </div>
        <v-row no-gutters dense class="container-height-450">
          <v-col cols="6" sm="6" md="6" v-for="(item, index) in Items" :key="index" class="text-center ma-auto">
            <v-btn :to="item.path" :color="item.color" x-large dark>
              <span class="text-h5 text-sm-h4 text-md-h4 font-weight-bold">
                <span class="text-strong-1">{{ item.title }}</span>
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="background-descrption">
        <v-layout justify-center>
          <v-flex xs12 sm8 md8 >
            <div class="container-height-200">
              <p class="color-azul text-md-h3 text-sm-h4 text-h4 ma-auto text-center">
                <span class="text-strong-1">Fisioterapia y la sinergia en el movimiento</span>
              </p>
            </div>
            <p class="text-center container-height-350">
              <span class="text-light">
                Es un abordaje terapéutico integral que parte de la sinergia entre las diferentes capacidades físicas y
                el cuerpo humano. Consiste en prevenir y mejorar la calidad de vida mediante una intervención enfocada
                en la funcionalidad, partiendo de una valoración y estableciendo el diagnóstico fisioterapeutico
                para el diseño de objetivos y de una intervención basada en evidencia que promueva el bienestar y un
                impacto positivo en la salud.
              </span>
            </p>
            <div class="container-height-100">
              <v-btn class="ma-auto text-center" to="/Physio/Agendar" color="#001B71" x-large dark>
                <span class="text-md-h4 text-sm-h5 text-h5 font-weight-bold">Agendar Valoración</span>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" v-if="false">
        <v-carousel v-model="model" cycle hide-delimiters show-arrows-on-hover dark>
          <template v-for="(item, index) in slider">
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index"
              reverse-transition="fade-transition" transition="fade-transition">
              <v-row class="flex-nowrap" style="height:100%">
                <template v-for="(n,i) in columns">
                  <template v-if="(+index + i) < slider.length">
                    <v-col :cols="(12 / columns)" :key="i">
                      <v-sheet v-if="(+index + i) < slider.length" class="pa-1">
                        <v-row align="center" justify="center">
                          <!-- <div class="display-3">{{+index + i + 1}}</div> -->
                          <v-img class="img-link" :src="slider[(+index + i)].image" width="100%" :height="showImageHeight" />
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-col>
    </v-row>
    <FooterMain color="#001B71" className="white--text" colorIcon="color-azul-light" />
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',
  data: () => ({
    model: null,
    Items: [
      { title: 'Agendar', path: '/Physio/Agendar', icon: 'mdi-home', color: '#b8d1fc' },
      { title: 'Fisios', path: '/Physio/PhysioPersons', icon: 'mdi-home', color: '#b8d1fc' },
    ],
    slider: [
      { image: require('@/assets/physio/slider/carrucel_1.jpg'), name: 'carrucel_1' },
      { image: require('@/assets/physio/slider/carrucel_2.jpg'), name: 'carrucel_2' },
      { image: require('@/assets/physio/slider/carrucel_3.jpg'), name: 'carrucel_3' },
      { image: require('@/assets/physio/slider/carrucel_7.jpg'), name: 'carrucel_7' },
      { image: require('@/assets/physio/slider/carrucel_8.jpg'), name: 'carrucel_8' },
      { image: require('@/assets/physio/slider/carrucel_9.jpg'), name: 'carrucel_9' },

      { image: require('@/assets/physio/slider/carrucel_4.jpg'), name: 'carrucel_4' },
      { image: require('@/assets/physio/slider/carrucel_5.jpg'), name: 'carrucel_5' },
      { image: require('@/assets/physio/slider/carrucel_6.jpg'), name: 'carrucel_6' }

    ]
  }),
  computed: {
    columns () {
      if (this.$vuetify.breakpoint.xl) return 4
      if (this.$vuetify.breakpoint.lg) return 3
      if (this.$vuetify.breakpoint.md) return 2
      return 1
    },
    showImageHeight () {
      if (this.$vuetify.breakpoint.xl) return 900
      if (this.$vuetify.breakpoint.lg) return 700
      if (this.$vuetify.breakpoint.md) return 500
      return 350
    }
  }
}
</script>

<style scoped>
.background-main {
  /* border: 1px red solid; */
  background-image: url(../assets/physio/physio_main.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 20%;
  width:100%;
  height: 85vmin;
}
.background-descrption {
 background: url("../assets/fondo_physio.jpeg");
 background-size: 100%;
  background-position: 100% 40%;
}
.img-container {
  z-index: 2;
}
@media  (max-width: 600px) {
  .background-main {
    height: 110vmin;
  }
}
</style>
