<template>
  <v-card v-if="model" elevation="0" @click="sendAction" tile>
    <v-list-item v-if="type === 'cycling'" two-line>
      <v-list-item-content>
        <div class="text-center">
          <v-sheet
            class="rounded-circle mx-auto transition-swing circle-morado-main"
            max-width="200"
            height="200"
            width="100%"
            elevation="0">
            <v-row>
              <v-col cols="12">
                <v-avatar size="180">
                  <v-img v-if="model.photo" :src="model.photo" :alt="model.firstName" />
                  <v-img v-else src="@/assets/fondo2.png" id="circle-photo" />
                </v-avatar>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
        <v-list-item-subtitle class="text-center pt-3">
          <label class="color-morado text-uppercase text-strong"> {{ model.firstName }} {{ model.lastName }}</label>
          <div>
            <v-btn class="text-center" icon elevation="0" x-large>
              <v-icon x-large class="mdi-48px">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </v-list-item-subtitle>
        <!-- <div id="circle" class="container-relative-center mb-2"></div> -->
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="type === 'fisio'" two-line>
      <v-list-item-content>
        <div class="text-center">
          <v-sheet
            class="rounded-circle mx-auto transition-swing circle-azul-main"
            max-width="200"
            height="200"
            width="100%"
            elevation="0">
            <v-row>
              <v-col cols="12">
                <v-avatar size="180">
                  <v-img v-if="model.photo" :src="model.photo" :alt="model.firstName" />
                  <v-img v-else src="@/assets/fondo2.png" id="circle-photo" />
                </v-avatar>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
        <v-list-item-subtitle class="text-center pt-3">
          <label class="color-azul text-uppercase text-strong"> {{ model.firstName }} {{ model.lastName }}</label>
          <div>
            <v-btn class="text-center" icon elevation="0" x-large>
              <v-icon x-large class="mdi-48px">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </v-list-item-subtitle>
        <!-- <div id="circle" class="container-relative-center mb-2"></div> -->
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ['model', 'type', 'onSuccess'],
  data: () => ({}),
  created () {
  },
  methods: {
    sendAction () {
      this.onSuccess(this.model)
    }
  }
}
</script>

<style scoped>
.circle-morado-main{
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  border-color: white;
  border-style: dotted;
}
.circle-morado-main:hover {
  border-color: #602F73;
}

.circle-azul-main{
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  border-color: white;
  border-style: dotted;
}
.circle-azul-main:hover {
  border-color: #001B71;
}
</style>
