import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import 'vue-material-design-icons/styles.css'
import axios from 'axios'
import Cookies from 'vue-cookies'

import Header from './components/pagemain/Header.vue'
import NavigationDrawer from './components/pagemain/NavigationDrawer.vue'
import NavigationDrawerFisio from './components/pagemain/NavigationDrawerFisio.vue'
import AvisoDialog from './components/dialogs/AvisoDialog.vue'
import AvisoSessionDialog from './components/dialogs/AvisoSessionDialog.vue'

import Footer from './components/pagemain/Footer.vue'

Vue.use(VueSweetalert2)
Vue.use(Cookies)
Vue.component('HeaderMain', Header)
Vue.component('NavigationMain', NavigationDrawer)
Vue.component('NavigationFisioMain', NavigationDrawerFisio)
Vue.component('FooterMain', Footer)
Vue.component('DialogMain', AvisoDialog)
Vue.component('DialogSessionMain', AvisoSessionDialog)

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_RUTA_API

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
