<template>
  <v-container>
    <v-row dense align="center" justify="center">
      <v-col cols="8">
        <v-card outlined tile>
          <v-card-title>
            <label class="text-h5 text-uppercase ma-auto py-5">
              <span class="text-strong-1">Ingresar nueva contraseña</span>
            </label>
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row dense>
                <v-col cols="12">
                  <label class="text-strong-1">Nueva Contraseña</label>
                  <v-text-field v-model="reset.password" type="password"
                    placeholder="Nueva contraseña"
                    :rules="fieldRequired"
                    required
                    solo />
                </v-col>
                <v-col cols="12">
                  <label class="text-strong-1">Confirmar Nueva Contraseña</label>
                  <v-text-field v-model="reset.verificpassword" type="password"
                    placeholder="Confirmar Nueva contraseña"
                    :rules="fieldRequired"
                    required
                    solo />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn color="#602F73" class="text-right py-5" @click="ActiveAccount" large dark>
                    <span class="text-strong-1">Restablecer Contraseña</span>
                  </v-btn>
                </v-col>

                <v-col v-if="showmessage" cols="12">
                  <v-alert border="top" colored-border color="success" elevation="4">
                    <p class="text-light p-1 text-center">
                      La contraseña se a restablecido correctamente
                    </p>
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <FooterMain class="pt-5" color="white" class-name="color-azul" color-icon="color-morado"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Validate } from '@/mixins/validateFormMixin'
import router from '@/router'
export default {
  components: {},
  mixins: [Validate],
  data: () => ({
    token: '',
    userId: '',
    reset: {},
    showmessage: false
  }),
  computed: {
    ...mapGetters(['get_object_put'])
  },
  watch: {
    get_object_put (row) {
      if (row.message) {
        this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 600, params: { message: row.message, confirm: true } })
        return
      }
      this.showmessage = true
    }
  },
  mounted () {
    this.reset.token = this.$route.params.token
  },
  methods: {
    ...mapActions(['PUTObjectService']),
    ...mapMutations(['SHOW_DIALOG_ALERT', 'HIDDE_DIALOG_ALERT']),
    ActiveAccount () {
      if (this.$refs.form.validate()) {
        if (this.reset.password === this.reset.verificpassword) {
          this.PUTObjectService({ url: '/Users/resetPassword', data: this.reset })
        } else {
          this.SHOW_DIALOG_ALERT({ title: 'Advertencia !', width: 600, params: { message: 'la contraseña no coinciden' } })
        }
      }
    },
    returnProfile () {
      router.push({ name: 'PerfilView' })
    }
  }
}
</script>
<style scoped>
</style>
